import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Deposit.css';
import { Col, FormControl, FormGroup, Grid, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import cx from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../locale/messages';
import history from '../../core/history';
import BalanceMenu from '../../components/Balance/BalanceMenu';
import { QRCodeSVG } from 'qrcode.react';
import { FaCopy } from 'react-icons/lib/fa';
import axios from 'axios';
import { connect } from 'react-redux';
//Image
import chainIcon from '../../../public/SiteIcons/chain-icon.png';
import { toastr } from 'react-redux-toastr';
import Loader from '../../components/Loader/Loader';

class Deposit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        address: ''
    }
}
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  getAddress = async () => {
    console.log(this.props.userData, 'this.props.userData');
    const {data} = await axios.post('https://api.testrentals.co.in/token/get-user-address', {
      user_id: this.props.userData.userId,
    })
    console.log(data);
   this.setState({
    address: data?.data?.address
   })
  }

  componentDidMount() {
    this.getAddress()
}

  render() {
    const {address} = this.state
    return (
      <Grid fluid className='dashBoardContainer'>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={cx(s.depositContainer)}>
              <BalanceMenu />
           
              <div className='text-center mt-[60px]'>
                <div className='text-[24px] mb-5'>Your wallet</div>
                <div className='flex justify-center '><QRCodeSVG className='p-5 border' value={address} width={'267px'} height={'267px'}/></div>
                <div className='text-[14px] mt-5 mb-4'>Network: BSC testnet <img src={chainIcon} className='w-[24px] inline-block'/></div>
                <div className='text-[16px] cursor-pointer' onClick={() => {
                  navigator.clipboard.writeText(address)
                  toastr.success('Copied Successfully!')
                }}>{address} <FaCopy className="inline-block"/></div>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}
const mapState = (state) => ({
  userData: state.account.data,
});

const mapDispatch = {
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(Deposit)));
