// General
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Translation
import { FormattedMessage, injectIntl } from "react-intl";

// Redux form
import { Field, reduxForm } from "redux-form";

// Internal Helpers
import validate from "./validate";
import submit from "./submit";

// Locale
import messages from "../../locale/messages";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./Login.css";
import bt from "../../components/commonStyle.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import loginIcon from "../../../public/SiteImages/loginIcon.svg";

import { openForgotPasswordModal } from "../../actions/modalActions";

//Images
import ShowPassword from "../../../public/SiteIcons/pswVisible.svg";
import HidePassword from "../../../public/SiteIcons/pwdHidden.svg";
import clsx from "clsx";
class LoginForm extends Component {
  static propTypes = {
    openForgotPasswordModal: PropTypes.func.isRequired,
    formatMessage: PropTypes.func,
    siteName: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: "",
      selected: "host",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectedChange = this.handleSelectedChange.bind(this);
  }

  handleChange(fieldName) {
    this.setState({
      showPassword: fieldName === this.state.showPassword ? "" : fieldName,
    });
  }

  handleSelectedChange(selected) {
    this.setState({
      selected,
    });
  }

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
    showPassword,
    maxlength,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <>
        <label className={s.loginLabel}>{label}</label>
        <FormControl
          {...input}
          placeholder={label}
          type={showPassword === input.name ? input : type}
          className={className}
          maxlength={maxlength}
        />
        {type == "password" && (
          <span
            className={cx(
              bt.pwdImage,
              bt.loginPwdSection,
              "svgImg",
              "pwdImageRTL"
            )}
            onClick={() => this.handleChange(input.name)}
          >
            {showPassword === input.name ? (
              <img src={ShowPassword} />
            ) : (
              <img src={HidePassword} />
            )}
          </span>
        )}
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </>
    );
  };

  render() {
    const { error, handleSubmit, submitting, dispatch, siteName } = this.props;
    const { formatMessage } = this.props.intl;
    const { openForgotPasswordModal } = this.props;

    return (
      <form onSubmit={handleSubmit(submit)}>
        <div className="mx-auto max-w-full text-white">
          <h4 className="border-b border-[#606570] py-[25px] text-center text-white text-[24px] font-medium">
            Sign in
          </h4>

          <div className="!px-[20px] sm:!px-[200px]">
            {error && (
              <span className={s.errorMessage}>{formatMessage(error)}</span>
            )}

            <div className="bg-[#242526] rounded-[40px] px-2 sm:px-5 mt-[24px] py-1 shadow-[-2px_-2px_6px_0px_rgba(68,74,83,0.6),4px_4px_12px_0px_rgba(0,0,0,0.6)] flex items-center gap-x-4 mb-8">
              <div
                className={clsx(
                  "w-40 border-[3px] rounded-[40px] text-[14px] sm:text-base sm:font-medium cursor-pointer",
                  "border-[#242526] h-10 flex items-center w-1/2 min-w-[130px] sm:min-w-[168px] flex-grow justify-center",
                  {
                    "bg-[#18191A] shadow-[-2px_-2px_6px_0px_#444A53,4px_4px_4px_0px_rgba(0,0,0,0.25)] transition-all duration-300":
                      this.state.selected === "host",
                  }
                )}
                onClick={() => this.handleSelectedChange("host")}
              >
                <div>Sign in as a Host</div>
              </div>

              <div
                className={clsx(
                  "w-40 border-[3px] rounded-[40px]  text-[14px] sm:text-base sm:font-medium cursor-pointer",
                  "border-[#242526] h-10 flex items-center w-1/2 min-w-[130px] sm:min-w-[168px] flex-grow justify-center",
                  {
                    "bg-[#18191A] shadow-[-2px_-2px_6px_0px_#444A53,4px_4px_4px_0px_rgba(0,0,0,0.25)] transition-all duration-300":
                      this.state.selected === "guest",
                  }
                )}
                onClick={() => this.handleSelectedChange("guest")}
              >
                Sign in as a Guest
              </div>
            </div>

            <FormGroup className={clsx("w-full", bt.space3)}>
              <Field
                name="email"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.email)}
                className={cx(
                  bt.commonControlInput,
                  s.backgroundOne,
                  "backgroundOneRTL"
                )}
              />
            </FormGroup>
            <FormGroup className={cx("w-full", bt.space3, bt.pwdSection)}>
              <Field
                name="password"
                type="password"
                component={this.renderFormControl}
                label={formatMessage(messages.password)}
                className={cx(
                  bt.commonControlInput,
                  s.backgroundTwo,
                  "backgroundOneRTL"
                )}
                showPassword={this.state.showPassword}
                maxlength={25}
              />
            </FormGroup>
            <button
              className={cx(s.signupButtonBgContainer)}
              block
              type="submit"
              disabled={submitting}
            >
              <div className={cx(s.linearButton)}>
                <div className={cx(s.effectButton)}>
                  Sign in as a{" "}
                  <span className="capitalize ml-[4px]">
                    {this.state.selected}
                  </span>
                </div>
              </div>
            </button>
            <div
              className={cx(
                "text-center text-white text-base font-medium mb-[32px]",
                bt.buttonHover
              )}
              onClick={openForgotPasswordModal}
            >
              Forgot Password?
            </div>
          </div>
        </div>
      </form>
    );
  }
}

LoginForm = reduxForm({
  form: "LoginForm", // a unique name for this form
  validate,
  destroyOnUnmount: false,
})(LoginForm);

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
});

const mapDispatch = {
  openForgotPasswordModal,
};

export default injectIntl(
  withStyles(s, bt)(connect(mapState, mapDispatch)(LoginForm))
);
