// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// Redux Form
import { Field, reduxForm, reset, formValueSelector, change } from 'redux-form';

// Locale
import messages from '../../locale/messages';
import s from './StakingRequest.css';
import bt from '../../components/commonStyle.css';

// Redux
import { connect } from 'react-redux';

import { graphql, gql, compose } from 'react-apollo';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
    Button,
    FormGroup,
    Col,
    FormControl,
    Modal
} from 'react-bootstrap';
//Image 
import closeIcon from '../../../public/SiteIcons/commonModalCloseBtn.svg';
import { STAKING_TYPE } from '../../constants';
import { getBalanceUser } from '../../actions/getBalanceUser';
import { toastr } from 'react-redux-toastr';
import * as FontAwesome from 'react-icons/lib/fa';
import { LinearButton } from '../Button/GrayButton';

//Images

class StakingRequest extends Component {

    static propTypes = {
        formatMessage: PropTypes.any,
        listId: PropTypes.number,
        getListingData: PropTypes.shape({
            loading: PropTypes.bool,
            UserListing: PropTypes.object
        }),
    };

    constructor(props) {
        super(props);
        this.state = {
            shown: false,
            disabled: true,
        };
        this.toggle = this.toggle.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    toggle() {
        this.setState({
            shown: !this.state.shown
        });
    }

    onChange(e) {
        if (e.target.value && e.target.value.trim() != '') {
            this.setState({ disabled: false });
        } else {
            this.setState({ disabled: true });
        }
    }

    renderFormControl = ({ input, label, type, meta: { touched, error }, className, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={cx(s.formGroup, 'row')}>
                <Col xs={12} sm={12} md={12} lg={12} className={'rtlWishListInput'}>
                    {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    <FormControl {...input} placeholder={placeholder} type={type} className={className} />
                </Col>
            </FormGroup>
        );
    }

    async submitForm(values, dispatch) {
        const { mutate, profileId, wishListGroups, change, updateStakeRequest } = this.props;
        if (values.amount.trim() != '') {
            let updatedWishLists = wishListGroups;

            this.setState({
                shown: !this.state.shown
            });
            const res = await updateStakeRequest({
                variables: {
                    amount: Number(values.amount),
                    action: Number(STAKING_TYPE.ACTION_STAKE)
                },

            });
            if (res?.data?.updateStakeRequest?.status === "true") {
                toastr.success("Timebox success!")
                this.props.getBalanceUser()
                this.props.refetch()
              } else {
                toastr.error("Timebox failed!", res?.data?.unstakeRequest?.message)
                this.props.getBalanceUser()
                this.props.refetch()
              }
        }
    }

    render() {
        const { error, handleSubmit, submitting, dispatch, listId } = this.props;
        const { formatMessage } = this.props.intl;
        const { disabled, shown } = this.state;

        return (
            <div>
                <div className={cx('flex items-center rounded-lg  text-base font-medium')}>
                    <LinearButton onClick={this.toggle}>Timebox</LinearButton>
                {/* <button
                    onClick={this.toggle}
                    className={cx(
                        "text-white text-base h-10 py-[5px] px-5 rounded-md bg-[linear-gradient(270deg,#0242E4_0.24%,#0064FA_58.41%,#4986E2_99.85%)]",
                        "border-[3px] border-[#242526]",
                        "hover:bg-[#4986E2]",
                        "shadow-[-2px_-2px_6px_0px_#444A53,4px_4px_4px_0px_rgba(0,0,0,0.25)]",
                        "active:bg-[linear-gradient(270deg,#0242E4_0.24%,#091FE4_99.85%)] active:shadow-[2px_2px_6px_0px_#000_inset,-1px_-1px_3px_0px_#444A53_inset]"
                    )}
                    >
                       Stake
                </button> */}
                    
                </div>
                <Modal show={shown} animation={false} onHide={this.toggle} dialogClassName={cx(s.logInModalContainer, 'wishListCloseBtn', 'wishListCloseNew', s.wishlistModal)} >
                    <div className={s.sectionBlock}>
                        <Modal.Header >
                            <div className='flex justify-between items-center'>
                            <div className={cx(s.wishListTitle, 'font-semibold text-[18px]')}>
                                Timebox R3V
                            </div>
                            <div onClick={this.toggle} className={cx(s.commonCloseModalBtn, 'svgImg')}><img src={closeIcon} /></div>
                            </div>
                        </Modal.Header>
                        <Modal.Body bsClass={s.logInModalBody}>
                            <div className={s.root}>
                                <div className={cx(s.container, 'rtlWishListInput', 'p-[30px]')}>
                                    <div className={cx('inputFocusColor', s.paddingBottom)}>
                                        <form onSubmit={handleSubmit(this.submitForm)} autoComplete="off">

                                            {
                                                <div className={cx(s.textAignRight, 'textAlignLeftRtl')}>
                                                    <Field
                                                        name="amount"
                                                        type="number"
                                                        component={this.renderFormControl}
                                                        placeholder={'Amount'}
                                                        className={cx(bt.commonControlInput, s.space1)}
                                                        onChange={this.onChange}
                                                    />
                                                    <FormGroup className={cx(s.spaceTop3, s.formGroup, 'text-center mb-0 mt-5')}>
                                                        <LinearButton disabled={disabled} type="submit">Timebox R3V</LinearButton>
                                                    {/* <button
                                                      type="submit"
                                                      disabled={disabled}
                                                        className={cx(
                                                            "text-white text-base h-10 py-[5px] px-5 rounded-md bg-[linear-gradient(270deg,#0242E4_0.24%,#0064FA_58.41%,#4986E2_99.85%)]",
                                                            "border-[3px] border-[#242526]",
                                                            "hover:bg-[#4986E2]",
                                                            "shadow-[-2px_-2px_6px_0px_#444A53,4px_4px_4px_0px_rgba(0,0,0,0.25)]",
                                                            "active:bg-[linear-gradient(270deg,#0242E4_0.24%,#091FE4_99.85%)] active:shadow-[2px_2px_6px_0px_#000_inset,-1px_-1px_3px_0px_#444A53_inset]"
                                                        )}
                                                        >
                                                        
                                                    </button> */}
                                                        {/* <Button className={cx(bt.btnSecondaryFull)}
                                                            type="submit"
                                                            disabled={disabled}
                                                        >Stake R3V
                                                        </Button> */}
                                                    </FormGroup>
                                                </div>
                                            }
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                </Modal>
            </div>
        )
    }
}

StakingRequest = reduxForm({
    form: 'StakingRequest', // a unique name for this form
})(StakingRequest);

const selector = formValueSelector('StakingRequestModalForm');

const mapState = (state) => ({
    account: state?.account?.data,
    balance: state?.balance
});

const mapDispatch = {
    change,
    getBalanceUser
};

export default compose(injectIntl,
    withStyles(s, bt),
    connect(mapState, mapDispatch),
)(StakingRequest);