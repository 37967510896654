// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// Redux Form
import { Field, reduxForm, reset, formValueSelector, change } from 'redux-form';

// Locale
import messages from '../../locale/messages';
import s from './ListPlaceStep1.css';
import i from './ImportListing.css';
import bt from '../../components/commonStyle.css';

// Redux
import { connect } from 'react-redux';

import { graphql, gql, compose } from 'react-apollo';
//Image
import addIcon from '../../../public/SiteIcons/add.svg';
import groupIcon from '../../../public/SiteIcons/group.svg';
 

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
    Button,
    FormGroup,
    Col,    
    FormControl,
    Modal
} from 'react-bootstrap';
//Image 
import closeIcon from '../../../public/SiteIcons/commonModalCloseBtn.svg';
import { STAKING_TYPE } from '../../constants';
import { getBalanceUser } from '../../actions/getBalanceUser';
import { toastr } from 'react-redux-toastr';
import * as FontAwesome from 'react-icons/lib/fa';
import ButtonLoader from '../Loader/ButtonLoader';
import history from '../../core/history';

const checkAirbnbURL = (url) => {
    const regex = /^https:\/\/www\.airbnb\.(com|co\.[a-zA-Z]{2})\/(rooms|luxury\/listing)\/\d+(\?.*)?$/;
    return !/\s/.test(url) && regex.test(url);
};

//Images

class ImportListingModal extends Component {

    static propTypes = {
        formatMessage: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = {
            shown: false,
            disabled: true,
            loading: false,
            urlAirbnb: [''],
            error: ['']
        };
        this.toggle = this.toggle.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.onChange = this.onChange.bind(this);
        this.addField = this.addField.bind(this);
        this.removeField = this.removeField.bind(this);
    }

    toggle() {
        this.setState({
            shown: !this.state.shown
        });
    }

    onChange(e, index) {
        if (e.target.value && e.target.value.trim() != '') {
            const urlAirbnb = [...this.state.urlAirbnb]
            urlAirbnb[index] = e.target.value;
            console.log(urlAirbnb);
            this.setState({ urlAirbnb });
            if (checkAirbnbURL(e.target.value)) {
                this.setState({ disabled: false, error: [] });

            } else {
                const updatedErrors = [...this.state.error];
                updatedErrors[index] = 'URL invalid';
                this.setState({ disabled: true, error: updatedErrors });
            }
        } else {
            const updatedErrors = [...this.state.error];
            updatedErrors[index] = 'URL cannot be empty';
            this.setState({ disabled: true, error: updatedErrors });
        }
    }

    renderFormControl = ({ input, label, type, meta: { touched, error }, className, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={cx(s.formGroup, 'row')}>
                <Col xs={12} sm={12} md={12} lg={12} className={'rtlWishListInput'}>
                    {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    <FormControl {...input} placeholder={placeholder} type={type} className={className} />
                </Col>
            </FormGroup>
        );
    }

    async submitForm() {
        const { change, importListing } = this.props;
        const { urlAirbnb } = this.state
        event.preventDefault();
        console.log(urlAirbnb, 'urlAirbnburlAirbnb');
        if (urlAirbnb.filter(el => !!el).length > 0) {

            this.setState({
                loading: true
            });
            const res = await importListing({
                variables: {
                    urlAirbnb: urlAirbnb
                },

            });
            if (res?.data?.importListing?.status?.length && res?.data?.importListing?.status?.every(el => el === 'Import success')) {
                toastr.success("Import success!")
                this.setState({error: []})
                history.push('/rooms')
            } else {
                toastr.error("Import failed!")
                this.setState({error: res?.data?.importListing?.status})
            }
            this.setState({
                loading: false
            });

        }
    }


    addField = () => {
        const { urlAirbnb, error } = this.state;
        if(urlAirbnb.length > 4) {
            toastr.info("You can import 5 listings every time")
            return 
        } 
        this.setState({
            urlAirbnb: [...urlAirbnb, ''],
            error: [...error, ''],
        });
    };

    removeField = (index) => {
        const { urlAirbnb, error } = this.state;
        if (urlAirbnb.length < 2) return
        urlAirbnb.splice(index, 1);
        error.splice(index, 1);
        this.setState({ urlAirbnb, error });
    };

    render() {
        const { submitting, importListing } = this.props;
        const { formatMessage } = this.props.intl;
        const { disabled, shown, loading, error, urlAirbnb } = this.state;
        return (
            <div>
                <img onClick={this.toggle} src='/SiteImages/import-listing-img.svg' className=' block w-[196px] h-[48px] cursor-pointer' />
                <Modal show={shown} animation={false}  size="lg" onHide={this.toggle}   dialogClassName={s.modalContainer}
               
                >
                    <div className={s.sectionBlock}>
                        {/* <Modal.Header >
                            <div className='flex justify-between items-center'>
                                <div className={cx(s.wishListTitle, 'font-semibold text-[18px]')}>
                                    Import Airbnb listing
                                </div>
                                <div onClick={this.toggle} className={cx(s.commonCloseModalBtn, 'svgImg')}><img src={closeIcon} /></div>
                            </div>
                        </Modal.Header> */}
                        <Modal.Body bsClass={cx(i.modalBody)}>
                            
                            <div className={s.root}>
                                <div className={cx(s.container, 'rtlWishListInput', 'p-[30px]')}>
                                    <div className={cx('inputFocusColor', s.paddingBottom)}>
                                        <div className={'w-full flex justify-center'}>
                                        <div className={s.modalheading}>Import {' '}
                                            <span className={s.airbnb_text}>Airbnb</span>  {' '}
                                            listing
                                        </div>
                                        </div>
                                   
                                        <form onSubmit={this.submitForm} autoComplete="off">
                                            {
                                                <div className={cx(s.textAignRight, 'textAlignLeftRtl','mt-3')}>
                                                    <label htmlFor="price" className="block text-sm font-medium leading-6 text-[#E5E6EB]">
                                                        Sample Url: https://www.airbnb.com/rooms/770410970869283356
                                                    </label>
                                                    {urlAirbnb.map((value, index) => (
                                                        <>
                                                            <div className='flex gap-4 mt-3'>
                                                                <div className='w-full'>
                                                                    <Field
                                                                        name={`urlAirbnb[${index}]`}
                                                                        type="text"
                                                                        value={urlAirbnb[index]}
                                                                        component={this.renderFormControl}
                                                                        placeholder={'Airbnb listing url'}
                                                                        className={cx(bt.commonControlInput, s.space1, 'w-full self-center')}
                                                                        onChange={e => this.onChange(e, index)}
                                                                    />
                                                                </div>
                                                                <img
                                                                onClick={() => this.removeField(index)}
                                                                src={groupIcon}
                                                                className='cursor-pointer self-center mb-2'
                                                                alt='...'
                                                                />
                                                                {/* <Button className='h-[58px]' onClick={() => this.removeField(index)}><FontAwesome.FaMinus
                                                                    size={18}
                                                                    className='inline-block '
                                                                /></Button> */}
                                                            </div>
                                                            {error[index] && error[index].length > 0 && <span className={cx(bt.errorMessage, error[index] === 'Import success' ? '!text-lime-600' : '')}>{error[index]}</span>}
                                                        </>
                                                    ))}
                                                       <img
                                                        onClick={this.addField}
                                                        src={addIcon}
                                                        alt='...'
                                                        className='cursor-pointer self-center'
                                                        />

                                                    {/* <Button onClick={this.addField}><FontAwesome.FaPlus
                                                        size={18}
                                                        className='inline-block'
                                                    />Add field</Button> */}
                                                    <FormGroup className={cx(s.spaceTop3, s.formGroup, 'text-center mb-0 mt-5')}>
                                                        <ButtonLoader label={'Import'} show={loading} type="submit">
                                                        </ButtonLoader>
                                                    </FormGroup>
                                                </div>
                                            }
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapState = (state) => ({
    account: state?.account?.data,
    balance: state?.balance
});

const mapDispatch = {
    change,
    getBalanceUser
};

export default compose(injectIntl,
    withStyles(bt),
    connect(mapState, mapDispatch),
    graphql(gql`
        mutation importListing(
            $urlAirbnb:[String],
        ){
            importListing(
                urlAirbnb: $urlAirbnb,
            ) {
                status,
                id
            }
        }
    `, {
        name: 'importListing'
    })
)(ImportListingModal);