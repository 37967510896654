import React from 'react';
import UserLayout from '../../components/Layout/UserLayout';
import Search from './searchForm';
import { setSiteSettings } from '../../actions/siteSettings';
import messages from '../../locale/messages';

const title = 'Edit Profile';

export default async function action({ store, intl }) {

  const title = intl?.formatMessage(messages?.search);

  // From Redux Store
  let isAuthenticated = store.getState().runtime.isAuthenticated;
 
  return {
    title,
    component: <UserLayout><Search title={title} /></UserLayout>,
  };
}
