import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, gql, compose } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';

// Redux
import { connect } from 'react-redux';

// Style
import {
  Grid,
  Row,
  Col,
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Dashboard.css';
import bt from '../../components/commonStyle.css';

// Component
import guestIcon from "../../../public/SiteIcons/guest.svg"
import messageIcon from "../../../public/SiteIcons/message.svg"

import IdCard from "../../../public/SiteIcons/IdCard.svg"
import DashboardIcon from "../../../public/SiteIcons/Dashboard.svg"
import payout from "../../../public/SiteIcons/payout.svg"
import balance from "../../../public/SiteIcons/balance.svg"
import stake from "../../../public/SiteIcons/Stack.svg"
import history from '../../core/history';

// Locale
import messages from '../../locale/messages';

class Dashboard extends React.Component {

  static propTypes = {
    formatMessage: PropTypes.any,
    account: PropTypes.shape({
      userId: PropTypes.string.isRequired,
      picture: PropTypes.string,
    }).isRequired,
    allUnreadThreads: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      getUnreadThreads: PropTypes.array
    }),
    siteName: PropTypes.string.isRequired
  };

  static defaultProps = {
    allUnreadThreads: {
      loading: true,
      getUnreadThreads: []
    },
    account: {
      userId: null,
      picture: null
    }
  }

  render() {
    
    return (
      <>
        <div className="text-[32px] font-medium text-center">Account</div>
        <Grid fluid className={cx(s.pageContainer, "ViewProfile")}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className={s.mainSection}>
                <div
                  onClick={() => history.push("/user/view-profile")}
                  className={cx(s.cardButton, s.cardButtonProfile)}
                >
                  <img src={IdCard} className="w-[40px] h-[40px]" alt="..." />
                  <div className={s.sectionHeader}>
                    <FormattedMessage {...messages.dashboardMenuInfo} />
                  </div>
                </div>

                {/* <div
                  onClick={() => history.push("/user/view-profile")}
                  className={cx(s.cardButton)}
                >
                  <img
                    src={DashboardIcon}
                    className="w-[40px] h-[40px]"
                    alt="..."
                  />
                  <div className={s.sectionHeader}>
                    {" "}
                    <FormattedMessage {...messages.dashboardMenuDashboard} />
                  </div>
                </div> */}

                <div
                  onClick={() => history.push("/user/payout")}
                  className={cx(s.cardButton)}
                >
                  <img src={payout} className="w-[40px] h-[40px]" alt="..." />
                  <div className={s.sectionHeader}>
                    {" "}
                    <FormattedMessage {...messages.dashboardPaymentPayout} />
                  </div>
                </div>

                <div
                  onClick={() => history.push("/new-time-boxing")}
                  className={cx(s.cardButton)}
                >
                  <img src={stake} className="w-[40px] h-[40px]" alt="..." />
                  <div className={s.sectionHeader}>
                    {" "}
                    <FormattedMessage {...messages.dashboardMenuStaking} />
                  </div>
                </div>

                <div
                  onClick={() => history.push("/my-balance")}
                  className={cx(s.cardButton)}
                >
                  <img src={balance} className="w-[40px] h-[40px]" alt="..." />
                  <div className={s.sectionHeader}>
                    {" "}
                    <FormattedMessage {...messages.dashboardMenuBalances} />
                  </div>
                </div>
              </div>
              {/* <div className='flex flex-wrap gap-5'>
              
                <div onClick={()=> history.push('/user/edit')} className='flex flex-col max-w-[384px] border border-[#D3D4D5] rounded-xl py-5 gap-2 px-4 cursor-pointer min-w-[384px]'>
                    <img src={IdCard} className='w-[40px] h-[40px]' alt='...' />
                    <div className={s.sectionHeader}>   <FormattedMessage {...messages.dashboardMenuInfo} /></div>
                    <div className={s.secondary}>Provide personal details and how we can reach you</div>
                </div>
               
                <div  onClick={()=> history.push('/user/view-profile')} className='flex flex-col max-w-[384px] border border-[#D3D4D5] rounded-xl py-5 gap-2 px-4 cursor-pointer min-w-[384px]'>
                    <img src={DashboardIcon} className='w-[40px] h-[40px]' alt='...' />
                    <div className={s.sectionHeader}>   <FormattedMessage {...messages.dashboardMenuDashboard} /></div>
                    <div className={s.secondary}>Quickly access all your account features </div>
                </div>

                <div onClick={()=> history.push('/user/payout')}  className='flex flex-col max-w-[384px] border border-[#D3D4D5] rounded-xl py-5 gap-2 px-4 cursor-pointer min-w-[384px]'>
                    <img src={payout} className='w-[40px] h-[40px]' alt='...' />
                    <div className={s.sectionHeader}>   <FormattedMessage {...messages.dashboardPaymentPayout} /></div>
                    <div className={s.secondary}>Manage your payments and keep track of transactions</div>
                </div>

                <div onClick={()=> history.push('/new-staking')} className='flex flex-col max-w-[384px] border border-[#D3D4D5] rounded-xl py-5 gap-2 px-4 cursor-pointer min-w-[384px]'>
                    <img src={stake} className='w-[40px] h-[40px]' alt='...' />
                    <div className={s.sectionHeader}>   <FormattedMessage {...messages.dashboardMenuStaking} /></div>
                    <div className={s.secondary}>Stake R3V and keep track of rewards</div>
                </div>

                <div onClick={()=> history.push('/my-balance')} className='flex flex-col max-w-[384px] border border-[#D3D4D5] rounded-xl py-5 gap-2 px-4 cursor-pointer min-w-[384px]'>
                    <img src={balance} className='w-[40px] h-[40px]' alt='...' />
                    <div className={s.sectionHeader}>   <FormattedMessage {...messages.dashboardMenuBalances} /></div>
                    <div className={s.secondary}>All your platform balances</div>
                </div>
                <div onClick={()=> history.push('/user/verification')} className='flex flex-col max-w-[384px] border border-[#D3D4D5] rounded-xl py-5 gap-2 px-4 cursor-pointer min-w-[384px]'>
                    <img src={IdCard} className='w-[40px] h-[40px]' alt='...' />
                    <div className={s.sectionHeader}>   Verification</div>
                    <div className={s.secondary}>Verify your information</div>
                </div>
              </div> */}
            </Col>
          </Row>
        </Grid>
      </>
    );
  }
}

const mapState = (state) => ({
  account: state.account.data,
  siteName: state.siteSettings.data.siteName
});

const mapDispatch = {};

export default compose(
  injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch),
)(Dashboard);