import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "../Transaction.css";

// Components
import NoTransaction from "../NoTransaction";

// Locale
import messages from "../../../locale/messages";
import HostingCompletedTransactionItem from "./HostingCompletedTransactionItem";

class HostingCompletedTransaction extends Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        checkIn: PropTypes.string.isRequired,
        checkOut: PropTypes.string.isRequired,
        confirmationCode: PropTypes.number.isRequired,
        payoutId: PropTypes.number,
        listData: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }),
        guestData: PropTypes.shape({
          firstName: PropTypes.string.isRequired,
        }),
      })
    ),
  };

  static defaultProps = {
    data: [],
  };

  render() {
    const { data, totalCount } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className="rounded-lg border border-border overflow-hidden px-4 divide-y">
        {(data && data.length === 0) || totalCount === 0 ? (
          <div className={s.spaceMargin}>
            <NoTransaction
              type={"noTransactions"}
              totalCount={totalCount}
              noText={formatMessage(messages.noTransactionFuture)}
            />
          </div>
        ) : (
          data.map((item, index) => {
            return <HostingCompletedTransactionItem key={index} data={item} />;
          })
        )}
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(HostingCompletedTransaction));
