import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { graphql, compose } from 'react-apollo';

import { Field, reduxForm, reset } from 'redux-form';
import validate from './validate';

// Style
import {
  Button,
  FormGroup,
  Col,
  FormControl,
  Panel,
  Grid,
  Row
} from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './BuyR3vForm.css';
import bt from '../../../components/commonStyle.css';

// Component
import { toastr } from 'react-redux-toastr';

import DropZone from './DropZone';
import Loader from '../../../components/Loader';

// GraphQL
import AddBuyR3VMutation from './AddBuyR3VMutation.graphql';
import history from '../../../core/history';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';


class BuyR3vForm extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    initialValues: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      image1: [],
      image2: [],
      image3: [],
      enableBtn: false
    }
    this.uploadImage1 = this.uploadImage1.bind(this)
    this.uploadImage2 = this.uploadImage2.bind(this)
    this.uploadImage3 = this.uploadImage3.bind(this)
    this.submitForm = this.submitForm.bind(this);
  }

  renderFormControl = ({ input, label, type, meta: { touched, error }, className, placeholder }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={bt.space3}>
        <label className={s.labelTextNew}>{label}</label>
        <FormControl {...input} type={type} className={bt.commonControlInput} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>

    );
  }

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, className, children }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={bt.space3}>
        <label className={s.labelTextNew}>{label}</label>
        <FormControl
          {...input}
          className={className}
          componentClass={"textarea"}
        />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    )
  }

  async submitForm(values, dispatch) {
    const { mutate } = this.props;
    const { image1, image2, image3 } = this.state
    const { data } = await mutate({
      variables: {
        data: JSON.stringify({
          proofUSDtoUSDT: image1,
          proofUSDTtoR3V: image2,
          proofR3VTransfer: image3,
          amountUSD: values?.amountUSD || 0
        })
      }
    });
    if (data && data.addBuyR3V) {
      if (data.addBuyR3V.status === 1) {
        toastr.success("Submitted Successfully!", "Page Buy R3V details submitted successfully!");
        dispatch(reset('BuyR3vForm'));
      } else {
        toastr.error("Failed to update!", "Your changes to Page Buy R3V is failed!");
      }
    }
  }

  uploadImage1 = (file) => {
    console.log('uploadImage111');
    this.setState({ image1: [...this.state.image1, file] })
  }

  uploadImage2 = (file) => {
    console.log('uploadImage222');
    this.setState({ image2: [...this.state.image2, file] })
  }

  uploadImage3 = (file) => {
    console.log('uploadImage333');
    this.setState({ image3: [...this.state.image3, file] })
  }

  render() {
    const { error, handleSubmit, initialValues, } = this.props;
    const { image1, image3, image2, enableBtn } = this.state
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <Grid fluid>
          <Row className="justify-content-center">
            <h1 className={s.headerTitle}> <FormattedMessage {...messages.writeAReview} /></h1>
            <Col xs={12} sm={12} md={8} lg={8} cent className={s.blockcenter}>
              <Panel className={cx(s.panelHeader, 'bgBlack')}>
                <form onSubmit={handleSubmit(this.submitForm)}>
                  {error && <strong>{error}</strong>}
                  
                  <FormGroup className={cx(bt.space3, 'hidden absolute')} key={'0001'}>
                    <label className={s.labelTextNew} >Proof images of USD {'->'} USDT</label>
                    <div className={bt.space3}>
                      <DropZone key={1} data={image1} id="upload11" doUploadImage={(file) => {
                        console.log('uploadImage111');
                        this.setState({ image1: [...this.state.image1, file] })
                      }} placeholder={formatMessage(messages.photosPlaceholder)} />
                      <Loader
                        show={false}
                        type={"page"}
                      >
                        {
                          image1 != [] &&
                          <Row>
                            {image1.map(el => (
                              <Col xs={12} sm={6} md={6} lg={4} key={el}>
                                <div
                                  style={{ backgroundImage: `url(/images/banner/${el})` }}
                                  className={s.bannerImageBg}
                                />
                              </Col>
                            ))}

                          </Row>
                        }
                      </Loader>
                    </div>
                  </FormGroup><Field name="amountUSD" type="text" component={this.renderFormControl} label={'USD withdraw amount'} />
                  <FormGroup className={bt.space3} key={1}>
                    <label className={s.labelTextNew} >Proof images of USD {'->'} USDT</label>
                    <div className={bt.space3}>
                      <DropZone key={1} data={image1} id="upload11" doUploadImage={(file) => {
                        console.log('uploadImage111');
                        this.setState({ image1: [...this.state.image1, file] })
                      }} placeholder={formatMessage(messages.photosPlaceholder)} />
                      <Loader
                        show={false}
                        type={"page"}
                      >
                        {
                          image1 != [] &&
                          <Row>
                            {image1.map(el => (
                              <Col xs={12} sm={6} md={6} lg={4} key={el}>
                                <div
                                  style={{ backgroundImage: `url(/images/banner/${el})` }}
                                  className={s.bannerImageBg}
                                />
                              </Col>
                            ))}

                          </Row>
                        }
                      </Loader>
                    </div>
                  </FormGroup>
                  <FormGroup className={bt.space3} key={2}>
                    <label className={s.labelTextNew} >Proof images of USDT {'->'} R3V</label>
                    <div className={bt.space3}>
                      <DropZone data={image2} key={2} id="upload22" doUploadImage={(file) => {
                        console.log('uploadImage222');
                        this.setState({ image2: [...this.state.image2, file] })
                      }} placeholder={formatMessage(messages.photosPlaceholder)} />
                      <Loader
                        show={false}
                        type={"page"}
                      >
                        {
                          image2 != [] &&
                          <Row>
                            {image2.map(el => (
                              <Col xs={12} sm={6} md={6} lg={4} key={el}>
                                <div
                                  style={{ backgroundImage: `url(/images/banner/${el})` }}
                                  className={s.bannerImageBg}
                                />
                              </Col>
                            ))}

                          </Row>
                        }
                      </Loader>
                    </div>
                  </FormGroup>
                  <FormGroup className={bt.space3} key={3}>
                    <label className={s.labelTextNew} >Proof images of transfer R3V</label>
                    <div className={bt.space3}>
                      <DropZone data={image3} key={3} id="upload33" doUploadImage={(file) => {
                        console.log('uploadImage333');
                        this.setState({ image3: [...this.state.image3, file] })
                      }} placeholder={formatMessage(messages.photosPlaceholder)} />
                      <Loader
                        show={false}
                        type={"page"}
                      >
                        {
                          image3 != [] &&
                          <Row>
                            {image3.map(el => (
                              <Col xs={12} sm={6} md={6} lg={4} key={el}>
                                <div
                                  style={{ backgroundImage: `url(/images/banner/${el})` }}
                                  className={s.bannerImageBg}
                                />
                              </Col>
                            ))}

                          </Row>
                        }
                      </Loader>
                    </div>
                  </FormGroup>
                  <div className={cx(bt.textAlignRight, 'textAlignLeftRtl')}>
                    <Button className={cx(bt.btnPrimary, bt.btnLarge)} type="submit" disabled={!image1.length || !image3.length || !image2.length} >
                      <FormattedMessage {...messages.submitLabel} />
                    </Button>
                  </div>
                </form>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

BuyR3vForm = reduxForm({
  form: 'BuyR3vForm', // a unique name for this form
  validate
})(BuyR3vForm);

export default compose(injectIntl,
  withStyles(s, bt),
  graphql(AddBuyR3VMutation)
)(BuyR3vForm);