import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Table, Tr, Td, Thead, Th } from 'reactable';
import messages from '../../../locale/messages';
import s from './InquiryManagement.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import Link from '../../Link/Link';
import moment from 'moment';
import CustomPagination from '../../CustomPagination/CustomPagination';
import ExportImage from '../../../../public/adminIcons/export.png';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

class InquiryManagement extends Component {

    handleSearchChange = (e) => {
        const { getAllInquiryQuery: { refetch }, changeStateValues } = this.props;
        let variables = {
            currentPage: 1,
            searchList: e.target.value,
        };
        changeStateValues({
            currentPage: 1,
            searchList: e.target.value,
        });
        refetch(variables);
    }

    paginationData = (currentPage) => {
        const { getAllInquiryQuery: { refetch }, changeStateValues, searchList } = this.props;
        let variables = { currentPage, searchList };
        changeStateValues({ currentPage });
        refetch(variables);
    }

    takeAction = async (threadId) => {
        const { refetch } = this.props;
        let query = `query checkThreadData($threadId:Int){
            checkThreadData(threadId:$threadId){
                status
            }
        }`;

        const resp = await fetch('/graphql', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query,
                variables: { threadId },
            }),
            credentials: 'include',
        });

        const { data } = await resp.json();
        if (data?.checkThreadData?.status == '200') {
            return true
        } else {
            toastr.error('Error!', 'You have already approved')
            let variables = {
                searchKey: '',
                currentPage: 1
            };
            await refetch(variables)
            return false
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { getAllInquiryQuery, currentPage, searchList, toCurrency } = this.props;
        let userType = 'host';
        return (
            <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
                <h1 className={s.headerTitle}><FormattedMessage {...messages.manageInquiry} /></h1>
                <div className={cx(s.exportSection, s.exportSectionGridSub, 'bgBlack')}>
                    <div>
                        <FormControl
                            type="text"
                            placeholder={formatMessage(messages.search)}
                            onChange={(e) => this.handleSearchChange(e)}
                            className={cx('searchInputControl', 'searchInputControlWidth', 'searchInputControlAR')}
                        />
                    </div>
                    <div>
                        {
                            getAllInquiryQuery?.getAllInquiryAdmin?.inquiryData?.length > 0 && <a
                                href={`/export-admin-data?type=inquiry&keyword=${searchList}&toCurrency=${toCurrency}`}
                                className={cx(s.exportText, 'commonFloatLeft', 'textWhite')}>
                                <span className={s.vtrMiddle}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                                <span className={cx(s.exportLinkImg, 'exportLinkImgCommon')}>
                                    <img src={ExportImage} className={s.exportImg} />
                                </span>
                            </a>
                        }
                    </div>
                </div>
                <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
                    <Table className="table" noDataText={formatMessage(messages.noRecordFound)} sortable={true}>
                        <Thead>
                            <Th scope="col">{formatMessage(messages.id)}</Th>
                            <Th scope="col">{formatMessage(messages.adminListTitle)}</Th>
                            <Th scope="col">{formatMessage(messages.checkInAndCheckOut)}</Th>
                            <Th scope="col">{formatMessage(messages.bookingStatus)}</Th>
                            <Th scope="col">{formatMessage(messages.bookingAction)}</Th>
                            <Th scope="col">{formatMessage(messages.guestEmail)}</Th>
                            <Th scope="col">{formatMessage(messages.hostEMailLabel)}</Th>
                            <Th scope="col">{formatMessage(messages.details)}</Th>
                        </Thead>
                        {
                            getAllInquiryQuery?.getAllInquiryAdmin?.inquiryData?.map((value, index) => {
                                let checkIn = moment(value.startDate).format('DD MMMM YYYY')
                                let checkOut = moment(value.endDate).format('DD MMMM YYYY')
                                return (
                                    <Tr key={index}>
                                        <Td data-label={formatMessage(messages.id)} column={formatMessage(messages.id)} data={value.id} />
                                        {
                                            value?.threadsData?.listData?.title &&
                                            <Td data-label={formatMessage(messages.adminListTitle)} column={formatMessage(messages.adminListTitle)}>
                                                <a href={"/rooms/" + value.threadsData.listId} target='_blank'>
                                                    {value?.threadsData?.listData?.title}
                                                </a>
                                            </Td>
                                        }
                                        <Td data-label={formatMessage(messages.checkInAndCheckOut)} column={formatMessage(messages.checkInAndCheckOut)} data={`${checkIn} - ${checkOut}`} />
                                        <Td data-label={formatMessage(messages.bookingStatus)} column={formatMessage(messages.bookingStatus)} >
                                            <p>{value?.data ? <FormattedMessage {...messages.messageStatus2} /> : <FormattedMessage {...messages.panelInquiry} />}</p>
                                        </Td>
                                        <Td data-label={formatMessage(messages.bookingAction)} column={formatMessage(messages.bookingAction)}>
                                            {
                                                value?.data == "preApproved" ?
                                                    <p>
                                                        <span>
                                                            -
                                                        </span>
                                                    </p>
                                                    :
                                                    <a
                                                        onClick={() => this.takeAction(value.threadId)}
                                                        target="_blank" href={"/message/" + value.threadId + "/" + userType}
                                                        className={cx(s.previewLink)}>
                                                        < FormattedMessage {...messages.manageLabel} />
                                                    </a>
                                            }
                                        </Td>
                                        <Td data-label={formatMessage(messages.guestEmail)} column={formatMessage(messages.guestEmail)} data={value?.threadsData?.guestUserData?.email} />
                                        <Td data-label={formatMessage(messages.hostEMailLabel)} column={formatMessage(messages.hostEMailLabel)} data={value?.threadsData?.hostUserData?.email} />
                                        <Td data-label={formatMessage(messages.details)} column={formatMessage(messages.details)}>
                                            <Link to={"/siteadmin/viewInquiry/" + value.id + "/inquiry"} >
                                                < FormattedMessage {...messages.viewLabel} />
                                            </Link>
                                        </Td>
                                    </Tr>
                                )
                            })
                        }
                    </Table>
                </div>
                {
                    getAllInquiryQuery?.getAllInquiryAdmin?.inquiryData?.length > 0
                    && <div>
                        <CustomPagination
                            total={getAllInquiryQuery.getAllInquiryAdmin.count}
                            currentPage={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            change={this.paginationData}
                            paginationLabel={formatMessage(messages.panelInquiry)}
                        />
                    </div>
                }
            </div >
        )
    }
};

const mapState = (state) => ({
    toCurrency: state.currency.to || state.currency.base,
});

const mapDispatch = {
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(InquiryManagement)));