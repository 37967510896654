import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import s from './Transaction.css';
import { convert } from '../../helpers/currencyConvertion';
import cx from 'classnames';
// Locale
import messages from '../../locale/messages';

// Component
import CurrencyConverter from '../CurrencyConverter';

//Images
import paidOutImage from '../../../public/SiteIcons/paidOutIcon.svg';

class TotalAmount extends Component {
	static propTypes = {
		className: PropTypes.string.isRequired,
		mode: PropTypes.string.isRequired
	};

	static defaultProps = {
		data: []
	};

	render() {
		const { className, mode, data, base, rates, toCurrency } = this.props;
		const { formatMessage } = this.props.intl;
		let label, currency, amountUSD = 0, amountR3V = 0, convertedTotal = 0, convertedHostService = 0, convertedPayout = 0;
		let hostTransactionAmount = 0, hostTransactionCurrency;
		let hostTransactionAmountUsd = 0;
		let hostTransactionAmountR3v = 0;
		if (mode === 'completed') {
			label = formatMessage(messages.paidOut);
			if (data != null && data.length > 0) {
				data.map((item) => {
					console.log({
						payoutDebug_step1: {
							item: item,
							amount: amountUSD
						}
					});
					// hostTransactionAmount = item && item.hostTransaction && item.hostTransaction.amount ? item.hostTransaction.amount : 0;
					hostTransactionAmountUsd = item && item.grossProceedsOfHostUSD ? item.grossProceedsOfHostUSD : 0;
					hostTransactionAmountR3v = item && item.grossProceedsOfHostR3V ? item.grossProceedsOfHostR3V : 0;

					hostTransactionCurrency = item && item.hostTransaction && item.hostTransaction.currency ? item.hostTransaction.currency : base;
					convertedTotal = convert(base, rates, hostTransactionAmountR3v, hostTransactionCurrency, toCurrency);
					amountUSD = amountUSD + hostTransactionAmountUsd + convertedTotal;

					currency = hostTransactionCurrency;

					console.log({
						payoutDebug_step2: {
							item: item,
							amount: amountUSD,
							convertedTotal: convertedTotal
						}
					});
				})
			}
		} else {
			label = formatMessage(messages.pendingPayouts);
			if (data != null && data.length > 0) {
				data.map((item) => {

					if (item && item.cancellationDetails && item.cancellationDetails.payoutToHost) {
						convertedPayout = convert(base, rates, (item.cancellationDetails.payoutToHost || 0), item.cancellationDetails.currency, toCurrency);
						amountUSD = amountUSD + convertedPayout;
						currency = item.cancellationDetails.currency;
					} else {
						convertedTotal = convert(base, rates, item.total, item.currency, toCurrency);
						convertedHostService = convert(base, rates, item.hostServiceFee, item.currency, toCurrency);
						amountUSD = amountUSD + (convertedTotal - convertedHostService);
						currency = item.currency;
					}
				})
			}
		}
		return (
			<h3 className={s.totalAmountText}>
				<span className={'svgImg'}><img src={paidOutImage} className={cx(s.paidImg, 'paidImgRTL')} /></span>
				<span>{label}: </span>
				<span>
					<CurrencyConverter
						amount={amountUSD}
						from={toCurrency}
					/>
				</span>
			</h3>
		);
	}
}


const mapState = (state) => ({
	base: state.currency.base,
	rates: state.currency.rates,
	toCurrency: state.currency.to,
});

const mapDispatch = {
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(TotalAmount)));
