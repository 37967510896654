// General
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux Form
import { Field, reduxForm } from "redux-form";

// Redux
import { connect } from "react-redux";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Grid, Button, Row, Col } from "react-bootstrap";
import s from "./ListPlaceStep1.css";
import bt from "../../components/commonStyle.css";

import update from "./update";

// Component
import Avatar from "../Avatar";

// Images
import BgImage from "../../../public/SiteImages/createListBg.svg";
import ListImage from "../../../public/SiteImages/createListImage.svg";
import darkImage from "../../../public/SiteImages/darkIntro.svg";
import waveIcon from "../../../public/SiteIcons/waving-hand.png";
import ImportListingModal from "./ImportListingModal";
import OR from "../../../public/SiteImages/or.svg";
import OrMobile from "../../../public/SiteIcons/ormobile.svg";
import history from "../../core/history";
import { LinearButton, LinearButtonSolid } from "../Button/GrayButton";

class ListIntro extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    hostDisplayName: PropTypes.string.isRequired,
    guestDisplayName: PropTypes.string,
    nextPage: PropTypes.any,
    hostPicture: PropTypes.string,
    guestPicture: PropTypes.string,
    userData: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    userData: {
      firstName: "",
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      roomType: [],
      personCapacity: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { listingFields } = this.props;
    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType,
        personCapacity: listingFields.personCapacity,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { listingFields } = nextProps;
    if (listingFields != undefined) {
      this.setState({
        roomType: listingFields.roomType,
        personCapacity: listingFields.personCapacity,
      });
    }
  }

  render() {
    const {
      nextPage,
      userData,
      hostPicture,
      hostDisplayName,
      guestDisplayName,
    } = this.props;
    return (
      <>
        <div className="flex flex-col bg-[#18191A] justify-center mt-[110px] mb-[190px] items-center px-[160px] pb-[94px] max-w-[990px] mx-auto my-0 rounded shadow-[-6px_-6px_18px_0px_#444A53,6px_6px_18px_0px_#000]">
          {/* <img
            src={EmptyListingLayout}
            alt="..."
            className="w-[523px] h-[300px] mt-24"
          /> */}
          <div className={cx(s.descTitle, "mt-[24px]")}>
            {" "}
            <FormattedMessage {...messages.introCreateListing} />
          </div>
          <div className="flex w-full mt-[94px] justify-between ">
            <div className="flex flex-col content-center items-center">
              <div className={s.getYourReady}>
                <FormattedMessage {...messages.letYouGetReady} />
              </div>
              <LinearButton
                containerClassname="mt-[24px]"
                size={[196, 32]}
                py={8}
                onClick={() => nextPage("house-type")}
              >
                <div className="text-[16px]">Get Started</div>
              </LinearButton>
              {/* <Button
                className={cx(
                  s.button,
                  bt.btnPrimary,
                  bt.btnLarge,
                  bt.gradientBackground,
                  s.space5,
                  s.textBold,
                  s.borderWhiteBtn,
                  "max-w-[137px] !mt-[21px]"
                )}
                
              >
                
              </Button> */}
            </div>
            <img src={OR} alt="..." className="w-[42px] h-[71px]" />
            <div className="flex flex-col items-center">
              <div className={cx(s.getYourReady)}>
                Already a host on Airbnb?
              </div>
              <LinearButtonSolid
                containerClassname="mt-[24px]"
                size={[200, 36]}
                py={8}
                onClick={() => {
                  history.push("/import-listing");
                }}
              >
                <div className="text-[16px]">Import Airbnb listings</div>
              </LinearButtonSolid>
              {/* <Button
                className={cx(
                  s.buttonSecondary,
                  s.space5,
                  s.spaceTop2,
                  s.textBold,
                  "max-w-[204px] self-center"
                )}
                onClick={() => nextPage("room")}
              >
                Import Airbnb listing
              </Button> */}
            </div>
          </div>
        </div>
        {/* <div className={s.listIntroBgSection}>
          <div
            className={cx(s.listIntroBg, "listIntroBgImage")}
            style={{ backgroundImage: `url(${BgImage})` }}
          ></div>
          <Grid fluid className={s.listIntroContainer}>
            <div className={s.userRight}>
              <Avatar
                isUser
                title={guestDisplayName}
                className={s.profileImage}
              />
            </div>
            <h3 className={s.listIntroTitle}>
              <img src={waveIcon} className={s.waveCss} />{" "}
              <FormattedMessage {...messages.hi} />,
              <span className={s.userNameColor}>{userData.firstName}!</span>
            </h3>
            <h3 className={cx(s.listIntroTitle, s.spaceTop4, s.spaceBottom20)}>
              <FormattedMessage {...messages.letYouGetReady} />.
            </h3>
            <Button
              className={cx(
                s.button,
                bt.btnPrimary,
                bt.btnLarge,
                s.space5,
                s.spaceTop2,
                s.textBold
              )}
              onClick={() => nextPage("room")}
            >
              <FormattedMessage {...messages.letsStart} />
            </Button>
            <h3 className={cx(s.listIntroTitle, s.spaceTop4, s.spaceBottom20)}>
              Already a host on Airbnb?
            </h3>
            <ImportListingModal />
          </Grid>
        </div> */}
      </>
    );
  }
}

ListIntro = reduxForm({
  form: "ListPlaceStep1", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: update,
})(ListIntro);

const mapState = (state) => ({
  userData: state.account.data,
  listingFields: state.listingFields.data,
});

const mapDispatch = {};

export default injectIntl(
  withStyles(s, bt)(connect(mapState, mapDispatch)(ListIntro))
);
