exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ViewProfile-containerMain-2BMlU {\n    padding-left: 0 !important;\n    padding-right: 0 !important;\n    max-width: 1440px !important;\n    padding-top: 0px !important;\n}\n@media screen and (max-width: 1440px) {\n    .ViewProfile-containerMain-2BMlU {\n        padding-left: 60px !important;\n        padding-right: 60px !important;\n        max-width: 1440px !important;\n        padding-top: 0px !important;\n    }\n}", ""]);

// exports
exports.locals = {
	"containerMain": "ViewProfile-containerMain-2BMlU"
};