import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Redux
import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";

import CurrencyConverter from "../CurrencyConverter";
import { injectIntl } from "react-intl";

// External Component
import DayPicker, { DateUtils } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import { Row, Col } from "react-bootstrap";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "!isomorphic-style-loader!css-loader!./DayDragCalendar.css";

import SaveCalendar from "./SaveCalendar";

// Local
import { isRTL } from "../../helpers/formatLocale";

//Helper
import { getDateUsingTimeZone } from "../../helpers/dateRange";
import backIcon from "../../../public/SiteIcons/backward.svg";
import nextIcon from "../../../public/SiteIcons/forward.svg";
import { format } from "../../utils/datetime";
import cx from "classnames";
class DayDragCalendar extends Component {
  static propTypes = {
    change: PropTypes.func,
    formName: PropTypes.string,
    disabledDates: PropTypes.array,
    blockedDates: PropTypes.array,
  };

  static defaultProps = {
    disabledDates: [],
    blockedDates: [],
    listId: null,
    sources: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedMonth: new Date(),
      selectedDays: [],
      from: undefined,
      to: undefined,
      dateRange: [],
      //availableDates: [],
      chooseRangeDate: [],
      isPrice: [],
      sources: [],
    };
    this.isDaySelected = this.isDaySelected.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.resetCalendar = this.resetCalendar.bind(this);
    this.renderDay = this.renderDay.bind(this);
    this.resetDatePickerChange = this.resetDatePickerChange.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { blockedDates, sources, availableDatesPrices } = this.props;
    if (blockedDates != undefined) {
      this.setState({ selectedDays: blockedDates });
    }

    let sourcesValue = [];
    let sourceObject = {};

    availableDatesPrices &&
      availableDatesPrices.map((item, key) => {
        sourceObject = {};
        sourceObject["isSpecialPrice"] = item.isSpecialPrice;
        sourceObject["blockedDates"] = item.date;
        sourcesValue.push(sourceObject);
      });

    this.setState({ sources: sourcesValue });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { blockedDates, sources, availableDatesPrices } = nextProps;
    if (blockedDates != undefined) {
      this.setState({ selectedDays: blockedDates });
    }
    let sourcesValue = [];
    let sourceObject = {};

    availableDatesPrices &&
      availableDatesPrices.map((item, key) => {
        sourceObject = {};
        sourceObject["isSpecialPrice"] = item.isSpecialPrice;
        sourceObject["blockedDates"] = item.date;
        sourcesValue.push(sourceObject);
      });

    this.setState({ sources: sourcesValue });
  }

  scrollTop() {
    window.scrollTo({
      top: 700,
      behavior: "smooth",
    });
  }

  renderDay(day, modifiers) {
    const { currency } = this.props;
    const { sources } = this.state;
    const date = day.getDate();
    let dateRangeValue = moment(day).format("YYYY-MM-DD");

    const dotcolor = modifiers?.secondHalfBlocked
      ? "#DE350B"
      : (modifiers?.selectedEnd || modifiers?.selectedStart) &&
        !modifiers?.booked &&
        !modifiers?.selecting &&
        !modifiers?.disabled
      ? "#DE350B"
      : (modifiers?.availableStart || modifiers?.availableEnd) &&
        !modifiers?.booked &&
        !modifiers?.selecting &&
        !modifiers?.disabled
      ? "#1DD265"
      : null;
    return (
      <div className={cx(s.responsiveDisplay, "date-available-container")}>
        <span className={"dateFontWeight"}>{date}</span>
        <div
          className={cx("date-available-dot", `bg-[${dotcolor || "#88898E"}]`)}
        ></div>
        <div>
          {sources &&
            sources.map((item, key) => {
              let dateValue = moment(item.blockedDates).format("YYYY-MM-DD");
              if (dateRangeValue == dateValue) {
                return (
                  <div className={"priceAlignment pt-[4px]"}>
                    <CurrencyConverter
                      amount={item.isSpecialPrice}
                      from={currency}
                    />
                  </div>
                );
              }
            })}
        </div>
      </div>
    );
  }

  isDaySelected(day) {
    const { selectedDays } = this.state;

    if (selectedDays && selectedDays.length > 0) {
      return selectedDays.some((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
    }
  }

  async handleDayClick(day, { start, end, selected, disabled }) {
    const { blockedDates, change, formName } = this.props;
    let selectedDays = blockedDates.slice();
    let startDate, endDate;
    let dateRange = [],
      rangeStart,
      rangeEnd;

    if (disabled) {
      return;
    }

    const range = DateUtils.addDayToRange(day, this.state);
    startDate = range.from;
    endDate = range.to;

    if (startDate && !endDate) {
      rangeStart = new Date(startDate);
      dateRange.push(rangeStart);
    } else if (startDate && endDate) {
      rangeStart = new Date(startDate);
      rangeEnd = new Date(endDate);

      if (!DateUtils.isSameDay(rangeStart, rangeEnd)) {
        dateRange.push(rangeStart);

        rangeStart = new Date(+rangeStart);

        while (rangeStart < endDate) {
          dateRange.push(rangeStart);
          var newDate = rangeStart.setDate(rangeStart.getDate() + 1);
          rangeStart = new Date(newDate);
        }
      } else {
        startDate = null;
        endDate = null;
        dateRange, (selectedDays = []);
      }
    }

    const isBrowser = typeof window !== "undefined";
    let smallDevice = isBrowser
      ? window.matchMedia("(max-width: 768px)").matches
      : true;
    if (smallDevice) this.scrollTop();

    this.setState({ selectedDays, dateRange, from: startDate, to: endDate });

    change("ListPlaceStep3", "startDate", startDate);
    change("ListPlaceStep3", "endDate", endDate);
  }

  resetCalendar() {
    const { change } = this.props;
    // this.setState({ dateRange: [], from: null, to: null, startDate: null, endDate: null });
    this.setState({
      dateRange: [],
      from: null,
      to: null,
      startDate: null,
      endDate: null,
    });
    change("ListPlaceStep3", "startDate", null);
    change("ListPlaceStep3", "endDate", null);
  }

  resetDatePickerChange() {
    const { change } = this.props;
    this.setState({ dateRange: [], from: null, to: null });
  }
  handleNextMonth = () => {
    const nextMonth = new Date(this.state.selectedMonth);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    this.setState({ selectedMonth: nextMonth });
  };
  handleBackMonth = () => {
    const nextMonth = new Date(this.state.selectedMonth);
    nextMonth.setMonth(nextMonth.getMonth() - 1);
    this.setState({ selectedMonth: nextMonth });
  };
  render() {
    const { selectedDays, from, to, dateRange } = this.state;
    const {
      disabledDates,
      formName,
      listId,
      availableDates,
      todayLabel,
      halfDates,
    } = this.props;
    const { availableDatesPrices } = this.props;
    const { sources } = this.state;
    const { minNight, maxNight, houseRules, checkInEnd, checkInStart, locale } =
      this.props;
    const { cancellationPolicy, maxDaysNotice, bookingNoticeTime } = this.props;
    const { cleaningPrice, basePrice, currency } = this.props;
    const { isStartDate, isEndDate, country } = this.props;

    let listCountryDate = getDateUsingTimeZone(country, false);
    let today = new Date(
      listCountryDate.get("year"),
      listCountryDate.get("month"),
      listCountryDate.get("date"),
      listCountryDate.get("hour"),
      listCountryDate.get("minute"),
      listCountryDate.get("second")
    );

    const modifiers = {
      start: from,
      end: to,
      selected: selectedDays,
      selecting: dateRange,
      available: availableDates,
      availableStart: halfDates && halfDates.secondHalfAvailable,
      availableEnd: halfDates && halfDates.firstHalfAvailable,
      selectedStart: halfDates && halfDates.secondHalfBlocked,
      selectedEnd: halfDates && halfDates.firstHalfBlocked,
      bookedStart: halfDates && halfDates.secondHalfDisabled,
      bookedEnd: halfDates && halfDates.firstHalfDisabled,
      booked: disabledDates,
      secondHalfBlocked: halfDates && halfDates.secondHalfBlocked,
    };
    return (
      <Row>
        <Col lg={8} md={12} sm={12} xs={12} className={"saveCalender"}>
          <DayPicker
            navbarElement={
              <div className="customNavbar">
                <div
                  className="customNavbarButton"
                  onClick={this.handleBackMonth}
                >
                  <img src={backIcon} />
                </div>
                <div className="customNavbarCaption">
                  {format(this.state.selectedMonth, "MMMM YYYY")}
                </div>
                <div
                  className="customNavbarButton"
                  onClick={this.handleNextMonth}
                >
                  <img src={nextIcon} />
                </div>
              </div>
            }
            month={this.state.selectedMonth}
            selectedDays={[this.isDaySelected, from, { from, to }]}
            onDayClick={this.handleDayClick}
            modifiers={modifiers}
            disabledDays={[DateUtils.isPastDay, ...disabledDates]}
            fromMonth={today}
            renderDay={this.renderDay}
            locale={isRTL(locale) ? locale : "en-US"}
            localeUtils={MomentLocaleUtils}
            // todayButton={todayLabel}
            className={"BecomeCalendar"}
            transitionDuration={0}
            dir={isRTL(locale) ? "rtl" : "ltr"}
          />
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <SaveCalendar
            selectedDays={dateRange}
            start={from}
            end={to}
            formName={formName}
            resetCalendar={this.resetCalendar}
            resetDatePickerChange={this.resetDatePickerChange}
            listId={listId}
            minNight={minNight}
            maxNight={maxNight}
            houseRules={houseRules}
            checkInEnd={checkInEnd}
            checkInStart={checkInStart}
            cancellationPolicy={cancellationPolicy}
            maxDaysNotice={maxDaysNotice}
            bookingNoticeTime={bookingNoticeTime}
            cleaningPrice={cleaningPrice}
            basePrice={basePrice}
            currency={currency}
            isStartDate={isStartDate}
            isEndDate={isEndDate}
          />
        </Col>
      </Row>
    );
  }
}

const selector = formValueSelector("ListPlaceStep3");
const step1FormSelector = formValueSelector("ListPlaceStep1");

const mapState = (state) => ({
  isStartDate: selector(state, "startDate"),
  isEndDate: selector(state, "endDate"),
  locale: state.intl.locale,
  country: step1FormSelector(state, "country"),
  halfDates: selector(state, "halfDates"),
});

const mapDispatch = {
  change,
};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(DayDragCalendar))
);
