import React from "react";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./SubnavBar.css";
import history from "../../core/history";
import { FormattedMessage, injectIntl } from "react-intl";

// Locale
import messages from "../../locale/messages";

// Component
import Link from "../Link";
import { Breadcrumb, Grid } from "react-bootstrap";

const breadcumbItems = [
  {
    rootTitle: "Account",
    rootPath: "/dashboard",
    activeTitle: "",
    activePath: [],
  },
  {
    rootTitle: "Account",
    rootPath: "/dashboard",
    activeTitle: "My Balance",
    activePath: ["/my-balance", "/balance-history", "/deposit", "/withdraw"],
  },
  {
    rootTitle: "Account",
    rootPath: "/dashboard",
    activeTitle: "Time-Boxing",
    activePath: ["/new-time-boxing", "/time-boxing-history", "/time-boxing-pool-data"],
  },
  {
    rootTitle: "Account",
    rootPath: "/dashboard",
    activeTitle: "Transactions & Payouts",
    activePath: [
      "/user/payout",
      "/user/addpayout",
      "/user/transaction",
      "/user/transaction/host",
      "/user/transaction/book",
      "/users/security",
    ],
  },
  {
    rootTitle: "Account",
    rootPath: "/dashboard",
    activeTitle: "",
    activePath: [
      "/user/dashboard",
    ],
  },
  {
    rootTitle: "Account",
    rootPath: "/dashboard",
    activeTitle: "Personal info",
    activePath: [
      "/user/view-profile","/user/edit"
    ],
  },
  {
    rootTitle: "Account",
    rootPath: "/dashboard",
    activeTitle: "Reviews",
    activePath: [
      "/user/reviews/you","/user/reviews/about-you"
    ],
  },
  {
    rootTitle: "Create Listing",
    rootPath: "/become-a-host?mode=new",
    activeTitle: "Import Airbnb listing",
    activePath: [
      "/import-listing"
    ],
  },
  {
    rootTitle: "Create Listing",
    rootPath: "/listing",
    activeTitle: "Import Airbnb listing",
    activePath: [
      "/import-listing"
    ],
  },
];

class MenuComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
    };
  }

  componentDidMount() {
    if (history.location) {
      this.setState({
        location: history.location.pathname,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (history.location) {
      this.setState({
        location: history.location.pathname,
      });
    }
  }

  render() {
    const { location } = this.state;
    const { profileId } = this.props;

    const breadItem =
      breadcumbItems.find((el) => el.activePath.includes(location)) ||
      breadcumbItems[0];
    const currentPath = history?.location?.pathname;
    console.log(currentPath);
    return (
      <Grid fluid className={cx("dashBoardContainer", 'mb-0', s.breadCrumbContainer)}>
        <div className={cx(s.progressContainer, "hidden-xs", "hidden-print")}>
          <Breadcrumb className={cx(s.menuBread, "breadcumb-menu")}>
            <Breadcrumb.Item className="menuNormalItem" href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href={breadItem.rootPath}>
              {breadItem.rootTitle}
            </Breadcrumb.Item>
            <Breadcrumb.Item className={s.activeItemText} active>{breadItem.activeTitle}</Breadcrumb.Item>
          </Breadcrumb>
          {/* <ul className={cx(s.navList)}>
          <li className={cx({ [s.active]: location === '/dashboard' })}>
            <Link to={'/dashboard'} className={cx(s.navItem, 'navRtl', s.marginLeft)}>
              <FormattedMessage {...messages.dashboard} />
            </Link>
          </li>

          <li className={cx({ [s.active]: location === '/inbox' })}>
            <Link to={'/inbox'} className={cx(s.navItem, 'navRtl')}>
              <FormattedMessage {...messages.inbox} />
            </Link>
          </li>
          <li className={cx({
            [s.active]: location === '/rooms' || location === '/reservation/current'
              || location === '/reservation/previous'
          })}>
            <Link to={'/rooms'} className={cx(s.navItem, 'navRtl')}>
              <FormattedMessage {...messages.hosting} />
            </Link>
          </li>

          <li className={cx({ [s.active]: location === '/trips/current' || location === '/trips/previous' })}>
            <Link to={'/trips/current'} className={cx(s.navItem, 'navRtl')} >
              <FormattedMessage {...messages.traveling} />
            </Link>
          </li>
          <li className={cx({
            [s.active]: location === '/user/edit' || location === '/user/photo' || location.startsWith('/users/show/')
              || location === '/user/verification' || (location === '/user/reviews/about-you' || location === '/user/reviews/you') || location === '/users/show/:profileId?'
          })}>
            <Link to={'/user/edit'} className={cx(s.navItem, 'navRtl')}>
              <FormattedMessage {...messages.profile} />
            </Link>
          </li>
          <li className={cx({
            [s.active]: location === '/user/payout' || location.startsWith('/user/transaction')
              || location === '/users/security' || location === '/user/addpayout'
          })}>
            <Link to={'/user/payout'} className={cx(s.navItem, 'navRtl')}>
              <FormattedMessage {...messages.account} />
            </Link>
          </li>
          <li className={cx({
            [s.active]: location === '/new-staking'
          })}>
            <Link to={'/new-staking'} className={cx(s.navItem, 'navRtl')}>
              <FormattedMessage {...messages.staking} />
            </Link>
          </li>
          <li className={cx({
            [s.active]: location === '/my-balance'
          })}>
            <Link to={'/my-balance'} className={cx(s.navItem, 'navRtl')}>
              <FormattedMessage {...messages.balance} />
            </Link>
          </li>
        </ul> */}
        </div>
      </Grid>
    );
  }
}

export default injectIntl(withStyles(s)(MenuComponent));
