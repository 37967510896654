// General
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux Form
import { Field, reduxForm, formValueSelector } from "redux-form";

// Translation
import { injectIntl, FormattedMessage } from "react-intl";

// Locale
import messages from "../../locale/messages";

// Helpers
import validateStep3 from "./validateStep3";

// Redux
import { connect } from "react-redux";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import {
  Row,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
} from "react-bootstrap";
import s from "./ListPlaceStep1.css";
import bt from "../../components/commonStyle.css";

// Component
import FooterButton from "./FooterButton";
import SidePanel from "./SidePanel";
import updateStep3 from "./updateStep3";

//Image
import toolTipIcon from "../../../public/SiteIcons/listCommonToolTip.svg";
import { Form } from "react-bootstrap/lib/Navbar";

const CancellationShortStay = [
  {
    messages: messages.flexible,
    value: 1,
  },
  {
    messages: messages.moderate,
    value: 2,
  },
  {
    messages: messages.firm,
    value: 3,
  },
  // {
  //   messages: messages.strict,
  //   value: 4,
  // },
];

const CancellationLongStay = [
  {
    messages: messages.firm,
    value: 3,
  },
  // {
  //   messages: messages.strict,
  //   value: 4,
  // },
];
class AdvanceNotice extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      isShortStay: true,
      bookingNoticeTime: [],
      policyContent: "",
    };
    this.handleCancellation = this.handleCancellation.bind(this);
    this.handleCancellationStay = this.handleCancellationStay.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { listingFields } = this.props;
    const { cancellationPolicies, cancelPolicy } = this.props;
    if (cancellationPolicies && cancellationPolicies.length > 0) {
      let itemListData = cancelPolicy
        ? cancellationPolicies.filter((policy) => {
            if (policy.id == cancelPolicy) return policy;
          })
        : cancellationPolicies[0];
      this.setState({
        policyContent:
          itemListData && itemListData[0]
            ? itemListData[0].policyContent
            : itemListData.policyContent,
      });
    }
    if (listingFields != undefined) {
      this.setState({
        bookingNoticeTime: listingFields.bookingNoticeTime,
      });
    }
  }

  componentDidMount() {
    const { valid } = this.props;
    const { cancellationPolicies, cancelPolicy } = this.props;
    if (cancellationPolicies && cancellationPolicies.length > 0) {
      let itemListData = cancelPolicy
        ? cancellationPolicies.filter((policy) => {
            if (policy.id == cancelPolicy) return policy;
          })
        : cancellationPolicies[0];
      this.setState({
        policyContent:
          itemListData && itemListData[0]
            ? itemListData[0].policyContent
            : itemListData.policyContent,
      });
    }
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { valid, listingFields } = nextProps;
    const { cancellationPolicies, cancelPolicy } = nextProps;
    if (cancellationPolicies && cancellationPolicies.length > 0) {
      let itemListData = cancelPolicy
        ? cancellationPolicies.filter((policy) => {
            if (policy.id == cancelPolicy) return policy;
          })
        : cancellationPolicies[0];
      this.setState({
        policyContent:
          itemListData && itemListData[0]
            ? itemListData[0].policyContent
            : itemListData.policyContent,
      });
    }
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }

    if (listingFields != undefined) {
      this.setState({
        bookingNoticeTime: listingFields.bookingNoticeTime,
      });
    }
  }

  renderFormControlSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </div>
    );
  };

  renderTimeSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
    startValue,
    endValue,
  }) => {
    const { formatMessage } = this.props.intl;
    let start = startValue ? startValue : 8;
    let end = endValue ? endValue : 25;
    let timeArray = [];
    for (let i = start; i <= end; i++) {
      timeArray.push(i);
    }

    return (
      <div>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
        <FormControl componentClass="select" {...input} className={className}>
          <option className="bg-[#242526]" value="Flexible">{label}</option>
          {timeArray.map((item, key) => {
            return (
              <option value={item} key={key}>
                {item > 12 ? (item > 24 ? item - 24 : item - 12) : item}
                {item >= 12 && item < 24 ? "PM" : "AM"}
                {item == 12 ? " (noon)" : ""}
                {item == 24 ? " (mid night)" : ""}
                {item > 24 ? " (next day)" : ""}
              </option>
            );
          })}
        </FormControl>
      </div>
    );
  };

  handleCancellation() {
    const { cancellationPolicies, cancelPolicy } = this.props;
    let itemListData =
      cancellationPolicies && cancellationPolicies.length > 0
        ? cancellationPolicies.filter((policy) => policy.id == cancelPolicy)
        : [];
    this.setState({
      policyContent:
        itemListData && itemListData[0] ? itemListData[0].policyContent : "",
    });
  }

  handleCancellationStay() {
    this.setState({ isShortStay: !this.state.isShortStay });
  }

  render() {
    const { handleSubmit, previousPage, nextPage, formPage, step } = this.props;
    const { bookingNoticeTime, policyContent, isShortStay } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <div className={""}>
        {/* <SidePanel
          title={formatMessage(messages.stepThreeCommonHeading)}
          landingContent={formatMessage(messages.whatamenitiesPanel)}
        /> */}
        <form onSubmit={handleSubmit}>
          <div className={s.landingMainContent}>
            <div className="text-[32px] font-medium text-center mb-[45px]">
              Advance notice and cancellation policy
            </div>
            <ControlLabel className={cx(s.landingLabel, "textWhite mb-[24px]")}>
              <FormattedMessage {...messages.advanceNoticeCheckInTitle} />
            </ControlLabel>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <FormGroup className={s.formGroup}>
                  <ControlLabel
                    className={cx(s.landingLabel, "textWhite", s.FromTextCss)}
                  >
                    <FormattedMessage {...messages.advanceNoticeFrom} />
                  </ControlLabel>
                  <div>
                    <Field
                      name="checkInStart"
                      component={this.renderTimeSelect}
                      className={cx(
                        // s.formControlSelectNew,
                        // s.jumboSelect,
                        s.listCommonJumboSelect,
                        s.advanceNoticeSelect
                        // "listCommonJumboSelectRTL"
                      )}
                      startValue={8}
                      endValue={25}
                      label={formatMessage(messages.flexible)}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <FormGroup className={s.formGroup}>
                  <ControlLabel
                    className={cx(s.landingLabel, "textWhite", s.FromTextCss)}
                  >
                    <FormattedMessage {...messages.advanceNoticeTo} />
                  </ControlLabel>
                  <Field
                    name="checkInEnd"
                    component={this.renderTimeSelect}
                    className={cx(
                      // s.formControlSelectNew,
                      // s.jumboSelect,
                      s.listCommonJumboSelect,
                      s.advanceNoticeSelect
                      // "listCommonJumboSelectRTL"
                    )}
                    startValue={9}
                    endValue={26}
                    label={formatMessage(messages.flexible)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup className={cx(s.formGroup, s.spaceTop4)}>
              <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                <FormattedMessage {...messages.chooseCancellationPolicy} />
              </ControlLabel>
              <Field
                name="cancellationStayType"
                component={this.renderFormControlSelect}
                className={cx(
                  s.listCommonJumboSelect,
                  s.advanceNoticeSelect
                )}
                value={isShortStay}
                onChange={() => this.handleCancellationStay()}
              >
                <option value={"short"}>
                  {formatMessage(messages.guestCancellationShortStay)}
                </option>
                <option value={"long"}>
                  {formatMessage(messages.guestCancellationLongStay)}
                </option>
              </Field>
              <br></br>

              <Field
                name="cancellationPolicy"
                component={this.renderFormControlSelect}
                className={cx(
                  s.listCommonJumboSelect,
                  s.advanceNoticeSelect
                )}
                onChange={() => this.handleCancellation()}
              >
                {isShortStay
                  ? CancellationShortStay.map((item, index) => (
                      <option key={index} value={`${item.value}`}>
                        {formatMessage(item.messages)}
                      </option>
                    ))
                  : CancellationLongStay.map((item, index) => (
                      <option key={index} value={`${item.value}`}>
                        {formatMessage(item.messages)}
                      </option>
                    ))}
              </Field>
            </FormGroup>
            <Row className={s.tipCommonCss}>
              <div className={cx(s.tipCommonCssInline, "mt-[24px]")}>
                <img src={toolTipIcon} />
                <span className={cx(s.commonTipCsss, s.noteText)}>
                  {policyContent}
                </span>
              </div>
            </Row>
          </div>
          <FooterButton
            nextPage={nextPage}
            previousPage={previousPage}
            nextPagePath={"pricing"}
            previousPagePath={"house-rules"}
            formPage={formPage}
            step={step}
          />
        </form>
      </div>
    );
  }
}

AdvanceNotice = reduxForm({
  form: "ListPlaceStep3", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3,
  onSubmit: updateStep3,
})(AdvanceNotice);

const selector = formValueSelector("ListPlaceStep3"); // <-- same as form name

const mapState = (state) => ({
  cancelPolicy: selector(state, "cancellationPolicy"),
  listingFields: state.listingFields.data,
  existingList: state.location.isExistingList,
  cancellationPolicies: state.location.cancellationPolicies,
});

const mapDispatch = {};

export default injectIntl(
  withStyles(s, bt)(connect(mapState, mapDispatch)(AdvanceNotice))
);
