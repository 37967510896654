export function getAedR3vRate() {
    // Todo: Update realtime here
    // return 0.4;
    return 1.0;
}

export function getR3vAedRate() {
    // Todo: Update realtime here
    // return 0.4;
    return 1.0;
}

export function getR3vUsdtRate() {
    // Todo: Update realtime here
    // return 0.25;
    return 1.0;
}