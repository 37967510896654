import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Col, FormControl, FormGroup, Grid, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import history from "../../core/history";
import messages from "../../locale/messages";
import s from './BalanceMenu.css'
import { compose, gql, graphql } from 'react-apollo';
import bt from '../../components/commonStyle.css';
import cx from 'classnames';
import { connect } from "react-redux";
import { convertR3VtoUSD } from "../../helpers/currencyConvertion";

class BalanceMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: ''
    }
  }
  handleSelect(key) {
    history.push(key)
  }
  componentDidMount() {
    if (history.location) {
      this.setState({
        location: history.location.pathname
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (history.location) {
      this.setState({
        location: history.location.pathname
      });
    }
  }
  render() {
    const { location } = this.state
    const {balance} = this.props
    return (
      <div>
        <div className="text-3xl font-medium whitespace-nowrap mb-[30px]">
          My Balance
        </div>
        <div className="max-w-[934px]">
          <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
            <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
              <div className="bg-[#A5C58A] grow w-full pl-8 pr-1 py-1 rounded-lg max-md:max-w-full max-md:mt-6 max-md:pl-5">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                  <div className="flex flex-col items-stretch w-[69%] max-md:w-full max-md:ml-0">
                    <div className="flex flex-col items-stretch my-auto max-md:mt-10">
                      <div className="!text-[#18191A] text-sm font-semibold whitespace-nowrap">
                        My USD Balance:
                      </div>
                      <div className="text-[#18191A] text-lg font-bold whitespace-nowrap mt-4">
                        {balance?.balance || 0} USD
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-stretch w-[31%] ml-5 max-md:w-full max-md:ml-0">
                    <div className="h-[114px]"></div>
                    {/* <div className={cx(s.usdBlock)}>
                      <img
                        loading="lazy"
                        src="/SiteIcons/dollarWallet.png"
                        className="aspect-[1.05] object-contain object-center w-[114px] backdrop-blur-none overflow-hidden shrink-0 max-w-full grow max-md:mt-10"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
              <div className="bg-[#1F5ABD] grow w-full pl-8 pr-1 py-1 rounded-lg max-md:max-w-full max-md:mt-6 max-md:pl-5">
                <div className="gap-5 h-full  flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                  <div className="flex flex-col items-stretch w-[69%] max-md:w-full max-md:ml-0">
                    <div className="flex flex-col items-stretch my-auto max-md:mt-10">
                      <div className="text-[#E5E6EB] text-sm font-semibold whitespace-nowrap">
                        My R3V Balance:
                      </div>
                      <div className="text-[#E5E6EB] text-lg font-bold whitespace-nowrap mt-4">
                        {balance?.air_balance || 0} R3V (~{convertR3VtoUSD(balance?.air_balance || 0)} USD)
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col h-full items-stretch w-[31%] ml-5 max-md:w-full max-md:ml-0">
                    <div className={cx(s.revBlock)}>
                      <img
                        loading="lazy"
                        src="/SiteIcons/R3VWallet.png"
                        className="aspect-[1.05] object-contain object-center w-[80px] h-[45px] backdrop-blur-none overflow-hidden shrink-0 max-w-full grow max-md:mt-10"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-3xl mt-[60px] mb-[32px] font-medium whitespace-nowrap">
          Balance History
        </div>
        <Panel className={cx('bgBlack border-none')}>

          <div className={cx('listingTab', 'listingMobileTab', 'tabReservation')}>
            <Tabs className="custom-tab" activeKey={this.state.location} id="controlled-tab-example" onSelect={(e) => this.handleSelect(e)}>
              <Tab eventKey={'/my-balance'} title={'USD Balance History'} />
              <Tab eventKey={'/balance-history'} title={'R3V Balance History'} />
              <Tab eventKey={'/deposit'} title={'Deposit'} />
              <Tab eventKey={'/withdraw'} title={'Withdraw'} />
            </Tabs>
          </div>
        </Panel>
      </div>)
  }

}
const mapState = (state) => ({
  balance: state?.balance?.data
});

const mapDispatch = {

}

export default compose(injectIntl, withStyles(s, bt), connect(mapState, mapDispatch))(BalanceMenu);