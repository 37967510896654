// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// Redux Form
import { Field, reduxForm, reset, formValueSelector, change } from 'redux-form';

// Locale
import messages from '../../locale/messages';
import s from './WithdrawPayment.css';
import bt from '../../components/commonStyle.css';

// Redux
import { connect } from 'react-redux';

import { graphql, gql, compose } from 'react-apollo';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
    Button,
    FormGroup,
    Col,
    FormControl,
    Modal
} from 'react-bootstrap';
//Image 
import closeIcon from '../../../public/SiteIcons/commonModalCloseBtn.svg';
import { STAKING_TYPE } from '../../constants';
import { getBalanceUser } from '../../actions/getBalanceUser';
import { toastr } from 'react-redux-toastr';
import * as FontAwesome from 'react-icons/lib/fa';

//Images

class WithdrawPayment extends Component {

    static propTypes = {
        formatMessage: PropTypes.any,
        listId: PropTypes.number,
        getListingData: PropTypes.shape({
            loading: PropTypes.bool,
            UserListing: PropTypes.object
        }),
    };

    constructor(props) {
        super(props);
        this.state = {
            shown: false,
            disabled: true,
        };
        this.toggle = this.toggle.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    toggle() {
        this.setState({
            shown: !this.state.shown
        });
    }

    onChange(e) {
        if (e.target.value && e.target.value.trim() != '') {
            this.setState({ disabled: false });
        } else {
            this.setState({ disabled: true });
        }
    }

    renderFormControl = ({ input, label, type, meta: { touched, error }, className, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={cx(s.formGroup, 'row')}>
                <Col xs={12} sm={12} md={12} lg={12} className={'rtlWishListInput'}>
                    {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    <FormControl {...input} placeholder={placeholder} type={type} className={className} />
                </Col>
            </FormGroup>
        );
    }

    async submitForm(values, dispatch) {
        const { mutate, profileId, wishListGroups, change, withdrawPayment, payout } = this.props;
        if (values.amount.trim() != '') {
            let updatedWishLists = wishListGroups;
            const res = await withdrawPayment({
                variables: {
                    amountUSD: Number(values.amount),
                },

            });
            if (res?.data?.withdrawPayment?.status === "success") {
                toastr.success("Withdraw success!")
                this.setState({
                    shown: !this.state.shown
                });
            } else {
                toastr.error(res?.data?.withdrawPayment?.status || "Withdraw failed!")
            }
            this.props.getBalanceUser()
            this.props.refetch()
        } else {
            toastr.error("Withdraw failed!")
        }
    }

    render() {
        const { error, handleSubmit, submitting, dispatch, listId } = this.props;
        const { formatMessage } = this.props.intl;
        const { disabled, shown } = this.state;

        return (
            <div>
                <div className={cx(bt.btnPrimary, 'flex items-center rounded-lg  text-base font-medium')} onClick={this.toggle}>
                    Withdraw USD
                </div>
                <Modal show={shown} animation={false} onHide={this.toggle} dialogClassName={cx(s.logInModalContainer, 'loginModal', 'wishListCloseBtn', 'wishListCloseNew', s.wishlistModal)} >
                    <div className={s.sectionBlock}>
                        <Modal.Header >
                            <div className='flex justify-between items-center'>
                                <div className={cx(s.wishListTitle, 'font-semibold text-[18px]')}>
                                    Withdraw USD
                                </div>
                                <div onClick={this.toggle} className={cx(s.commonCloseModalBtn, 'svgImg')}><img src={closeIcon} /></div>
                            </div>
                        </Modal.Header>
                        <Modal.Body bsClass={s.logInModalBody}>
                            <div className={s.root}>
                                <div className={cx(s.container, 'rtlWishListInput', 'p-[30px]')}>
                                    <div className={cx('inputFocusColor', s.paddingBottom)}>
                                        <form onSubmit={handleSubmit(this.submitForm)} autoComplete="off">

                                            {
                                                <div className={cx(s.textAignRight, 'textAlignLeftRtl')}>
                                                    <Field
                                                        name="amount"
                                                        type="number"
                                                        component={this.renderFormControl}
                                                        placeholder={'Amount'}
                                                        className={cx(bt.commonControlInput, s.space1)}
                                                        onChange={this.onChange}
                                                    />
                                                    <FormGroup className={cx(s.spaceTop3, s.formGroup, 'text-center mb-0 mt-5')}>
                                                        <Button className={cx(bt.btnSecondaryFull)}
                                                            type="submit"
                                                            disabled={disabled}
                                                        >Withdraw USD
                                                        </Button>
                                                    </FormGroup>
                                                </div>
                                            }
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                </Modal>
            </div>
        )
    }
}

WithdrawPayment = reduxForm({
    form: 'WithdrawPayment', // a unique name for this form
})(WithdrawPayment);

const selector = formValueSelector('withdrawUsdModalModalForm');

const mapState = (state) => ({
    account: state?.account?.data,
    balance: state?.balance,
    payout: state?.payout?.data
});

const mapDispatch = {
    change,
    getBalanceUser
};

export default compose(injectIntl,
    withStyles(s, bt),
    connect(mapState, mapDispatch),
    graphql(gql`
    mutation withdrawPayment(
        $amountUSD:Float!,
    ){
        withdrawPayment(
            amountUSD: $amountUSD,
        ) {
            status,
        }
    }
    `, {
        name: 'withdrawPayment'
    })
)(WithdrawPayment);