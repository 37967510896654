import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";

import { FormGroup, FormControl, Button } from "react-bootstrap";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Transaction.css";
import bt from "../../components/commonStyle.css";

// Component
import BookingCompletedTransaction from "./BookingCompleted/BookingCompletedTransaction";
import HostingCompletedTransaction from "./HostingCompleted/HostingCompletedTransaction";
import CompletedTransactions from "./Completed/CompletedTransactions";
import FutureTransactions from "./Future/FutureTransactions";
import GrossEarnings from "./GrossEarnings/GrossEarnings";
import TotalAmount from "./TotalAmount";
import Loader from "../Loader";
import FilterListings from "./ModalFilter/Listings";
import FilterBookings from "./ModalFilter/Bookings";
import CustomPagination from "../CustomPagination";

import { onChangeListing } from "../../actions/Listing/onChangeListing";
import { payoutChangeListing } from "../../actions/Payout/payoutChangeListing";
import { openTransactionModal } from "../../actions/modalActions";

// Locale
import messages from "../../locale/messages";
import { debounce } from "../../helpers/debounce";
import history from "../../core/history";

//images
import exportIcon from "../../../public/SiteIcons/exportItemIcon.svg";
import filterIcon from "../../../public/SiteIcons/reservationFilter.svg";
import {setMode} from "browser-sync/dist/options";

class Transaction extends React.Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.any.isRequired,
      getTransactionHistory: PropTypes.shape({
        count: PropTypes.number.isRequired,
        reservationData: PropTypes.arrayOf(
          PropTypes.shape({
            hostId: PropTypes.string.isRequired,
            checkIn: PropTypes.string.isRequired,
            checkOut: PropTypes.string.isRequired,
            confirmationCode: PropTypes.number.isRequired,
            listData: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            guestData: PropTypes.shape({
              firstName: PropTypes.string.isRequired,
            }),
            hostTransaction: PropTypes.shape({
              payoutId: PropTypes.number,
              payEmail: PropTypes.string,
              amount: PropTypes.number,
              currency: PropTypes.string,
              createdAt: PropTypes.string,
            }),
          })
        ),
      }),
    }).isRequired,
    dataBooking: PropTypes.shape({
      getBookingTransactionHistory: PropTypes.shape({
        count: PropTypes.number.isRequired,
        reservationData: PropTypes.arrayOf(
          PropTypes.shape({
            hostId: PropTypes.string.isRequired,
            checkIn: PropTypes.string.isRequired,
            checkOut: PropTypes.string.isRequired,
            confirmationCode: PropTypes.number.isRequired,
            listData: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            guestData: PropTypes.shape({
              firstName: PropTypes.string.isRequired,
            }),
            hostTransaction: PropTypes.shape({
              payoutId: PropTypes.number,
              payEmail: PropTypes.string,
              amount: PropTypes.number,
              currency: PropTypes.string,
              createdAt: PropTypes.string,
            }),
          })
        ),
      }),
    }).isRequired,
    dataHosting: PropTypes.shape({
      getHostingTransactionHistory: PropTypes.shape({
        count: PropTypes.number.isRequired,
        reservationData: PropTypes.arrayOf(
          PropTypes.shape({
            hostId: PropTypes.string.isRequired,
            checkIn: PropTypes.string.isRequired,
            checkOut: PropTypes.string.isRequired,
            confirmationCode: PropTypes.number.isRequired,
            listData: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            guestData: PropTypes.shape({
              firstName: PropTypes.string.isRequired,
            }),
            hostTransaction: PropTypes.shape({
              payoutId: PropTypes.number,
              payEmail: PropTypes.string,
              amount: PropTypes.number,
              currency: PropTypes.string,
              createdAt: PropTypes.string,
            }),
          })
        ),
      }),
    }).isRequired,
  };

  static defaultProps = {
    data: {
      loading: true,
      getTransactionHistory: {
        count: null,
        reservationData: [],
      },
    },
    dataBooking: {
      loading: true,
      getBookingTransactionHistory: {
        count: null,
        reservationData: [],
      },
    },
    dataHosting: {
      loading: true,
      getHostingTransactionHistory: {
        count: null,
        reservationData: [],
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      mode: props.mode,
      currentPage: 1,
      payoutId: null,
      listId: null,
      load: true,
      searchKey: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.paginationData = this.paginationData.bind(this);
    this.handleChangeFirst = this.handleChangeFirst.bind(this);
    this.handleSearchChange = debounce(this.handleSearchChange.bind(this));
  }

  async componentDidUpdate(prevProps) {
    const {locale} = this.props.intl;
    const {locale: prevLocale} = prevProps.intl;

    if (locale !== prevLocale) {
      await this.setState({load: false});
      clearTimeout(this.loadSync);
      this.loadSync = null;
      this.loadSync = setTimeout(() => this.setState({load: true}), 1);
    }
  }

  async handleSearchChange(searchKey) {
    const {
      data: {refetch},
      dataBooking,
      dataHosting,
    } = this.props;
    const { mode } = this.state;
    let variables = {
      searchKey,
      currentPage: 1,
    };
    await this.setState({searchKey, currentPage: 1});
    if (mode === "host") {
      dataHosting?.refetch(variables);
    } else if (mode === "book") {
      dataBooking?.refetch(variables);
    }
  }

  async handleChange({payoutId, listId}) {
    const {
      data: {refetch},
      dataBooking,
      dataHosting,
    } = this.props;
    const {searchKey, mode} = this.state;
    let variables = {
      mode,
      currentPage: 1,
      payoutId,
      listId,
      searchKey,
    };
    console.log({handleSubmitVariables: variables});
    await this.setState({currentPage: 1, payoutId, listId});
    if (mode === "host") {
      dataHosting?.refetch(variables);
    } else if (mode === "book") {
      dataBooking?.refetch(variables);
    }
  }

  async handleChangeFirst(mode) {
    const {onChangeListing, payoutChangeListing} = this.props;
    const {
      dataBooking,
      dataHosting,
      data: {refetch},
    } = this.props;
    let variables = {
      currentPage: 1,
      payoutId: null,
      listId: null,
    };
    history.push("/user/transaction/" + mode);
    await this.setState({currentPage: 1, payoutId: null, listId: null, mode: mode});
    onChangeListing({listId: null, payoutId: null});
    payoutChangeListing(null);
  }

  async paginationData(currentPage) {
    console.log({debugTransactionPropsPagination: this.props});
    const {
      dataBooking,
      dataHosting,
      data: {refetch},
    } = this.props;
    const {mode} = this.state;
    if (mode === "book") {
      let variables = {currentPage};
      await this.setState({currentPage});
      dataBooking?.refetch(variables);
    } else if (mode === "host") {
      let variables = {currentPage};
      await this.setState({currentPage});
      dataHosting?.refetch(variables);
    }
  }

  // paginationDataHost(currentPage) {
  //   console.log({debugTransactionPropsPagination: this.props});
  //   const { dataHosting: { getHostingTransactionHistory } } = this.props;
  //   let variables = { currentPage };
  //   this.setState({ currentPage });
  //   getHostingTransactionHistory(variables);
  // }
  //
  // paginationDataBook(currentPage) {
  //   const { dataBooking: { getBookingTransactionHistory }} = this.props;
  //   let variables = { currentPage };
  //   this.setState({ currentPage });
  //   getBookingTransactionHistory(variables);
  // }

  render() {
    console.log({ debugTransactionProps: this.props });
    const {
      data: { loading, getTransactionHistory },
      mode,
      dataBooking: { getBookingTransactionHistory, loading: loadingB },
      dataHosting: { getHostingTransactionHistory, loading: loadingH },
    } = this.props;
    const { currentPage, payoutId, listId, searchKey, load } = this.state;
    const { formatMessage } = this.props.intl;
    const { toCurrency, openTransactionModal, siteName } = this.props;
    let page1Active, page2Active, page3Active, page4Active, page5Active;
    let showPayouts, showTotal, userId, guestId;
    page1Active = mode === "completed" ? s.active : "";
    page2Active = mode === "future" ? s.active : "";
    page3Active = mode === "grossEarnings" ? s.active : "";
    page4Active = mode === "book" ? s.active : "";
    page5Active = mode === "host" ? s.active : "";
    if (mode === "completed" || mode === "grossEarnings") {
      showPayouts = true;
    }
    if (mode === "completed" || mode === "future") {
      showTotal = true;
    }

    if (
      !loading &&
      getTransactionHistory &&
      getTransactionHistory.reservationData &&
      getBookingTransactionHistory &&
      getBookingTransactionHistory.reservationData &&
      getHostingTransactionHistory &&
      getHostingTransactionHistory.reservationData
    ) {
      if (getTransactionHistory.reservationData.length > 0) {
        userId = getTransactionHistory.reservationData[0].hostId;
      }
      if (getHostingTransactionHistory.reservationData.length > 0) {
        userId = getHostingTransactionHistory.reservationData[0].hostId;
      }
      if (getBookingTransactionHistory.reservationData.length > 0) {
        guestId = getBookingTransactionHistory.reservationData[0].guestId;
      }
    }

    console.log({
      debugTransactionAccount: {
        userId: userId,
        guestId: guestId,
        mode: mode,
        dataBooking: getBookingTransactionHistory,
        dataHosting: getHostingTransactionHistory,
      },
    });

    return (
      <div>
        <div className="mb-4 text-3xl font-semibold">Transaction History</div>
        <div className="">
          <ul className="flex border-b border-border-secondary px-2 mb-10">
            {/*<li className={cx(page1Active, s.mobileWidth)}>*/}
            {/*  <a className={cx(s.tabItem, 'textWhite')} onClick={() => this.handleChangeFirst('completed')}>*/}
            {/*    <FormattedMessage {...messages.completedTransactions} />*/}
            {/*  </a>*/}
            {/*</li>*/}
            {/*<li className={cx(page2Active, s.mobileWidth)}>*/}
            {/*  <a className={cx(s.tabItem, 'textWhite')} onClick={() => this.handleChangeFirst('future')}>*/}
            {/*    <FormattedMessage {...messages.futureTransactions} />*/}
            {/*  </a>*/}
            {/*</li>*/}
            {/*<li className={cx(page3Active, s.mobileWidth)}>*/}
            {/*  <a className={cx(s.tabItem, 'textWhite')} onClick={() => this.handleChangeFirst('grossEarnings')}>*/}
            {/*    <FormattedMessage {...messages.grossEarnings} />*/}
            {/*  </a>*/}
            {/*</li>*/}
            <li
              className={cx(
                "whitespace-nowrap border-b-[3px] py-4 px-1 text-sm font-medium flex justify-center cursor-pointer",
                {
                  "border-slate-900 text-gray-500": mode === "host",
                  "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700":
                    mode !== "host",
                }
              )}
            >
              <div
                className="text-base font-medium text-center px-3"
                onClick={() => this.handleChangeFirst("host")}
              >
                <FormattedMessage {...messages.hostingCompleted} />
              </div>
            </li>
            <li
              className={cx(
                "whitespace-nowrap border-b-[3px] py-4 px-1 text-sm font-medium flex justify-center cursor-pointer",
                {
                  "border-slate-900 text-gray-500": mode === "book",
                  "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700":
                    mode !== "book",
                }
              )}
            >
              <div
                className="text-base font-medium text-center px-3"
                onClick={() => this.handleChangeFirst("book")}
              >
                <FormattedMessage {...messages.bookingCompleted} />
              </div>
            </li>
          </ul>
          {/* {!loading &&
            load &&
            showTotal &&
            getTransactionHistory &&
            getTransactionHistory.totalCount > 0 &&
            getTransactionHistory.reservationData !== null && (
              <TotalAmount
                mode={mode}
                data={getTransactionHistory.reservationData}
              />
            )} */}
          {getTransactionHistory && getTransactionHistory.totalCount > 0 && (
            <div className="grid grid-cols-3 gap-4 mb-4">
              <div className="col-span-1">
                <FormGroup className={s.noMargin}>
                  <FormControl
                    type="text"
                    placeholder={formatMessage(messages.SearchTransactions)}
                    className={cx(
                      s.formControlInput,
                      s.jumboInput,
                      s.locationBgIcon,
                      "locationBgIconRTL",
                      "!rounded-md !h-12"
                    )}
                    onChange={(e) =>
                      this.handleSearchChange(e.target && e.target.value)
                    }
                    value={searchKey}
                  />
                </FormGroup>
              </div>

              {
                  mode === "host" &&  <FilterListings
                      handleResults={this.handleChange}
                      showPayouts={showPayouts}
                      mode={mode}
                  />
              }
              {
                mode === "book" && <FilterBookings
                      handleResults={this.handleChange}
                      showPayouts={showPayouts}
                      mode={mode}
                  />
              }

              <div className="col-span-2"></div>
              <div className="col-span-1 flex justify-end">
                {getTransactionHistory.count > 0 && (
                  <a
                    href={`/export-transaction?siteName=${siteName}&type=${mode}&toCurrency=${toCurrency}&listId=${
                      (listId > 0 && listId) || ""
                    }&payoutId=${(payoutId > 0 && payoutId) || ""}&searchKey=${
                      searchKey || ""
                    }`}
                    className="text-blue-500"
                  >
                    Download CSV
                  </a>
                )}
              </div>
            </div>
          )}

          {(loadingB || loadingH) && <Loader type={"text"} />}
          {!loading &&
            mode === "completed" &&
            getTransactionHistory &&
            getTransactionHistory.reservationData !== null && (
              <CompletedTransactions
                data={getTransactionHistory.reservationData}
                totalCount={getTransactionHistory.totalCount}
              />
            )}

          {!loading &&
            mode === "future" &&
            getTransactionHistory &&
            getTransactionHistory.reservationData !== null && (
              <FutureTransactions
                data={getTransactionHistory.reservationData}
                totalCount={getTransactionHistory.totalCount}
              />
            )}

          {!loading &&
            mode === "grossEarnings" &&
            getTransactionHistory &&
            getTransactionHistory.reservationData !== null && (
              <GrossEarnings
                data={getTransactionHistory.reservationData}
                totalCount={getTransactionHistory.totalCount}
              />
            )}

          {getHostingTransactionHistory &&
            !getHostingTransactionHistory.loading &&
            mode === "host" &&
            getHostingTransactionHistory.reservationData !== null && (
              <HostingCompletedTransaction
                data={getHostingTransactionHistory.reservationData}
                totalCount={getHostingTransactionHistory.totalCount}
              />
            )}
          {getBookingTransactionHistory &&
            !getBookingTransactionHistory.loading &&
            mode === "book" &&
            getBookingTransactionHistory.reservationData !== null && (
              <BookingCompletedTransaction
                data={getBookingTransactionHistory.reservationData}
                totalCount={getBookingTransactionHistory.totalCount}
              />
            )}
        </div>
        {mode === "host" &&
          getHostingTransactionHistory &&
          getHostingTransactionHistory.count !== null &&
          getHostingTransactionHistory.count > 0 && (
            <div>
              <CustomPagination
                total={getHostingTransactionHistory.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={5}
                change={this.paginationData}
                componentReference={mode}
                paginationLabel={formatMessage(messages.transactions)}
              />
            </div>
          )}
        {mode === "book" &&
          getBookingTransactionHistory &&
          getBookingTransactionHistory.count !== null &&
          getBookingTransactionHistory.count > 0 && (
            <div>
              <CustomPagination
                total={getBookingTransactionHistory.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={5}
                change={this.paginationData}
                componentReference={mode}
                paginationLabel={formatMessage(messages.transactions)}
              />
            </div>
          )}
        {mode !== "host" &&
          mode !== "book" &&
          getTransactionHistory &&
          getTransactionHistory.count !== null &&
          getTransactionHistory.count > 0 && (
            <div>
              <CustomPagination
                total={getTransactionHistory.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={5}
                change={this.paginationData}
                componentReference={mode}
                paginationLabel={formatMessage(messages.transactions)}
              />
            </div>
          )}
      </div>
    );
  }
}

const mapState = (state) => ({
  toCurrency: state.currency.to || state.currency.base,
  siteName: state.siteSettings && state.siteSettings.data.siteName,
});

const mapDispatch = {
  onChangeListing,
  openTransactionModal,
  payoutChangeListing,
};

export default compose(
  injectIntl,
  connect(mapState, mapDispatch),
  withStyles(s, bt)
)(Transaction);
