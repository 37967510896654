import React from 'react';
import PropTypes from 'prop-types';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ThingsToKnow.css';
import {
  Row,
  Col
} from 'react-bootstrap';
import cx from 'classnames';
import * as FontAwesome from 'react-icons/lib/fa';

// Redux
import { connect } from 'react-redux';

//Redux Action
import { openSocialShareModal } from '../../../actions/modalActions';

// Translation
import { injectIntl, FormattedMessage } from 'react-intl';

// Locale
import messages from '../../../locale/messages';
import WishListIcon from '../../WishListIcon/WishListIcon';

// Component
import StarRating from '../../StarRating/StarRating';

//Image
import shareIcon from '../../../../public/SiteIcons/viewListingShareIcon.svg';
import { checkValue } from '../ListingDetails/helper';
class ThingsToKnow extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    formatMessage: PropTypes.any,
    reviewsCount: PropTypes.number.isRequired,
    reviewsStarRating: PropTypes.number.isRequired,
  };

  render() {

    const { data, loading } = this.props;
    const { formatMessage } = this.props.intl;
    const { reviewsCount, reviewsStarRating, openSocialShareModal } = this.props;
    let starRatingValue = 0;
    if (reviewsCount > 0 && reviewsStarRating > 0) {
      starRatingValue = Math.round(reviewsStarRating / reviewsCount)
    }
    let sharedSpaces = [], houseRules = [], listBedTypes = [];
    let isListOwner = data.isListOwner;
    let wishListStatus = data.wishListStatus;
    houseRules = checkValue(data.houseRules, []);

    return (
      <div className={cx(s.paddingCss, s.hostFlex, 'paddingCssRTL')}>
        <div>
          <h1 className={cx(s.titleText, 'textWhite')}>
            Things to know
          </h1>
        </div>
        <div>
          <div>House rules</div>
          {
            houseRules?.map(item => {
              return (<div className='mb-2'>
                {item.listsettings.itemName}
              </div>)
            })
          }
          
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
});

const mapDispatch = {
  openSocialShareModal
};


export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ThingsToKnow)));
