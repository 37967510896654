import React from "react";
import PropTypes from "prop-types";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Layout.css";
import Header from "../Header";
import FooterToggle from "../FooterToggle";
import CookiesDisclaimer from "../CookiesDisclaimer";
import cx from "classnames";
import Footer from "../Footer";
import history from "../../core/history";

class NewListLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    console.log(history?.location, 'history?.location?.pathnamehistory?.location?.pathname');
    return (
      <div className={cx(s.overFlowHidden, 'dashboardLayout')}>
        <Header borderLess listing={!(history?.location?.pathname == '/become-a-host/index' || history?.location?.pathname == '/become-a-host' || history?.location?.pathname?.includes('/home'))} />
        <div className={s.paddingTop}>
          {this.props.children}
          <Footer listing={!(history?.location?.pathname == '/become-a-host/index' || history?.location?.pathname == '/become-a-host' || history?.location?.pathname?.includes('/home'))}/>
        </div>
        <CookiesDisclaimer />
      </div>
    );
  }
}

export default withStyles(s)(NewListLayout);
