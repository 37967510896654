import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./ViewUserProfile.css";
import moment from "moment";
import history from "../../core/history";
import { fromNow } from "../../utils/datetime";

class ReviewsAboutYou extends React.Component {
  static propTypes = {
    reviewsData: PropTypes.shape({
      loading: PropTypes.bool,
      formatMessage: PropTypes.any,
      userReviews: PropTypes.array,
      refetch: PropTypes.any,
    }),
    pendingData: PropTypes.shape({
      loading: PropTypes.bool,
      pendingReviews: PropTypes.array,
    }),
    loadMore: PropTypes.any.isRequired,
  };

  static defaultProp = {
    reviewsData: {
      loading: true,
    },
    pendingData: {
      loading: true,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      current: "others",
      searchKey: "",
    };
  }

  render() {
    const {
      reviewsData,
      reviewsData: { userReviews },
      loadMore,
      type,
    } = this.props;
    console.log("userReviews", userReviews)
    return (
      <div className="flex flex-col gap-6 mt-5">
        {userReviews?.results?.map((item, index) => {
          return (
            <div className="flex gap-3">
              <img
                src={`/images/avatar/${item?.authorData && item?.authorData?.picture}`}
                className="w-[60px] h-[60px] rounded-[50%] object-cover"
                alt="..."
              />
              <div className="flex flex-col gap-3">
                <div
                  className={cx(
                    s.fontWeightMedium,
                    s.fontColorPrimary,
                    s.fontEighteenSize,
                    s.commonFontProperty
                  )}
                >
                  {item?.authorData && item?.authorData?.firstName}{" "}
                  {item?.authorData && item.authorData?.lastName}
                </div>
                <div
                  className={cx(
                    s.fontWeightNormal,
                    s.fontColorPrimary,
                    s.fontSixteenSize,
                    s.commonFontProperty
                  )}
                >
                  {item?.reviewContent}
                </div>
                <div
                  className={cx(
                    s.fontWeightNormal,
                    s.fontColorSecondary,
                    s.fontFourteenSize,
                    s.commonFontProperty
                  )}
                >
                  {fromNow(item?.createdAt)}
                </div>
              </div>
            </div>
          );
        })}

        <div className="flex justify-center">
          {userReviews?.results.length ? (
            <button
              onClick={() => history.push("/user/reviews/about-you")}
              className="bg-black text-white px-5 py-2 rounded-md"
            >
              More reviews
            </button>
          ) : (
            <h4 className="mt-10">No Reviews Available</h4>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(ReviewsAboutYou));
