// General
import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import moment from "moment";
// Redux Form
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import { graphql, gql, compose } from "react-apollo";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import VerifiedInfo from "../VerifiedInfo/VerifiedInfo";
import submit from "./submit";
import validate from "./validate";
import Avatar from "../Avatar/Avatar";
import { doRemoveProfilePicture } from "../../actions/manageUserProfilePicture";
// Locale
import messages from "../../locale/messages";
import PopulateData from "../../helpers/populateData";

// Redux
import { connect } from "react-redux";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./ViewUserProfile.css";
import bt from "../../components/commonStyle.css";
//Images
import infoImage from "../../../public/SiteIcons/editInfoTipIcon.svg";
import homeIcon from "../../../public/SiteIcons/home.svg";
import verifiedAccount from "../../../public/SiteIcons/verifiedAccount.svg";
import pencilIcon from "../../../public/SiteIcons/pencil.svg";
import chatIcon from "../../../public/SiteIcons/translate1lighter.svg";
import profileIcon from "../../../public/SiteIcons/profile.svg";
import profileBanner from "../../../public/SiteImages/profileBanner.png";
import mobileProfileBanner from "../../../public/SiteImages/mobileProfileBanner.svg";
import verifyBanner from "../../../public/SiteIcons/verify.svg";
import Instagram from "../../../public/SiteIcons/Instagram.svg";
import starWhite from "../../../public/SiteIcons/star-white.svg";
import Facebook from "../../../public/SiteIcons/Facebook.svg";
import DropZone from "./DropZone";
import { use } from "chai";
import history from "../../core/history";
import UserReviewsQuery from "./UserReviews.graphql";
import PendingReviewsQuery from "./PendingReviews.graphql";
import ReviewsByYou from "./ReviewByYou.js";
import ReviewsAboutYou from "./ReviewAboutYou.js";

class ViewProfileMobile extends Component {
  static propTypes = {
    data: PropTypes.shape({
      userId: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired,
      location: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      picture: PropTypes.string.isRequired,
      profileId: PropTypes.number.isRequired,
      reviewsCount: PropTypes.number.isRequired,
    }),
    account: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    }).isRequired,
    profilePhotoLoading: PropTypes.bool,
    formatMessage: PropTypes.any,
    doRemoveProfilePicture: PropTypes.any.isRequired,
    profilePictureData: PropTypes.shape({
      loading: PropTypes.bool,
      userAccount: PropTypes.shape({
        picture: PropTypes.string.isRequired,
      }),
    }),
    userReviewsData: PropTypes.shape({
      loading: PropTypes.bool,
      userReviews: PropTypes.array,
    }),
    pendingReviewsData: PropTypes.shape({
      loading: PropTypes.bool,
      pendingReviews: PropTypes.array,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      location: "",
      maxUploadSize: 50,
      selectedTab: "reviewAboutYou",
      load: true,
      current: "me",
      type: "me",
    };
    this.loadMore = this.loadMore.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (history.location) {
      this.setState({
        location: history.location.pathname,
      });
    }
    this.handleMaxUploadFile();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (history.location) {
      this.setState({
        location: history.location.pathname,
      });
    }
  }

  handleMaxUploadFile = async () => {
    const siteData = await getSpecificConfig({ name: ["maxUploadSize"] });
    this.setState({
      maxUploadSize: siteData.maxUploadSize,
    });
  };

  handleClick(type, current) {
    const {
      userReviewsData: { refetch },
    } = this.props;
    let variables = { type, current };
    this.setState({ current });
    refetch(variables);
  }

  componentDidUpdate(prevProps) {
    const { locale } = this.props.intl;
    const { locale: prevLocale } = prevProps.intl;

    if (locale !== prevLocale) {
      this.setState({ load: false });
      clearTimeout(this.loadSync);
      this.loadSync = null;
      this.loadSync = setTimeout(() => this.setState({ load: true }), 1);
    }
  }

  loadMore(ownerType, current, searchKey) {
    const {
      userReviewsData: { userReviews, fetchMore },
    } = this.props;
    fetchMore({
      query: UserReviewsQuery,
      variables: {
        ownerType,
        offset: userReviews.results.length,
        loadCount: 3,
        current,
        searchKey,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return {
            userReviews: {
              totalCount: previousResult.userReviews.totalCount,
              results: previousResult.userReviews.results,
            },
          };
        }
        return {
          userReviews: {
            totalCount: fetchMoreResult.userReviews.totalCount,
            results: [
              ...previousResult.userReviews.results,
              ...fetchMoreResult.userReviews.results,
            ],
          },
        };
      },
    });
  }

  static defaultProps = {
    account: {
      userId: null,
    },
    profilePictureData: {
      loading: true,
    },
    profilePhotoLoader: false,
  };

  render() {
    const {
      account: { userId, picture },
      userData,
      data,
      userReviewsData,
      theme,
      pendingReviewsData,
    } = this.props;
    const {
      profilePictureData: { loading, userAccount },
      doRemoveProfilePicture,
      isProfilePage,
      isUser,
      hideMoreVerification,
    } = this.props;
    const { profilePhotoLoader } = this.props;
    const { formatMessage } = this.props.intl;
    const { location, load } = this.state;
    let isVerified,
      showOwnProfile = true,
      isReviewEnable;
    if (userData) isVerified = userData.profileId;
    if (isProfilePage && !isUser) showOwnProfile = false;
    isReviewEnable = location === "/dashboard" ? true : false;

    let date = moment(userData?.createdAt).format("MMMM YYYY");
    
    return (
       <div className="relative pt-20 px-5 inline  sm:!hidden max-w-[430px]">
       <div className="flex flex-column items-center max-w-[430px]">
        <img
          className="h-[100%] w-[100%] object-cover rounded-[4px] justify-self-center"
            src={mobileProfileBanner}
            alt="..."
            />
        </div>

        <div className="flex gap-7 sm:pl-10">
            <div className={cx(s.profileContainer)}>
              <div className="rounded-[50%] w-[60px] h-[60px] sm:w-[120px] sm:h-[120px]">
                <Avatar
                  height={120}
                  width={120}
                  className={s.imgResponsive}
                  source={data && data.picture}
                  isUser={showOwnProfile}
                />
              </div>

              {/* <div className="flex gap-3 mt-5">
              <img className="w-[24px] h-[24px]" src={pencilIcon} alt="..." />
              <div
                className={cx(
                  s.fontWeightNormal,
                  s.fontColorSecondary,
                  s.fontSixteenSize,
                  s.commonFontProperty,
                  "self-center"
                )}
              >
                Update avatar
              </div>
            </div> */}

              {showOwnProfile && (
                <div className="">
                  <DropZone
                    data={data}
                    maxUploadSize={this.state.maxUploadSize}
                    isEditIcon
                    className={cx(s.editAvatarBtnContainer)}
                  />
                  {/* {picture ? (
                    <DropZone
                      data={data}
                      maxUploadSize={this.state.maxUploadSize}
                      isEditIcon
                      className={cx("profileDropZone")}
                    />
                  ) : (
                    <DropZone
                      data={data}
                      maxUploadSize={this.state.maxUploadSize}
                      defaultMessage={formatMessage(messages.editProfilePhoto)}
                    />
                  )} */}
                </div>
              )}
              <div
                className={cx(
                  s.fontWeightMedium,
                  s.fontColorPrimary,
                  s.fontThirtyTwoSize,
                  s.commonFontProperty,
                  s.nameText,
                  "text-center "
                )}
              >
                {userData?.firstName} {userData?.lastName}
              </div>
              {userData?.verification?.isIdVerification === 1 ? (
                <div className="flex gap-3 mt-3">
                  <img
                    className="w-[24px] h-[24px]"
                    src={verifyBanner}
                    alt="..."
                  />
                  <div
                    className={cx(
                      s.fontWeightNormal,
                      s.fontColorSecondary,
                      s.fontSixteenSize,
                      s.commonFontProperty,
                      "self-center"
                    )}
                  >
                    Verified
                  </div>
                </div>
              ) : null}

              {/* <div className={cx(s.rating, "mt-8")}>* 5</div> */}

             <div className={cx('flex gap-3 mt-5')}>
                <div className={cx(s.ratingNumberStar, "")}>
                    <img src={starWhite} className="w-[18px] h-[18px]" alt="..." />
                    {userReviewsData?.userReviews?.stars || 5}
                </div>
                <div className={cx(s.ratingNumber, "")}>
                {userReviewsData?.userReviews?.totalCount || 0} ratings
              </div>
             </div>
            
             

              <div className="flex gap-3 mt-[28px] sm:mt-[64px]">
                <img src={Instagram} className="w-[24px] h-[24px]" alt="..." />
                <img
                  src={Facebook}
                  className="w-[24px] h-[24px] ml-[24px]"
                  alt="..."
                />
              </div>
              <div className={cx(s.horizentalBar, "mt-5")} />
              <div className={cx(s.member, "mt-5")}>Member since {date}</div>
            </div>
       </div>

        <div className="flex flex-col relative w-full mt-[24px]">
              <div className={s.heading}>
                Hi, I’m {userData?.firstName} {userData?.lastName}
              </div>

              {userData?.info ? (
                <div className={cx(s.profileDesc, "mt-5")}>
                  {userData?.info}
                </div>
              ) : null}

              <div className="flex flex-col gap-[1.5rem] mt-7">
                {userData?.location ? (
                  <div className="flex gap-3">
                    <img
                      className="w-[24px] h-[24px]"
                      src={homeIcon}
                      alt="..."
                    />
                    <div
                      className={cx(
                        s.fontSixteenSize,
                        s.fontWeightNormal,
                        s.fontColorSecondary,
                        s.commonFontProperty,
                        "self-center min-w-[100px]"
                      )}
                    >
                      Lives in
                    </div>
                    <div
                      className={cx(
                        s.fontSixteenSize,
                        s.fontWeightNormal,
                        s.fontColorPrimary,
                        s.commonFontProperty,
                        "self-center ml-[2rem]"
                      )}
                    >
                      {userData?.location}
                    </div>
                  </div>
                ) : null}
                <div className="flex gap-3">
                  <img
                    className="w-[24px] h-[24px]"
                    src={verifiedAccount}
                    alt="..."
                  />
                  <div
                    className={cx(
                      s.fontSixteenSize,
                      s.fontWeightNormal,
                      s.fontColorSecondary,
                      s.commonFontProperty,
                      "self-center min-w-[100px]"
                    )}
                  >
                    Account
                  </div>
                  <div
                    className={cx(
                      s.fontSixteenSize,
                      s.fontWeightNormal,
                      s.fontColorPrimary,
                      s.commonFontProperty,
                      "self-center ml-[2rem]"
                    )}
                  >
                    {userData?.verification?.isIdVerification === 1 ? "Verified" : "Not Verified"} Account
                  </div>
                </div>

                {/* <div className="flex gap-3">
                  <img
                    className="w-[24px] h-[24px]"
                    src={pencilIcon}
                    alt="..."
                  />
                  <div
                    className={cx(
                      s.fontSixteenSize,
                      s.fontWeightNormal,
                      s.fontColorSecondary,
                      s.commonFontProperty,
                      "min-w-[100px]"
                    )}
                  >
                    Verified Account
                  </div>
                  <VerifiedInfo
                    userId={data ? data.userId : userId}
                    showOwnProfile={showOwnProfile}
                    hideMoreVerification={hideMoreVerification}
                  />
                </div> */}

                {userData?.preferredLanguage ? (
                  <div className="flex gap-3">
                    <img
                      className="w-[24px] h-[24px]"
                      src={chatIcon}
                      alt="..."
                    />
                    <div
                      className={cx(
                        s.fontSixteenSize,
                        s.fontWeightNormal,
                        s.fontColorSecondary,
                        s.commonFontProperty,
                        "self-center   min-w-[100px]"
                      )}
                    >
                      Language
                    </div>
                    <div
                      className={cx(
                        s.fontSixteenSize,
                        s.fontWeightNormal,
                        s.fontColorPrimary,
                        s.commonFontProperty,
                        "self-center ml-[2rem]"
                      )}
                    >
                      {userData?.preferredLanguage}
                    </div>
                  </div>
                ) : null}
              </div>
              </div>

              <div className={cx(s.reviewSection, "mt-10")}>
                <div className="flex justify-center">
                  <div
                    className={cx(
                      s.fontColorPrimary,
                      s.commonFontProperty,
                      "font-medium text-[24px]"
                    )}
                  ></div>
                  <div className={cx("flex gap-2 w-[100%] justify-center", s.ratingDiv)}>
                    <div
                      className={
                        this.state.selectedTab === "reviewAboutYou"
                          ? s.activeButtonContainer
                          : s.inActiveButtonContainer
                      }
                    >
                      <div
                        onClick={() => {
                          this.setState({ selectedTab: "reviewAboutYou" });
                          this.handleClick("others", "responded");
                        }}
                        className={
                          this.state.selectedTab === "reviewAboutYou"
                            ? s.activeButton
                            : s.inActiveButton
                        }
                      >
                        Review about you
                      </div>
                    </div>
                    <div
                      className={
                        this.state.selectedTab === "reviewByYou"
                          ? s.activeButtonContainer
                          : s.inActiveButtonContainer
                      }
                    >
                      <div
                        onClick={() => {
                          this.setState({ selectedTab: "reviewByYou" });
                          this.handleClick("me", "me");
                        }}
                        className={cx(
                          this.state.selectedTab === "reviewByYou"
                            ? s.activeButton
                            : s.inActiveButton,
                          "self-center"
                        )}
                      >
                        Reviews by you
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.selectedTab === "reviewAboutYou" ? (
                  <ReviewsAboutYou reviewsData={userReviewsData} />
                ) : (
                  <ReviewsByYou reviewsData={userReviewsData} />
                )}
              </div>
       </div>
    );
  }
}

ViewProfileMobile = reduxForm({
  form: "EditProfileForm", // a unique name for this form
  validate,
})(ViewProfileMobile);
const selector = formValueSelector("EditProfileForm");

const mapState = (state) => ({
  userData: state.account.data,
  account: state.account.data,
  profilePhotoLoading: state.account.profilePhotoLoading,
  profilePhotoLoader: state.loader.profilePhotoLoader,
  listSettingsData: state.adminListSettingsData.data,
  isAuthenticate: state.runtime.isAuthenticated,
  theme: state.currency.theme,
});

const mapDispatch = {
  doRemoveProfilePicture,
};

export default compose(
  injectIntl,
  withStyles(s, bt),
  graphql(UserReviewsQuery, {
    name: "userReviewsData",
    options: (props) => ({
      variables: {
        current: "responded",
        type: "others",
      },
      fetchPolicy: "network-only",
    }),
  }),
  graphql(PendingReviewsQuery, {
    name: "pendingReviewsData",
    options: (props) => ({
      fetchPolicy: "network-only",
    }),
  }),
  connect(mapState, mapDispatch)
)(ViewProfileMobile);
