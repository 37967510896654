import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './NewWallet.css';
import { Col, FormControl, FormGroup, Grid, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import cx from 'classnames'
import { FormattedMessage } from 'react-intl';
import messages from '../../locale/messages';
import history from '../../core/history';
import { Table, Tr, Td, Thead, Th } from 'reactable';
import { compose, gql, graphql } from 'react-apollo';
import moment from 'moment';
import { Wallet_TYPE } from '../../constants';
import BalanceMenu from '../../components/Balance/BalanceMenu';

class WalletHistory extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };


  render() {
    const { getWalletHistory } = this.props
    return (
      <Grid fluid className='dashBoardContainer'>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={cx('')}>
              <BalanceMenu />
              <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
                <Table className="table table-custom"
                  noDataText={'No Record'}
                >
                  <Thead>
                    <Th scope="col">Date</Th>
                    <Th scope="col">Token Balance (R3V)</Th>
                    <Th scope="col">Timeboxed Balance (R3V)</Th>
                  </Thead>
                  {
                    getWalletHistory?.getWalletHistory
                    && getWalletHistory?.getWalletHistory?.length > 0
                    && getWalletHistory?.getWalletHistory?.map((value, key) => {
                      return (
                        <Tr key={key}>
                          <Td column='Date' >
                            <div className='flex justify-center'>
                              <div className='text-left w-fit'>
                                <div className='text-base'>{moment(value.created_at).format('MMMM Do YYYY')}</div>
                                <div className='text-base text-[var(--common-text-Secondry-color)]'>{moment(value.created_at).format('h:mm:ss')}</div>
                              </div>
                            </div>
                          </Td>
                          <Td data-label='Token Balance (R3V)' column='Token Balance (R3V)'  >{value.token_balance}</Td>
                          <Td data-label='Staked Balance (R3V)' column='Staked Balance (R3V)'  >{value.staked_token_balance}</Td>
                        </Tr>
                      )
                    })
                  }
                </Table>
              </div>
            </div>
          </Col>
        </Row>

      </Grid>
    );
  }
}


export default compose(withStyles(s), graphql(gql`
query {
  getWalletHistory{
    user_id
    wallet_id
    token_balance
    staked_token_balance
    created_at
  }
}

`, {
  name: 'getWalletHistory',
  options: {
    ssr: false
  }
}))(WalletHistory);

