import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './NewStaking.css';
import { Col, FormControl, FormGroup, Grid, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import cx from 'classnames'
import { FormattedMessage } from 'react-intl';
import messages from '../../locale/messages';
import history from '../../core/history';
import StakingMenu from '../../components/Staking/StakingMenu';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { compose, gql, graphql } from 'react-apollo';
import StakingRequestModal from '../../components/Staking/StakingRequestModal';
import { connect } from 'react-redux';
import { Table, Tr, Td, Thead, Th } from 'reactable';
import bt from '../../components/commonStyle.css';
import { STAKING_TYPE } from '../../constants';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { getBalanceUser } from '../../actions/getBalanceUser';
import { convertR3VtoUSD } from '../../helpers/currencyConvertion';
import { GrayButton } from '../../components/Button/GrayButton';

function getStatusTitle(status) {
  switch (status) {
    case STAKING_TYPE.STATUS_STAKED:
      return 'Time-Boxed';
    case STAKING_TYPE.STATUS_UN_STAKE_PENDING:
      return 'Timebox ended';
    case STAKING_TYPE.STATUS_UN_STAKE_CANCELLED:
      return 'End Timebox cancelled';
    case STAKING_TYPE.STATUS_UN_STAKE_COMPLETED:
      return 'End Timebox completed';
    default:
      return 'Not processing';
  }
}

const COLORS = ['#1F5ABD', '#98BFFF', '#F79A16', '#15CAEA'];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  x, y, name, percent
}) => {
  if (!percent > 0) return ''
  return (
    <text x={x} y={y} fill="white" textAnchor="end" dominantBaseline="central">
      {percent?.toFixed(2)}%
    </text>
  );
};

class NewStaking extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  async requestUnstake(stakeId) {
    const res = await this.props.unstakeRequest({
      variables: {
        stakeId,
        action: Number(STAKING_TYPE.ACTION_UNSTAKE)
      },

    });
    if (res?.data?.unstakeRequest?.status === "true") {
      toastr.success("Unstake success!")
      this.props?.getStakingInfo?.refetch()
    } else {
      toastr.error("Unstake failed!", res?.data?.unstakeRequest?.message)
      this.props?.getStakingInfo?.refetch()
    }
    console.log(res);
  }

  async requestCancelUnstake(stakeId) {
    const res = await this.props.unstakeRequest({
      variables: {
        stakeId,
        action: Number(STAKING_TYPE.ACTION_CANCEL_UNSAKE)
      },

    });
    if (res?.data?.unstakeRequest?.status === "true") {
      toastr.success("Cancel unstake success!")
      this.props?.getStakingInfo?.refetch()
    } else {
      toastr.error("Cancel unstake failed!", res?.data?.unstakeRequest?.message)
      this.props?.getStakingInfo?.refetch()
    }
    console.log(res);
  }


  render() {
    const { getStakingInfo, balance, updateStakeRequest } = this.props
    const { stakingInfo, stakingPool,
      stakingRequests } = getStakingInfo?.getStakingInfo || {}

    const rawDataChart = [
      { name: 'R3V Staked', value: stakingInfo?.total_staked || 0 },
      { name: 'R3V Pending UnStake', value: stakingInfo?.total_pending_un_staked || 0 },
      { name: 'R3V Balance', value: balance?.air_balance || 0 },
      { name: 'R3V Pending Rewards', value: stakingInfo?.total_reward_unclaimed || 0 },
    ]
    const totalR3v = (stakingInfo?.total_staked || 0) + (stakingInfo?.total_pending_un_staked || 0) + (balance?.air_balance || 0) + (stakingInfo?.total_reward_unclaimed || 0)
    const dataChart = rawDataChart.map(el => ({ ...el, percent: (el.value / totalR3v * 100) }))

    return (
      <Grid fluid className='dashBoardContainer'>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={cx()}>
              <StakingMenu />
              <div className='mt-[32px]'>
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                  <div className="flex flex-col items-stretch w-[56%] max-md:w-full max-md:ml-0">

                    <div className="flex grow flex-col items-stretch max-md:max-w-full max-md:mt-8">
                      <div className="bg-[#1F5ABD] flex items-center justify-between gap-5 pl-8 pr-1 py-1 rounded-xl max-md:max-w-full  max-md:pl-5">
                        <div className="flex flex-col items-stretch my-auto py-[10px] sm:py-[28px]">
                          <div className="text-neutral-300 text-xl font-medium">R3V</div>
                          <div className="text-zinc-400 text-base font-medium mt-14 max-md:mt-7">
                            Balance
                          </div>
                          <div className="text-neutral-100 text-[20px] sm:text-2xl font-medium sm:whitespace-nowrap mt-3">
                            {balance?.air_balance || 0} REV (~{convertR3VtoUSD(balance?.air_balance || 0)} AED)
                          </div>
                        </div>
                        <div className="backdrop-blur-none self-stretch flex flex-col items-center">
                          <div className={cx(s.revBlock)}>
                            <img
                              loading="lazy"
                              src="/SiteIcons/stake-r3v.svg"
                              className="aspect-[1.78] object-contain object-center w-[64px] overflow-hidden mt-36 max-md:mt-10"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-8 max-md:max-w-full">
                        <div className="gap-5 flex items-stretch gap-0">
                          <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                            <div className="border border-[#606570] bg-[#18191A] flex grow flex-col w-full pl-6  py-6 rounded-lg border-solid items-start max-md:mt-8 max-md:px-5">
                              <div className="text-[#E5E6EB] text-base font-semibold sm:whitespace-nowrap">
                              Timeboxed Balance
                              </div>
                              <div className="text-[#E5E6EB] text-xl font-semibold mt-14 max-md:mt-7">
                                {stakingInfo?.total_staked || 0}
                              </div>
                              <div className="text-[#E5E6EB] text-base font-semibold mt-2">
                                R3V
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                            <div className="border border-[#606570] bg-[#18191A] flex grow flex-col w-full pl-6  py-6 rounded-lg border-solid items-start max-md:mt-8 max-md:px-5">
                              <div className="text-[#E5E6EB] text-base font-semibold sm:whitespace-nowrap">
                               Non-Timeboxed Balance
                              </div>
                              <div className="text-[#E5E6EB] text-xl font-semibold mt-14 max-md:mt-7">
                                {stakingPool?.total_staked || 0}
                              </div>
                              <div className="text-[#E5E6EB] text-base font-semibold mt-2">
                                R3V
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-8 max-md:max-w-full">
                        <div className="gap-5 flex items-stretch">
                          <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                            <div className="border border-[#606570] bg-[#18191A] flex grow flex-col w-full pl-6  py-6 rounded-lg border-solid items-start max-md:mt-8 max-md:px-5">
                              <div className="text-[#E5E6EB] text-base font-semibold md:whitespace-nowrap">
                              Pending End Timeboxes
                              </div>
                              <div className="text-[#E5E6EB] text-xl font-semibold mt-14 max-md:mt-7">
                                {stakingInfo?.total_pending_un_staked || 0}
                              </div>
                              <div className="text-[#E5E6EB] text-base font-semibold mt-2">
                                R3V
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                            <div className="border border-[#606570] bg-[#18191A] flex grow flex-col w-full pl-6  py-6 rounded-lg border-solid items-start max-md:mt-8 max-md:px-5">
                              <div className="text-[#E5E6EB] text-base font-semibold md:whitespace-nowrap">
                                Pending Rewards
                              </div>
                              <div className="text-[#E5E6EB] text-xl font-semibold mt-14 max-md:mt-7">
                                {stakingPool?.total_pending_reward_pool || 0}
                              </div>
                              <div className="text-[#E5E6EB] text-base font-semibold mt-2">
                                R3V
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-stretch w-[44%] ml-5 max-md:w-full max-md:ml-0">
                    <div className="border border-[#606570] bg-[#18191A] flex grow flex-col items-center w-full rounded-lg border-solid max-md:max-w-full max-md:mt-8 max-md:px-5">
                      <div className="">
                        <ResponsiveContainer width="100%" height="100%" minWidth={"400px"} minHeight={'400px'}>
                          <PieChart>
                            <Pie
                              dataKey="value"
                              data={dataChart}
                              innerRadius={60}
                              outerRadius={100}
                              fill="#82ca9d"
                              label={renderCustomizedLabel}
                            >
                              {dataChart.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                            <Tooltip />
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                      <div className="self-stretch  md:px-12 py-8 pt-[18px] flex items-stretch justify-between gap-5 ">
                        <div className="flex flex-col items-stretch">
                          <div className="flex items-center gap-4">
                            <div className="bg-[#1F5ABD] flex w-4 shrink-0 h-4 flex-col rounded" />
                            <div className="text-[var(--common-text-color)] text-xl">Timeboxed Balance</div>
                          </div>
                          <div className="flex items-center gap-4 mt-8">
                            <div className="bg-[#98BFFF] flex w-4 shrink-0 h-4 flex-col rounded" />
                            <div className="text-[var(--common-text-color)] text-xl grow whitespace-nowrap self-start">
                              Non-Timeboxed Balance
                            </div>
                          </div>
                          <div className="flex  gap-4 mt-8 items-center">
                            <div className="bg-[#F79A16] flex w-4 shrink-0 h-4 flex-col rounded" />
                            <div className="text-[var(--common-text-color)] text-xl self-stretch grow whitespace-nowrap">
                              Pending End Timeboxes
                            </div>
                          </div>
                          <div className="flex gap-4 mt-8 items-center">
                            <div className="bg-[#15CAEA] flex w-4 shrink-0 h-4 flex-col rounded" />
                            <div className="text-[var(--common-text-color)] text-xl self-stretch grow whitespace-nowrap">
                              Pending Rewards
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-stretch self-start">
                          <div className="text-[var(--common-text-color)] text-right text-xl whitespace-nowrap">
                            {dataChart?.[0]?.percent.toFixed(2)}%
                          </div>
                          <div className="text-[var(--common-text-color)] text-right text-xl whitespace-nowrap mt-8">
                            {dataChart?.[1]?.percent.toFixed(2)}%
                          </div>
                          <div className="text-[var(--common-text-color)] text-right text-xl whitespace-nowrap mt-8">
                            {dataChart?.[2]?.percent.toFixed(2)}%
                          </div>
                          <div className="text-[var(--common-text-color)] text-right text-xl font-medium whitespace-nowrap mt-8">
                            {dataChart?.[3]?.percent.toFixed(2)}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 grid sm:grid-cols-3 grid-cols-1 gap-8" >
                <div className=" p-6 mt-4 rounded-lg">
                  <h3 className="text-[14px] font-weight-600">Total Balance
                    <span className=" block w-fit text-[#2A3153] mt-[8px] text-[24px]">{balance?.air_balance || 0} R3V </span>
                  </h3>
                </div>
                <div className=" bg-[#175CD3] p-6 mt-4 rounded-lg text-white">
                  <h3 className="text-[14px] font-weight-600">R3V Total Pending Rewards of Pool:
                    <span className=" block w-fit text-white mt-[8px] text-[24px]"> {stakingPool?.total_pending_reward_pool || 0} R3V </span>
                  </h3>
                </div>
                <div className=" bg-[#152B64] p-6 mt-4 rounded-lg text-white">
                  <h3 className="text-[14px] font-weight-600">R3V Total Staked of Pool:
                    <span className=" block w-fit text-white mt-[8px] text-[24px]">{stakingPool?.total_staked || 0}  R3V </span>
                  </h3>
                </div>

                <div className="md:col-span-2 p-6 mt-4 rounded-lg ">
                  <table className="table table-striped table-hover pt-4 ">
                    <thead className="bg-[#F9FAFB]">
                      <tr>
                        <th>Balance</th>
                        <th className="text-right">Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr className="!bg-white ">
                        <td className="py-4">R3V Balance:</td>
                        <td className="text-right py-5 font-weight-600 ">{balance?.air_balance || 0}  R3V</td>
                      </tr>
                      <tr className="!bg-white ">
                        <td className="py-4">R3V Staked:</td>
                        <td className="text-right py-5"> {stakingInfo?.total_staked || 0} R3V</td>
                      </tr>
                      <tr className="!bg-white ">
                        <td className="py-4">R3V Pending UnStake:</td>
                        <td className="text-right py-5"> {stakingInfo?.total_pending_un_staked || 0} R3V</td>
                      </tr>
                      <tr className="!bg-white ">
                        <td className="py-4">R3V Pending Rewards:</td>
                        <td className="text-right py-5">{stakingPool?.pending_reward || 0} R3V</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className=" p-6 mt-4 rounded-lg ">

                </div>
              </div> */}
              <div className='flex justify-between mb-3 mt-[60px]'>
                <div className='text-[18px] sm:text-3xl font-medium whitespace-nowrap mb-[20px]'>Time-Boxing Request</div>
                <StakingRequestModal updateStakeRequest={updateStakeRequest} refetch={getStakingInfo?.refetch} />
              </div>
              <div className={cx('table-responsive rounded-[8px]', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
                <Table className="table table-custom firsttabtimeboxing"
                  noDataText={'No Record'}
                >
                  <Thead>
                    <Th scope="col">Created at</Th>
                    <Th scope="col">Amount(R3V)</Th>
                    <Th scope="col">Status</Th>
                    <Th scope="col">Timebox will end at</Th>
                    <Th scope="col">Action</Th>

                  </Thead>
                  {
                    stakingRequests && stakingRequests?.length > 0 && stakingRequests.map((value, key) => {
                      return (
                        <Tr key={key}>
                          <Td className="!align-top !py-[16px] !pl-[20px]" column="Created at">
                            <div className="flex justify-start">
                              <div className="text-left w-fit">
                                <div className={s.rowText}>
                                  {moment(value.staked_time).format(
                                    "MMM Do YYYY"
                                  )}
                                </div>
                                <div className="text-base text-[#B1B3B8] text">
                                  {moment(value.staked_time).format("h:mm:ss")}
                                </div>
                              </div>
                            </div>
                          </Td>
                          <Td
                            className="!align-top !py-[16px]"
                            data-label="Amount(R3V)"
                            column="Amount(R3V)"
                          >
                            <div className={s.rowText}>{value.amount}</div>
                          </Td>
                          <Td
                            className="!align-top !py-[16px]"
                            data-label="Status"
                            column="Status"
                          >
                            <div className={cx(s.rowText)}>
                              {getStatusTitle(value.status)}
                            </div>
                          </Td>

                          <Td
                            className="!align-top !py-[16px] !pr-[20px]"
                            data-label="Timebox will end at"
                            column="Timebox will end at"
                          >
                            {value.un_staked_predict_time ? (
                              <div className="flex justify-center">
                                <div className="text-left w-fit">
                                  <div className={s.rowText}>
                                    {moment(
                                      value.un_staked_predict_time
                                    ).format("MMM Do YYYY")}
                                  </div>
                                  <div className="text-base text-[#B1B3B8]">
                                    {moment(
                                      value.un_staked_predict_time
                                    ).format("h:mm:ss")}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </Td>
                          <Td
                            data-label="Action"
                            column="Action"
                            className="w-[150px] !py-[16px] !pr-[20px]"
                          >
                            <div className="">
                              {value.status == STAKING_TYPE.STATUS_STAKED ? (
                                <GrayButton containerClassname="mr-[-12px]" onClick={() => this.requestUnstake(value.id)}>
                                  <div className='text-[16px] font-medium text-[#E5E6EB]'>End Timebox</div>
                                </GrayButton>
                              ) : (
                                ""
                              )}
                              {value.status ==
                              STAKING_TYPE.STATUS_UN_STAKE_PENDING ? (
                                <div
                                  onClick={() =>
                                    this.requestCancelUnstake(value.id)
                                  }
                                  className={cx("text-[16px] text-[#E5E6EB] font-medium text-right cursor-pointer")}
                                >
                                  Cancel
                                </div>
                              ) : (
                                ""
                              )}
                              {value.status ==
                              STAKING_TYPE.STATUS_UN_STAKE_COMPLETED ? (
                                <div className="text-[16px] font-medium text-cyan-500 text-right">
                                  Ended Timebox
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </Td>
                        </Tr>
                      );
                    })
                  }
                </Table>
              </div>
            </div>
          </Col>
        </Row>

      </Grid>
    );
  }
}


const mapState = (state) => ({
  account: state?.account?.data,
  balance: state?.balance?.data
});

const mapDispatch = {
  getBalanceUser
}

export default compose(withStyles(s, bt), graphql(gql`
query {
  getStakingInfo{
  stakingInfo {
    userId
    pool_id
    total_staked
    total_pending_un_staked
    total_reward_unclaimed
    total_reward_claimed
  }
  stakingPool {
    pending_reward
    total_staked
    total_pending_reward_pool
  }
  stakingRequests {
    id
    status
    amount
    staked_time
    un_staked_time_request
    un_staked_time
    cancel_un_staked_time_request
    cancel_un_staked_time
    un_staked_predict_time
  }}
}

`, {
  name: 'getStakingInfo',
  options: {
    ssr: false
  }
}), graphql(gql`
mutation updateStakeRequest(
    $action: Int,
    $amount:Float,
){
    updateStakeRequest(
        action: $action,
        amount: $amount,
    ) {
        status,
        message
    }
}
`, {
  name: 'updateStakeRequest'
}),
  graphql(gql`
mutation unstakeRequest(
    $action: Int,
    $stakeId:Int,
){
  unstakeRequest(
        action: $action,
        stakeId: $stakeId,
    ) {
        status,
        message
    }
}
`, {
    name: 'unstakeRequest'
  }), connect(mapState, mapDispatch))(NewStaking);
