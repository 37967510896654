import React from "react";
import { useState, useEffect } from "react";
import eclipImg from "../../../../public/SiteIcons/anim-eclip-home.png";
import s from "./Layout5.css";

const URL =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT43QTnCd8a0xXXQqhFmtcdsaCcH2CQCknQzFxUfUPzAg&s";

const LOOP_TIME = 2500;

function Animation() {
  const [count, setCount] = useState(1);

  //  add interval to count every 1 second
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => prev + 1);
    }, LOOP_TIME);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={s.animationContainer}>
      <div className={s.animationPosition}>
        <div className="size-[360px] rounded-full animHomePos bg-[#040507] flex items-center justify-center absolute bottom-[-188px]">
          <div
            className="size-[327px] rounded-full bg-[#040507] flex-col shrink-0 absolute z-10 text-white flex items-center justify-center"
            style={{
              backgroundImage:
                "radial-gradient(55% 55% at 50% 45.11%, #404951 0%, #1D1D1E 100%)",
            }}
          >
            <div className="text-2xl absolute top-2 left-1/2 -translate-x-1/2 font-bold">
              1
            </div>

            <div className="text-2xl absolute bottom-[24%] left-[87%] -translate-x-1/2 font-bold">
              2
            </div>

            <div className="text-2xl absolute bottom-[24%] left-[13%] -translate-x-1/2 font-bold">
              3
            </div>

            <div className="relative flex items-center justify-center">
              <div
                className={
                  "absolute space-y-2 text-center transition-all duration-300 ease-linear " +
                  (count % 3 === 1 ? "opacity-100" : "opacity-0")
                }
              >
                <div className="text-xl font-medium">Book</div>
                <div className="text-base whitespace-nowrap">
                  Book your vacation via StaynEarn
                </div>
              </div>

              <div
                className={
                  "absolute space-y-2 text-center transition-all duration-300 ease-linear " +
                  (count % 3 === 2 ? "opacity-100" : "opacity-0")
                }
              >
                <div className="text-xl font-medium">Earn</div>
                <div className="text-base whitespace-nowrap">
                  Earn R3V rewards
                  <br /> for successful bookings
                </div>
              </div>

              <div
                className={
                  "absolute space-y-2 text-center transition-all duration-300 ease-linear " +
                  (count % 3 === 0 ? "opacity-100" : "opacity-0")
                }
              >
                <div className="text-xl font-medium">Benefits</div>
                <div className="text-base whitespace-nowrap">
                  Use your rewards for free
                  <br />
                  stays and more
                </div>
              </div>
            </div>
          </div>

          <div
            className="z-50 relative w-[426px] h-40 rounded-full transform shrink-0 flex justify-end items-center"
            style={{
              // rotate: `${90 + count * 120}deg`,
              rotate: `${-90 + count * 120}deg`,
              transition: "300ms linear all",
              transformOrigin: "50% 50%",
            }}
          >
            <img src={eclipImg} className="w-[82px] rotate-90" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Animation;
