import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import DropzoneComponent from "react-dropzone-component";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "!isomorphic-style-loader!css-loader!./filepicker.css";

// Helpers
import {
  doUploadProfilePicture,
  doRemoveProfilePicture,
} from "../../actions/manageUserProfilePicture";
import { setLoaderComplete, setLoaderStart } from "../../actions/loader/loader";

// Images
import EditIcon from "../../../public/SiteIcons/profileEditIcon.svg";
import updateIcon from "../../../public/SiteIcons/writeIcon.svg";
import ss from "./ViewUserProfile.css";
class Dropzone extends Component {
  static propTypes = {
    doUploadProfilePicture: PropTypes.any.isRequired,
    doRemoveProfilePicture: PropTypes.any.isRequired,
    startProfilePhotoLoader: PropTypes.any.isRequired,
    formatMessage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.success = this.success.bind(this);
    // this.removeExistingFile = this.removeExistingFile.bind(this);
    this.addedfile = this.addedfile.bind(this);
    this.dropzone = null;
  }

  componentDidUpdate() {
    const isBrowser = typeof window !== "undefined";
    const isDocument = typeof document !== undefined;
    if (isBrowser && isDocument) {
      document.querySelector(".dz-hidden-input").style.visibility = "visible";
      document.querySelector(".dz-hidden-input").style.opacity = "0";
      document.querySelector(".dz-hidden-input").style.height = "100%";
      document.querySelector(".dz-hidden-input").style.width = "100%";
      document.querySelector(".dz-hidden-input").style.cursor = "pointer";
    }
  }

  async success(file, fromServer) {
    const { setLoaderStart, setLoaderComplete } = this.props;
    const { doUploadProfilePicture, data } = this.props;
    let fileName = fromServer.file.filename;
    let oldPicture = data != undefined ? data.picture : null;
    await setLoaderStart("profilePhotoLoader");
    await doUploadProfilePicture(fileName, oldPicture);
    await setLoaderComplete("profilePhotoLoader");
  }

  addedfile(file) {
    const { maxUploadSize, setLoaderStart, setLoaderComplete } = this.props;
    setLoaderStart("profilePhotoLoader");
    let filetypes = ["image/jpeg", "image/png", "image/jpg"];
    if (file.size > 1024 * 1024 * parseInt(maxUploadSize)) {
      this.dropzone.removeFile(file);
      toastr.error(
        "Maximum upload size Exceeded! ",
        "Try with smallest size image"
      );
      setLoaderComplete("profilePhotoLoader");
    }

    if (!filetypes.includes(file.type)) {
      toastr.error(
        "Error!",
        "You are trying to upload invalid image file. Please upload PNG, JPG & JPEG format image file."
      );
      this.dropzone.removeFile(file);
      setLoaderComplete("profilePhotoLoader");
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { defaultMessage, isEditIcon, maxUploadSize, className } = this.props;
    const djsConfig = {
      dictDefaultMessage: "",
      addRemoveLinks: false,
      uploadMultiple: false,
      maxFilesize: maxUploadSize,
      acceptedFiles: "image/jpeg,image/png",
      dictMaxFilesExceeded: "Remove the existing image and try upload again",
      previewsContainer: false,
      hiddenInputContainer: ".dzInputContainer",
      maxFiles: 1,
      init: function () {
        this.on("addedfile", function (file) {
          if (this.files.length > 1) {
            this.removeFile(this.files[0]);
          }
        });
      },
    };
    const componentConfig = {
      iconFiletypes: [".jpg", ".png"],
      postUrl: "/uploadProfilePhoto",
    };
    const eventHandlers = {
      init: (dz) => (this.dropzone = dz),
      success: this.success,
      addedfile: this.addedfile,
    };

    return (
      <>
        <div
          className={cx(
            "dzInputContainer",
            "-top-[20px] right-0 relative",
            "relative z-[2] mt-0",
            ss.editAvatarBtnContainer,
            className
          )}
        >
          <DropzoneComponent
            config={componentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
            className={ss.editAvatarBtnContainer}
          >
            <div className={cx("relative z-[-1]", ss.editAvatarBtn)}>
              {isEditIcon && <img src={EditIcon} className={"dropShadow"} />}
              {!isEditIcon && (
                <img src={updateIcon} className={cx("dropzoneImgSpace")} />
              )}
            </div>

            {defaultMessage && defaultMessage}
          </DropzoneComponent>
        </div>
      </>
    );
  }
}

const mapState = (state) => ({
  account: state.account.data,
});

const mapDispatch = {
  doUploadProfilePicture,
  doRemoveProfilePicture,
  setLoaderStart,
  setLoaderComplete,
};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(Dropzone))
);
