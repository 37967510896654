import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Footer.css";

import { Row, Col, Grid } from "react-bootstrap";
import cx from "classnames";
import * as FontAwesome from "react-icons/lib/fa";

// Component
import LanguageSwitcher from "../LanguageSwitcher";
import CurrencySwitcher from "../CurrencySwitcher";
import Link from "../Link";

import PlayStoreImage from "../../../public/SiteImages/playStore.png";
import AppStoreImage from "../../../public/SiteImages/appStore.png";
import facebookIcon from "../../../public/SiteImages/facebook.svg";
import twitterIcon from "../../../public/SiteImages/twitter.svg";
import instagramIcon from "../../../public/SiteImages/instagram.svg";
import footerBgImage from "../../../public/SiteImages/footerBg.svg";
import mainLogo from "../../../public/SiteImages/mainLogo.svg";
import darkLogo from "../../../public/SiteIcons/dark-logo.svg";

import houseBanner from "../../../public/SiteImages/houseBanner.svg";

// Locale
import messages from "../../locale/messages";

import getEnabledBlog from "./getEnabledBlog.graphql";
import history from "../../core/history";

class Footer extends React.Component {
  static propTypes = {
    siteName: PropTypes.string.isRequired,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
    appAvailableStatus: PropTypes.string,
    playStoreUrl: PropTypes.string,
    appStoreUrl: PropTypes.string,
    formatMessage: PropTypes.any,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      getEnabledBlog: PropTypes.array,
    }),
  };
  constructor(props) {
    super(props);
    this.state = {
      location: "",
    };
  }
  handleSelect(key) {
    history.push(key);
  }
  componentDidMount() {
    if (history.location) {
      this.setState({
        location: history.location.pathname,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (history.location) {
      this.setState({
        location: history.location.pathname,
      });
    }
  }

  render() {
    const {
      siteName,
      facebook,
      twitter,
      instagram,
      appAvailableStatus,
      playStoreUrl,
      theme,
      listing
    } = this.props;
    const {
      data: { getEnabledBlog },
    } = this.props;
    const { location } = this.state;
    console.log(theme, "theme");
    const urlParams = location.split("/");
    // if (
    //   [
    //     "/become-a-host/house-type",
    //     "/become-a-host/room",
    //     "/become-a-host/bedrooms",
    //     "/become-a-host/location",
    //   ].includes(history?.location?.pathname) ||
    //   (urlParams?.[1] == "become-a-host" &&
    //     [
    //       "map",
    //       "amenities",
    //       "home",
    //       "photos",
    //       "description",
    //       "house-rules",
    //       "advance-notice",
    //       "pricing",
    //       "discount",
    //       "min-max-nights",
    //       "calendar",
    //       "guest-requirements",
    //       "booking-scenarios",
    //       "local-laws",
    //       "congrat",
    //     ].includes(urlParams?.[3]))
    // )
    if(listing ) return <div className="h-[160px]"></div>;
    return (
      <footer className={cx(s.fixedFooter)}>
        <div className={cx(s.positionRelative,'sm:!flex !hidden')}>
          <div
            className={cx(
              s.footerBgBanner,
              s.root,
              "footerBannerImg",
              "footerBannerToggle"
            )}
          >
            <div className={s.container}>
              <div className={cx(s.footerSectionContainer, "hidden-print")}>
                <div className={cx(s.logoContainer, "flex gap-[2rem] items-start")}>
                  <img
                    src={theme === "dark" ? darkLogo : mainLogo}
                    alt="..."
                    className="mr-[160px] mt-[18px]"
                  />

                  <ul className={s.listContainer}>
                    <li className={s.footerLinks}>
                      <Link to={"/about"} className={s.linkFooter}>
                        <div className={cx(s.textLink)}>
                          <FormattedMessage {...messages.about} />
                        </div>
                      </Link>
                    </li>
                    <li className={s.footerLinks}>
                      <Link to={"/contact"} className={s.linkFooter}>
                        <div className={cx(s.textLink)}>
                          <FormattedMessage {...messages.contactForm} />
                        </div>
                      </Link>
                    </li>
                    {getEnabledBlog &&
                      getEnabledBlog.length > 0 &&
                      getEnabledBlog
                        .filter(
                          (item) =>
                            item.footerCategory != "discover" &&
                            item.footerCategory != "hosting"
                        )
                        .map((item) => {
                          return (
                            <li className={s.footerLinks}>
                              <Link
                                to={"/page/" + item.pageUrl}
                                className={cx(s.textLink, "darkModeFooterLink")}
                              >
                                {item.pageTitle}
                              </Link>
                            </li>
                          );
                        })}
                  </ul>

                  <ul className={cx(s.listContainer, s.listContainerGap)}>
                    <li className={s.footerLinks}>
                      <Link to={"/safety"} className={s.linkFooter}>
                        <div className={cx(s.textLink)}>
                          <FormattedMessage {...messages.trustSafety} />
                        </div>
                      </Link>
                    </li>
                    <li className={s.footerLinks}>
                      <Link to={"/travel"} className={s.linkFooter}>
                        <div className={cx(s.textLink)}>
                          <FormattedMessage {...messages.travelCredit} />
                        </div>
                      </Link>
                    </li>
                    {getEnabledBlog &&
                      getEnabledBlog.length > 0 &&
                      getEnabledBlog
                        .filter((item) => item.footerCategory == "discover")
                        .map((item) => {
                          return (
                            <li className={s.footerLinks}>
                              <Link
                                to={"/page/" + item.pageUrl}
                                className={cx(s.textLink, "darkModeFooterLink")}
                              >
                                {item.pageTitle}
                              </Link>
                            </li>
                          );
                        })}
                  </ul>

                  <ul className={cx(s.listContainer, s.listContainerGap)}>
                    <li className={s.footerLinks}>
                      <Link to={"/whyhost"} className={s.linkFooter}>
                        <div className={cx(s.textLink)}>
                          <FormattedMessage {...messages.becomeAHost} />
                        </div>
                      </Link>
                    </li>
                    <li className={s.footerLinks}>
                      <Link to={"/privacy"} className={s.linkFooter}>
                        <div className={cx(s.textLink)}>
                          <FormattedMessage {...messages.termsPrivacy} />
                        </div>
                      </Link>
                    </li>
                    {getEnabledBlog &&
                      getEnabledBlog.length > 0 &&
                      getEnabledBlog
                        .filter((item) => item.footerCategory == "hosting")
                        .map((item) => {
                          return (
                            <li className={s.footerLinks}>
                              <Link
                                to={"/page/" + item.pageUrl}
                                className={cx(s.textLink, "darkModeFooterLink")}
                              >
                                {item.pageTitle}
                              </Link>
                            </li>
                          );
                        })}
                  </ul>
                </div>
                <Grid fluid>
                  <Row className={cx(s.footerCopyrightContainer)}>
                    <div className={s.responsivePadding}>
                      <Col
                        sm={3}
                        md={3}
                        lg={2}
                        xs={12}
                        className={cx(s.responsiveMargin, s.noPaddingMobile)}
                      ></Col>
                      <Col
                        sm={3}
                        md={3}
                        lg={2}
                        xs={12}
                        className={cx(s.responsiveMargin, s.noPaddingMobile)}
                      ></Col>
                      <Col
                        sm={3}
                        md={3}
                        lg={2}
                        xs={12}
                        className={cx(s.responsiveMargin, s.noPaddingMobile)}
                      >
                        {/* <label className={cx(s.landingLabel)}><FormattedMessage {...messages.discover} /></label> */}
                      </Col>
                      <Col
                        sm={3}
                        md={3}
                        lg={2}
                        xs={12}
                        className={cx(s.responsiveMargin, s.noPaddingMobile)}
                      >
                        {/* <label className={cx(s.landingLabel)}><FormattedMessage {...messages.hosting} /></label> */}
                      </Col>
                      <Col
                        sm={3}
                        md={3}
                        lg={3}
                        xs={12}
                        className={cx(s.responsiveMargin, s.noPaddingMobile)}
                      ></Col>
                      {/* <Col sm={3} md={3} lg={3} xs={12} className={cx(s.responsiveMargin, s.noPaddingMobile)}>
                        {
                          appAvailableStatus == 1 && (playStoreUrl || appStoreUrl) &&
                          <div>
                            <label className={cx(s.landingLabel)}><FormattedMessage {...messages.appsAvailableOn} /></label>
                            <div className={cx(s.spaceTop21, s.iosFlex)}>
                              {
                                playStoreUrl && <a href={playStoreUrl} target="_blank" className={s.displayInlineBlock}>
                                  <img alt="Image" src={PlayStoreImage} />
                                </a>
                              }
                              {
                                appStoreUrl && <a href={appStoreUrl} target="_blank" className={s.displayInlineBlock}>
                                  <img alt="Image" src={AppStoreImage} />
                                </a>
                              }
                            </div>
                          </div>
                        }
                      </Col> */}
                    </div>
                  </Row>

                  <Row
                    className={cx(
                      s.copyrightSection,
                      s.copyrightSpaceTop,
                      s.copyRightTabNoMargin,
                      s.footerCopyrightContainer
                    )}
                  >
                    <Col
                      xs={6}
                      sm={4}
                      md={4}
                      lg={7}
                      className={s.noPaddingMobile}
                    >
                      <div className="flex gap-2">
                        <span
                          className={cx(
                            s.textFooter,
                            s.footerSiteName
                            // "darkModeFooterLink"
                          )}
                        >
                          {" "}
                          Copyright © 2023. All rights reserved |
                        </span>
                        <img
                          src={theme === "dark" ? darkLogo : mainLogo}
                          alt="..."
                          className="w-[86px] h-[18px]"
                        />
                      </div>
                    </Col>
                    <Col
                      xs={6}
                      sm={8}
                      md={8}
                      lg={5}
                      className={cx(
                        s.noPaddingMobile,
                        s.socialIcons,
                        "socialIconsFooterRTL"
                      )}
                    >
                      {/* {
                        facebook && <a href={facebook} target="_blank" className={cx(s.shareIcon, 'shareIconRtl')}>
                          <img alt="" src={facebookIcon} />
                        </a>
                      }
                      {
                        twitter && <a href={twitter} target="_blank" className={cx(s.shareIcon, 'shareIconRtl')}>
                          <img alt="" src={twitterIcon} />
                        </a>
                      }
                      {
                        instagram && <a href={instagram} target="_blank" className={cx(s.shareIcon, 'shareIconRtl')}>
                          <img alt="" src={instagramIcon} />
                        </a>
                      } */}
                    </Col>
                  </Row>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
  facebook: state.siteSettings.data.facebookLink,
  twitter: state.siteSettings.data.twitterLink,
  instagram: state.siteSettings.data.instagramLink,
  appAvailableStatus: state.siteSettings.data.appAvailableStatus,
  playStoreUrl: state.siteSettings.data.playStoreUrl,
  appStoreUrl: state.siteSettings.data.appStoreUrl,
  theme: state.currency.theme,
});

const mapDispatch = {};

export default compose(
  injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(getEnabledBlog, {
    options: {
      fetchPolicy: "network-only",
      ssr: false,
    },
  })
)(Footer);
