import {
  GET_BALANCE_USER_START,
  GET_BALANCE_USER_SUCCESS
} from '../constants';

export default function balance(state = {}, action) {
  switch (action.type) {
    case GET_BALANCE_USER_START:
      return {
        ...state,
      };
    case GET_BALANCE_USER_SUCCESS:
      return {
        ...state,
        data: action.updatedBalanceData,
      };
    
    default:
      return state;
  }
}
