import messages from '../../../../locale/messages'
const validate = values => {

    const errors = {};

    if (!values.url) {
        errors.url = messages.urlRequired;
    } else if (!values.url.trim()) {
        errors.url = messages.urlRequired;
    }

    if (!values.name) {
        errors.name = messages.nameRequired;
    } else if (!values.name.trim()) {
        errors.name = messages.nameRequired;
    }

    return errors
}

export default validate;