import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, reset, change } from "redux-form";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import FormControl from "react-bootstrap/lib/FormControl";
import {
  injectStripe,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "react-stripe-elements";
import { toastr } from "react-redux-toastr";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";

import HouseRules from "./HouseRules";
import Loader from "../../Loader";
import Link from "../../Link";
import ModalForm from "./ModalForm/ModalForm";

import validate from "./validate";
import fetch from "../../../core/fetch";
import messages from "../../../locale/messages";
import { isRTL } from "../../../helpers/formatLocale";
import { makePayment } from "../../../actions/booking/makePayment";
import { processCardAction } from "../../../actions/PaymentIntent/processCardAction";
import {
  applyPaymentMethod,
  openPaymentModal,
} from "../../../actions/modalActions";

import imageOne from "../../../../public/SiteIcons/payment-icons.png";
import imageTwo from "../../../../public/SiteIcons/stripe-connect.png";
import R3V from "../../../../public/SiteIcons/R3V.svg";
import dollar from "../../../../public/SiteIcons/dollar.svg";
import leftArrowIcon from "../../../../public/SiteIcons/leftIcon.svg";
import creditCard from "../../../../public/SiteIcons/CreditCard.svg";
import arrow from "../../../../public/SiteIcons/chevron-right.svg";
import defaultPic from "../../../../public/SiteImages/defaultPic.png";

import s from "./Payment.css";
import bt from "../../../components/commonStyle.css";
import balance from "../../../reducers/balance";
import axios from "axios";
import { privateKeyTap } from "../../../config";

const createOptions = (theme, isRTLLocale) => {
  return {
    style: {
      base: {
        color: theme == "dark" ? "#fff" : "#484848",
        fontWeight: 400,
        fontFamily: "inherit",
        fontSize: "14px",
        textAlign: isRTLLocale ? "right" : "left",
        fontSmoothing: "antialiased",
        ":focus": {
          color: theme == "dark" ? "#fff" : "#484848",
        },

        "::placeholder": {
          color: "#aaa",
        },

        ":focus::placeholder": {
          color: "#aaa",
        },
      },
      invalid: {
        color: theme == "dark" ? "#fff" : "#484848",
        ":focus": {
          color: theme == "dark" ? "#fff" : "#484848",
        },
        "::placeholder": {
          color: "#aaa",
        },
      },
    },
  };
};
class PaymentForm extends Component {
  static propTypes = {
    houseRules: PropTypes.arrayOf(
      PropTypes.shape({
        listsettings: PropTypes.shape({
          itemName: PropTypes.string.isRequired,
        }),
      })
    ),
    hostDisplayName: PropTypes.string.isRequired,
    allowedPersonCapacity: PropTypes.number.isRequired,
    initialValues: PropTypes.shape({
      listId: PropTypes.number.isRequired,
      listTitle: PropTypes.string.isRequired,
      hostId: PropTypes.string.isRequired,
      guestId: PropTypes.string.isRequired,
      checkIn: PropTypes.object.isRequired,
      checkOut: PropTypes.object.isRequired,
      guests: PropTypes.number.isRequired,
      basePrice: PropTypes.number.isRequired,
      cleaningPrice: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      weeklyDiscount: PropTypes.number,
      monthlyDiscount: PropTypes.number,
      paymentType: PropTypes.number,
    }).isRequired,
    paymentCurrencyList: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        symbol: PropTypes.string.isRequired,
        isEnable: PropTypes.bool.isRequired,
        isPayment: PropTypes.bool.isRequired,
      })
    ),
    paymentLoading: PropTypes.bool,
    formatMessage: PropTypes.any,
  };

  static defaultProps = {
    paymentCurrencyList: [],
    paymentLoading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      paymentStatus: 2,
      load: true,
      cardEle: null,
      tapSdk: null,
      personCapacityData: [],
      fireRedirect: false,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      listingFields,
      stripePayment,
      spotWalletPayment,
      R3VWalletPayment,
    } = this.props;
    if (listingFields != undefined) {
      this.setState({
        personCapacityData: listingFields?.personCapacity,
      });
    }
  }
  //
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps, "nextProps");
    const { listingFields, applyPaymentMethod, total, totalPriceR3v, totalPriceAED, platformFees } =
      nextProps;
    if (listingFields != undefined) {
      this.setState({
        personCapacityData: listingFields?.personCapacity,
      });
    }
    applyPaymentMethod({ platformFees, total, totalPriceR3v, totalPriceAED });
  }

  componentDidUpdate(prevProps) {
    const { locale } = this.props.intl;
    const { locale: prevLocale } = prevProps.intl;

    if (locale !== prevLocale) {
      this.setState({ load: false });
      clearTimeout(this.loadSync);
      this.loadSync = null;
      this.loadSync = setTimeout(() => this.setState({ load: true }), 1);
    }
  }

  renderFormControlSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
    disabled,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl
          disabled={disabled}
          componentClass="select"
          {...input}
          className={className}
        >
          {children}
        </FormControl>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  };

  renderFormControlTextArea = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl
          {...input}
          className={className}
          componentClass="textarea"
          placeholder={label}
        >
          {children}
        </FormControl>
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  };

  renderGuests(personCapacity) {
    const { formatMessage } = this.props.intl;
    const { personCapacityData } = this.state;

    let rows = [],
      i;
    for (i = 1; i <= personCapacity; i++) {
      rows.push(
        <option key={i} value={i}>
          {i}{" "}
          {i > 1
            ? personCapacityData[0]?.otherItemName
            : personCapacityData[0]?.itemName}
        </option>
      );
    }
    return rows;
  }

  renderpaymentCurrencies = () => {
    const { paymentCurrencyList } = this.props;
    let rows = [];

    if (paymentCurrencyList != null && paymentCurrencyList?.length > 0) {
      paymentCurrencyList?.map((item, index) => {
        if (item?.isEnable && item?.isPayment) {
          rows.push(
            <option key={index} value={item?.symbol}>
              {item?.symbol}
            </option>
          );
        }
      });
    }
    return rows;
  };

  renderFormControl = ({
    input,
    label,
    type,
    placeholder,
    meta: { touched, error },
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl
          {...input}
          placeholder={placeholder}
          type={type}
          className={className}
          maxLength={11}
        />
        {touched && error && (
          <span className={s.errorMessage}>{formatMessage(error)}</span>
        )}
      </div>
    );
  };

  handleClick = () => {
    const { dispatch } = this.props;
    dispatch(reset("BookingForm"));
  };

  handleSubmit = async (values, dispatch, event) => {
    console.log({
      debugPaymentFormValues: {
        values: values,
        total: values?.total,
        totalVallue: values?.totalValue,
      },
    });
   
    
    const { balance } = this.props;
    const usd_balance = balance?.balance;
    const r3v_balance = balance?.air_balance;
    const {cardEle, tapSdk} = this.state
    const { stripe, processCardAction } = this.props;

    let paymentType, paymentCurrency, query;
    // paymentType = values?.paymentType;
    paymentType = this.state.paymentStatus;
    console.log({ DebugPaymentTypeState: paymentType });
    paymentCurrency =
      paymentType == 3 || paymentType == 4
        ? values?.paymentCurrency
        : paymentType == 1
        ? values?.paymentCurrency
        : null;

    if (usd_balance === 0 && r3v_balance === 0) {
      toastr.error("Oops!", "Your balance is empty!");
      return;
    }
    if (
      ((paymentType === 3) &&
        usd_balance < values.totalValue) ||
      (paymentType === 4 && r3v_balance < values.totalValue)
    ) {
      toastr.error("Oops!", "Not enough balance");
      return;
    }

    query = `query checkReservation ($checkIn: String,$checkOut: String,$listId: Int ){
      checkReservation(checkIn: $checkIn, checkOut:$checkOut, listId:$listId ){
        id
        listId
        hostId
        guestId
        checkIn
        checkOut
        status
      }
    }`;

    values.checkIn = moment(values?.checkIn).format("YYYY-MM-DD");
    values.checkOut = moment(values?.checkOut).format("YYYY-MM-DD");

    const params = {
      listId: values?.listId,
      checkIn: values?.checkIn,
      checkOut: values?.checkOut,
    };

    const resp = await fetch("/graphql", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables: params,
      }),
      credentials: "include",
    });

    const { data } = await resp.json();

    // console.log({DebugPaymentType: paymentType});
    // console.log({DebugPaymentCurrency: values.paymentCurrency})
    // return;

    if (data?.checkReservation?.status == "200") {
      let msg = "",
        paymentMethodId,
        createPaymentMethod;

      // Pay in R3V Wallet
      if (paymentType == 4) {
        await dispatch(
          makePayment(
            values?.listId,
            values?.listTitle,
            values?.hostId,
            values?.guestId,
            values?.checkIn,
            values?.checkOut,
            values?.guests,
            values?.message,
            values?.basePrice,
            values?.cleaningPrice,
            values?.currency,
            values?.discount,
            values?.discountType,
            values?.guestServiceFee,
            values?.hostServiceFee,
            values?.totalValue,
            values?.bookingType,
            paymentCurrency,
            paymentType,
            values?.guestEmail,
            values?.bookingSpecialPricing,
            values?.isSpecialPriceAssigned,
            values?.isSpecialPriceAverage,
            values?.dayDifference,
            paymentMethodId,
            null,
            values?.checkInStart,
            values?.checkInEnd,
            values?.hostServiceFeeType,
            values?.hostServiceFeeValue,
            values?.threadId
          )
        );
      } else {
        // Pay in USD Wallet
        if (paymentType == 3) {
          const { status } = await dispatch(
            makePayment(
              values?.listId,
              values?.listTitle,
              values?.hostId,
              values?.guestId,
              values?.checkIn,
              values?.checkOut,
              values?.guests,
              values?.message,
              values?.basePrice,
              values?.cleaningPrice,
              values?.currency,
              values?.discount,
              values?.discountType,
              values?.guestServiceFee,
              values?.hostServiceFee,
              values?.totalValue,
              values?.bookingType,
              paymentCurrency,
              paymentType,
              values?.guestEmail,
              values?.bookingSpecialPricing,
              values?.isSpecialPriceAssigned,
              values?.isSpecialPriceAverage,
              values?.dayDifference,
              paymentMethodId,
              null,
              values?.checkInStart,
              values?.checkInEnd,
              values?.hostServiceFeeType,
              values?.hostServiceFeeValue,
              values?.threadId
            )
          );
          console.log({ debugMakePayment: status });
          if (status) {
            this.setState({ fireRedirect: true });
          }
        } else if(paymentType == 2){
          // Pay with TAP
            const { status, paymentIntentSecret, reservationId } =
              await dispatch(
                makePayment(
                  values?.listId,
                  values?.listTitle,
                  values?.hostId,
                  values?.guestId,
                  values?.checkIn,
                  values?.checkOut,
                  values?.guests,
                  values?.message,
                  values?.basePrice,
                  values?.cleaningPrice,
                  values?.currency,
                  values?.discount,
                  values?.discountType,
                  values?.guestServiceFee,
                  values?.hostServiceFee,
                  values?.totalValue,
                  values?.bookingType,
                  paymentCurrency,
                  paymentType,
                  values?.guestEmail,
                  values?.bookingSpecialPricing,
                  values?.isSpecialPriceAssigned,
                  values?.isSpecialPriceAverage,
                  values?.dayDifference,
                  paymentMethodId,
                  null,
                  values?.checkInStart,
                  values?.checkInEnd,
                  values?.hostServiceFeeType,
                  values?.hostServiceFeeValue,
                  values?.threadId,
                )
              );
            if (status == 400) {
              // const cardAction = await stripe?.handleCardAction(
              //   paymentIntentSecret
              // );
              // let confirmPaymentIntentId, amount;
              // amount = values?.total + values?.guestServiceFee;
              // if (cardAction?.paymentIntent.id) {
              //   confirmPaymentIntentId = cardAction?.paymentIntent?.id;
              //   const { handleCardActionStatus, errorMessage } =
              //     await processCardAction(
              //       reservationId,
              //       values?.listId,
              //       values?.hostId,
              //       values?.guestId,
              //       values?.listTitle,
              //       values?.guestEmail,
              //       amount,
              //       values?.currency,
              //       confirmPaymentIntentId
              //     );
              // } else {
              //   if (cardAction?.error?.message) {
              //     msg = cardAction?.error?.message;
              //     toastr.error("Oops!", msg);
              //   }
              // }
            }
      }
    }
    } else {
      toastr.error("Oops!", "Those dates are not available.");
    }
  };

  handlePayment = async (e, dataForDetail) => {
    console.log(e, "sita");
    const { change, onChangePaymentTypeValue, dispatch } = this.props;
    let paymentType = e;
    if (!paymentType) return;
    console.log({
      debug: {
        paymentType: paymentType,
        dataForDetail: dataForDetail,
      },
    });
    //
    if (paymentType == 2) {
      this.setState({ paymentStatus: 2 });
    } else if (paymentType == 3) {
      this.setState({ paymentStatus: 3 });
    } else if (paymentType == 4) {
      this.setState({ paymentStatus: 4 });
    } else {
      change("zipcode", null);
      this.setState({ paymentStatus: 1 });
    }
    onChangePaymentTypeValue({
      paymentType: Number(paymentType),
      dataForDetail: dataForDetail,
    });
  };

  // componentDidMount() {
  //   const tap = Tapjsli('pk_test_EtHFV4BuPQokJT6jiROls87Y');

  //   const elements = tap.elements();

  //   const style = {
  //     base: {
  //       color: '#535353',
  //       lineHeight: '18px',
  //       fontFamily: 'sans-serif',
  //       fontSmoothing: 'antialiased',
  //       fontSize: '16px',
  //       '::placeholder': {
  //         color: 'rgba(0, 0, 0, 0.26)',
  //         fontSize: '15px'
  //       }
  //     },
  //     invalid: {
  //       color: 'red'
  //     }
  //   };

  //   const labels = {
  //     cardNumber: "Card Number",
  //     expirationDate: "MM/YY",
  //     cvv: "CVV",
  //     cardHolder: "Card Holder Name"
  //   };

  //   const paymentOptions = {
  //     currencyCode: ["KWD", "AED", "SAR"],
  //     labels: labels,
  //     TextDirection: 'ltr',
  //     paymentAllowed: ['VISA', 'MASTERCARD', 'AMEX', 'MADA']
  //   };

  //   const card = elements.create('card', { style: style }, paymentOptions);
  //   this.setState({cardEle: card})
  // }

  render() {
    const {
      hostDisplayName,
      houseRules,
      hostPicture,
      hostProfileId,
      paymentLoading,
      intl: { locale },
      // dataForDetail,
      total,
      platformFees,
    } = this.props;
    const {
      handleSubmit,
      submitting,
      error,
      pristine,
      paymentType,
      guests,
      currency,
      cleaningPrice,
      balance,
    } = this.props;
    const {
      openPaymentModal,
      maximumStay,
      stripePayment,
      payPalPayment,
      theme,
      spotWalletPayment,
      R3VWalletPayment,
    } = this.props;
    const { restrictEdit, isPreApprove, listId, checkIn, checkOut } =
      this.props;
    const { paymentStatus, load, fireRedirect , cardEle} = this.state;
    const { formatMessage } = this.props.intl;
    console.log(balance, "balancebalancebalance");
    const dataForDetail = { total: total, platformFees: platformFees };

    let checkInDate, checkOutDate, paymentModalInitialValues;
    checkInDate =
      checkIn != null
        ? moment(moment(checkIn).format("YYYY-MM-DD")).format("MM/DD/YYYY")
        : "";
    checkOutDate =
      checkOut != null
        ? moment(moment(checkOut).format("YYYY-MM-DD")).format("MM/DD/YYYY")
        : "";
    paymentModalInitialValues = {
      checkIn,
      checkOut,
      guests,
      paymentType,
      currency,
      cleaningPrice,
    };

    console.log({
      debugPaymentForm: {
        state: this.state,
        props: this.props,
        fireRedirect: fireRedirect,
        dataForDetail: dataForDetail
      },
    });

    if (fireRedirect) {
      // redirect('/trips/current', 200);
    }

    return (
      <div
        className={cx(
          s.bookItPanel,
          s.spaceTop2,
          s.aboutNoMargin,
          "customRatioButton"
        )}
      >
        <ModalForm listId={listId} initialValues={paymentModalInitialValues} />
        <form de onSubmit={handleSubmit(this.handleSubmit)}>
         {!paymentLoading && (
                <div className={cx(s.cancelBtn, "mb-[32px]")} >
                  <Link
                    to={"/rooms/" + listId}
                    className={cx(s.cancelLinkText)}
                    onClick={this.handleClick}
                  >
                    <img className="w-[16px]" src={leftArrowIcon}/>
                    <FormattedMessage {...messages.cancel} />
                  </Link>
                </div>
              )}
          <h1 className={s.titleText}>
            <FormattedMessage {...messages.reviewAndPay} />
          </h1>
          {!isPreApprove && (
            <div>
              <div className={s.flex}>
                <div>
                  <div className={s.dateTitle}>
                    <FormattedMessage {...messages.dates} />
                  </div>
                  <div className={cx(s.showDate, "textWhite")}>
                    {checkInDate}
                    {" - "}
                    {checkOutDate}
                  </div>
                </div>
                {!restrictEdit && (
                  <div>
                    <div>
                      <a
                        onClick={() => openPaymentModal()}
                        className={s.editCss}
                      >
                        <span>
                          <FormattedMessage {...messages.editLabel} />
                        </span>{" "}
                        <span className={cx(s.editIcon, "editIconPayRTL")}>
                          <img src={arrow} />
                        </span>
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div className={cx(s.flex, s.marginTop)}>
                <div>
                  <div className={s.dateTitle}>
                    <FormattedMessage {...messages.guests} />
                  </div>
                  <div className={cx(s.showDate, "textWhite")}>
                    {guests}{" "}
                    {guests > 1 ? (
                      <FormattedMessage {...messages.guests} />
                    ) : (
                      <FormattedMessage {...messages.guest} />
                    )}
                  </div>
                </div>
                {!restrictEdit && (
                  <div>
                    <div>
                      <a
                        onClick={() => openPaymentModal()}
                        className={s.editCss}
                      >
                        <span>
                          <FormattedMessage {...messages.editLabel} />
                        </span>{" "}
                        <span className={cx(s.editIcon, "editIconPayRTL")}>
                          <img src={arrow} />
                        </span>
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div className={s.commonBorder}></div>
            </div>
          )}

          {houseRules?.length > 0 && (
            <div className={s.space4}>
              <HouseRules
                hostDisplayName={hostDisplayName}
                houseRules={houseRules}
              />
              <div className={s.commonBorder}></div>
            </div>
          )}

          <div className={cx(s.textLeft, "textAlignRightRtl")}>
            <div className={cx(s.h3, s.bold)}>
              <FormattedMessage {...messages.aboutYourTrip} />
            </div>
            <div className={s.aboutPaymentDesc}>
              <FormattedMessage {...messages.aboutDescPayment} />
            </div>
            <div className={s.hostFlex}>
              <Link to={"/users/show/" + hostProfileId}>
                {
                  <img
                    src={
                      hostPicture
                        ? "/images/avatar/medium_" + hostPicture
                        : defaultPic
                    }
                    className={s.avatarImage}
                  />
                }
              </Link>
              <div className={cx(s.messageSection)}>
                <span>
                  <FormattedMessage {...messages.hostedBy} />
                </span>{" "}
                <span>{hostDisplayName}</span>
              </div>
            </div>
            <div>
              <Field
                className={s.textArea}
                name="message"
                component={this.renderFormControlTextArea}
                label={formatMessage(messages.descriptionInfo)}
              />
            </div>
            <div className={s.commonBorder}></div>
          </div>
          <Col
            md={10}
            className={cx(s.textLeft, "textAlignRightRtl", s.noPadding)}
          >
            <section>
              <header className={s.paymentHeader}>
                <Row>
                  <Col
                    md={10}
                    className={cx(
                      s.textLeft,
                      s.paymentPadding,
                      "textAlignRightRtl"
                    )}
                  >
                    <h3
                      className={cx(
                        s.pullLeft,
                        s.h3,
                        s.space2,
                        "pullRightBooking"
                      )}
                    >
                      <FormattedMessage {...messages.payment} />
                    </h3>
                  </Col>
                </Row>
              </header>
            </section>
            <div className="flex flex-wrap gap-[3.25rem]">
              {stripePayment ? (
                <div
                  className={cx(
                    paymentStatus === 2 ? s.paymentModeSelected : s.paymentMode
                  )}
                  onClick={(e) => {
                    this.handlePayment(2, dataForDetail);
                  }}
                >
                  {/* <img src={creditCard} className={"w-[24px] h-[24px]"} /> */}
                  <div className={cx(s.paymentModeText)}>
                    <FormattedMessage {...messages.stripeWallet} />
                  </div>
                </div>
              ) : null}

              {/* {spotWalletPayment ? (
                <div
                  className={cx(
                    paymentStatus === 3 ? s.paymentModeSelected : s.paymentMode
                  )}
                  onClick={(e) => {
                    this.handlePayment(3, dataForDetail);
                  }}
                >
                  <img src={dollar} className={"w-[24px] h-[24px]"} />
                  <div className={cx(s.paymentModeText)}>
                    <FormattedMessage {...messages.spotUSDWallet} />
                  </div>
                  <div className={cx(s.paymentModeDesc)}>
                    Available balance: {balance?.balance || 0} USD
                  </div>
                </div>
              ) : null} */}

              {R3VWalletPayment ? (
                <div
                  className={cx(
                    paymentStatus === 4 ? s.paymentModeSelected : s.paymentMode
                  )}
                  onClick={(e) => {
                    this.handlePayment(4, dataForDetail);
                  }}
                >
                  {/* <img src={R3V} className={"w-[24px] h-[24px]"} /> */}
                  <div className={cx(s.paymentModeText)}>
                    {" "}
                    <FormattedMessage {...messages.spotR3VWallet} />
                  </div>
                  <div className={cx(s.paymentModeDesc)}>
                    Available balance: {balance?.air_balance || 0} R3V
                  </div>
                </div>
              ) : null}
            </div>
            {/* {stripePayment && (
              <span>
                <label
                  onClick={(e) => {
                    this.handlePayment(e.target.value, dataForDetail);
                  }}
                >
                  <Field
                    name="paymentType"
                    component="input"
                    type="radio"
                    value="2"
                    className={cx(s.cursorPointer)}
                  />
                  <span
                    className={cx(s.radioTextSection, "radioTextSectionRTL")}
                  >
                    {" "}
                    <FormattedMessage {...messages.creditCard} />
                  </span>
                </label>
              </span>
            )} */}
            {paymentStatus == 2 ? (
              !load ? (
                <Loader />
              ) : (
                <div id="element-container"></div>
              )
            ) : (
              <span></span>
            )}

            {/* {spotWalletPayment && (
              <Row className={s.payPalTop}>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <span>
                    <label
                      onClick={(e) => {
                        this.handlePayment(e.target.value, dataForDetail);
                      }}
                    >
                      <Field
                        name="paymentType"
                        component="input"
                        type="radio"
                        value="3"
                        checked={paymentType == 3}
                        className={cx(s.cursorPointer)}
                      />
                      <span
                        className={cx(
                          s.radioTextSection,
                          "radioTextSectionRTL"
                        )}
                      >
                        {" "}
                        <FormattedMessage {...messages.spotUSDWallet} /> (
                        {balance?.balance || 0} USD)
                      </span>
                    </label>
                  </span>
                </Col>
              </Row>
            )}

            {paymentStatus == 3 && (
              <Row className={cx(s.space4, s.spaceTop3)}>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <span className={cx(s.textLight)}>
                    <FormattedMessage {...messages.spotUSDWalletInfo} />
                  </span>
                </Col>
              </Row>
            )}

            {R3VWalletPayment && (
              <Row className={s.payPalTop}>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <span>
                    <label
                      onClick={(e) => {
                        this.handlePayment(e.target.value, dataForDetail);
                      }}
                    >
                      <Field
                        name="paymentType"
                        component="input"
                        type="radio"
                        value="4"
                        className={cx(s.cursorPointer)}
                      />
                      <span
                        className={cx(
                          s.radioTextSection,
                          "radioTextSectionRTL"
                        )}
                      >
                        {" "}
                        <FormattedMessage {...messages.spotR3VWallet} /> (
                        {balance?.air_balance || 0} R3V)
                      </span>
                    </label>
                  </span>
                </Col>
              </Row>
            )}
            {paymentStatus == 4 && (
              <Row className={cx(s.space4, s.spaceTop3)}>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <span className={cx(s.textLight)}>
                    <FormattedMessage {...messages.spotR3VWalletInfo} />
                  </span>
                </Col>
              </Row>
            )} */}

            <div className={cx(s.footerBtns, "flex")}>
            
              {/* {paymentLoading && (
                <div className={s.cancelBtn}>
                  <a
                    href="javascript:void(0)"
                    className={cx(s.cancelLinkText, {
                      [s.disabledLink]: submitting == true,
                    })}
                  >
                    <FormattedMessage {...messages.cancel} />
                  </a>
                </div>
              )} */}
              <div className={""}>
                <Loader
                  type={"button"}
                  buttonType={"submit"}
                  className={cx(s.btnPrimary, "arButtonLoader", s.loaderFlex)}
                  disabled={pristine || submitting || error || maximumStay}
                  show={paymentLoading}
                  label={formatMessage(messages.payNow)}
                />
              </div>
            </div>
          </Col>
        </form>
      </div>
    );
  }
}

PaymentForm = reduxForm({
  form: "PaymentForm", // a unique name for this form
  validate,
})(PaymentForm);

// Decorate with connect to read form values
const selector = formValueSelector("PaymentForm"); // <-- same as form name

const mapState = (state) => ({
  paymentCurrencyList: state?.currency?.availableCurrencies,
  paymentLoading: state?.book?.paymentLoading,
  paymentType: selector(state, "paymentType"),
  listingFields: state?.listingFields?.data,
  checkIn: selector(state, "checkIn"),
  checkOut: selector(state, "checkOut"),
  guests: Number(selector(state, "guests")),
  maximumStay: state?.viewListing?.maximumStay,
  payPalPayment: state?.book?.payPalPayment,
  stripePayment: state?.book?.stripePayment,
  restrictEdit: state?.viewListing?.restrictEdit,
  theme: state?.currency?.theme,
  balance: state?.balance?.data,
});

const mapDispatch = {
  // makePayment,
  processCardAction,
  openPaymentModal,
  applyPaymentMethod,
};

export default injectStripe(
  injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(PaymentForm)))
);
