exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SignupModal-lineBar-2RYd5{\n    -ms-flex-item-align: center;\n        align-self: center;\n       width: 120px;\n       height: 1px;\n       background-color: var(--common-text-color);\n       background-color: var(--common-text-color);\n   }", ""]);

// exports
exports.locals = {
	"lineBar": "SignupModal-lineBar-2RYd5"
};