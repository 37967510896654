import React, { Component } from "react";
import PropTypes from "prop-types";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";

import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Image } from "react-bootstrap";

import cx from "classnames";
import s from "./SocialLogin.css";
// Translation
import { FormattedMessage, injectIntl } from "react-intl";

// Locale
import messages from "../../locale/messages";

//Images
import googleIcon from "../../../public/SiteIcons/googleSign.svg";
import clsx from "clsx";
class SocialLogin extends Component {
  static propTypes = {
    formatMessage: PropTypes.any,
    refer: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    const { isAdmin } = this.props;
    if (isAdmin) {
      toastr.error(
        "Error!",
        "You are logged in as admin, you can't perform this action!"
      );
      event.preventDefault();
    }
  }

  render() {
    const { refer } = this.props;
    let FbURL = "/login/facebook";
    let GoogleURL = "/login/google";
    if (refer) {
      FbURL = "/login/facebook?refer=" + refer;
      GoogleURL = "/login/google?refer=" + refer;
    }

    return (
      <div>
        <a
          className={clsx(
            "mt-8 relative  w-full mx-auto",
            "text-white text-base h-12 py-[9px] px-5 rounded-md bg-[#242526]",
            "border-[3px] border-[#242526]",
            "hover:bg-[#2C2E2F] hover:text-[#E5E6EB] hover:no-underline",
            "shadow-[-2px_-2px_6px_0px_#444A53,4px_4px_4px_0px_rgba(0,0,0,0.25)]",
            "active:bg-[#242526] active:shadow-[2px_2px_6px_0px_#000_inset,-1px_-1px_3px_0px_#444A53_inset]",
            "flex items-center justify-center space-x-2",
            "transition-all duration-300 ease-in-out"
          )}
          onClick={(event) => this.handleClick(event)}
          href={GoogleURL}
        >
          <Image src={googleIcon} responsive className="absolute left-5" />
          <span>
            <FormattedMessage {...messages.googleLogin} />
          </span>
        </a>
      </div>
    );
  }
}

const mapState = (state) => ({
  isAdmin: state.runtime && state.runtime.isAdminAuthenticated,
});

const mapDispatch = {};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(SocialLogin))
);
