import React from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { cx } from "classnames";
import clsx from "clsx";
export const GrayButton = ({
  children,
  onClick,
  containerClassname,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      className={`buttonShadowContainer ${containerClassname}`}
      onClick={onClick}
    >
      <div className="buttonShadowContent">{children}</div>
    </Button>
  );
};
export const LinearButton = ({
  children,
  onClick,
  containerClassname,
  size,
  py,
  px,
  ...rest
}) => {
  return (
    <button
      className={`buttonLinearContainer ${containerClassname}`}
      onClick={onClick}
      {...rest}
    >
      <div className={`buttonLinearContent`}>
        <div
          className={`buttonLinearEffect ${
            size?.[0] ? `w-[${size?.[0]}px]` : ""
          } ${size?.[1] ? `h-[${size?.[1]}px]` : ""} py-[${py || 10}px] !px-[${
            px || 10
          }px]`}
        >
          {children}
        </div>
      </div>
    </button>
  );
};
export const LinearButtonSolid = ({
  children,
  onClick,
  containerClassname,
  size,
  py,
  px,
  ...rest
}) => {
  console.log(
    `buttonLinearEffectSolid ${size?.[0] ? `w-[${size?.[0]}px]` : ""} ${
      size?.[1] ? `h-[${size?.[1]}px]` : ""
    } py-[${py || 10}px] !px-[${px || 10}px]`
  );
  return (
    <button
      className={`buttonLinearSpin ${
        size?.[0] ? `w-[${size?.[0]}px]` : ""
      } ${size?.[1] ? `h-[${size?.[1]}px]` : ""} ${containerClassname}`}
      onClick={onClick}
      {...rest}
    >
      <div
        className={`buttonLinearEffectSolid py-[${py || 10}px] !px-[${
          px || 10
        }px]`}
      >
        {children}
      </div>
    </button>
  );
};
