import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './NewStaking.css';
import { Col, FormControl, FormGroup, Grid, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import cx from 'classnames'
import { FormattedMessage } from 'react-intl';
import messages from '../../locale/messages';
import history from '../../core/history';
import StakingMenu from '../../components/Staking/StakingMenu';
import { Table, Tr, Td, Thead, Th } from 'reactable';
import { compose, gql, graphql } from 'react-apollo';
import moment from 'moment';
import { STAKING_TYPE } from '../../constants';

class RewardHistory extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };


  render() {
    const { getStakingHistory } = this.props
    return (
      <Grid fluid className='dashBoardContainer'>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={cx('commonListingBg', 'tripContainer')}>
              <StakingMenu />
              <div>
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0 pt-[32px]">
                  <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                    <div className={cx("flex grow flex-col w-full pl-8 py-11 rounded-xl items-start max-md:max-w-full max-md:mt-6 max-md:px-5", s.block1)}>
                      <div className="text-[#242526] text-xl font-medium whitespace-nowrap">
                        R3V Total Pending Rewards of Pool
                      </div>
                      <div className="text-[#242526] text-base font-medium mt-24 max-md:mt-10">
                        Balance
                      </div>
                      <div className="text-[#242526] text-2xl font-medium mt-4">
                        {stakingPool?.total_staked || 0} REV
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                    <div className={cx("flex grow flex-col w-full pl-8 py-11 rounded-xl items-start max-md:max-w-full max-md:mt-6 max-md:px-5", s.block2)}>
                      <div className="text-[#242526] text-xl font-medium whitespace-nowrap">
                        R3V Total Staked of Pool
                      </div>
                      <div className="text-[#242526] text-base font-medium mt-24 max-md:mt-10">
                        Balance
                      </div>
                      <div className="text-[#242526] text-2xl font-medium mt-4">
                        {stakingPool?.total_pending_reward_pool || 0} REV
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

      </Grid>
    );
  }
}


export default compose(withStyles(s), graphql(gql`
query {
  getStakingHistory{
    action
    userId
    action_name
    pool_id
    amount
    created_at
  }
}

`, {
  name: 'getStakingHistory',
  options: {
    ssr: false
  }
}))(RewardHistory);

