// General
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Translation
import { injectIntl, FormattedMessage } from "react-intl";
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import { Button, ProgressBar } from "react-bootstrap";

import SaveButton from "../../components/Header/List/SaveButton";

import s from "./ListPlaceStep1.css";
import bt from "../../components/commonStyle.css";

import Loader from "../Loader";

// Locale
import messages from "../../locale/messages";
import history from "../../core/history";
import leftIcon from "../../../public/SiteIcons/leftIcon.svg";
import { GrayButton, LinearButton } from "../Button/GrayButton";

class FooterButton extends Component {
  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    nextPagePath: PropTypes.any,
    previousPagePath: PropTypes.any,
  };

  render() {
    const {
      previousPage,
      nextPage,
      updateListingMap,
      isDisabled,
      nextPagePath,
      previousPagePath,
      type,
      isExistingList,
      mapUpdateLoading,
      loading,
      isAvailable,
      skipLabel,
      formPage,
      step,
      className,
    } = this.props;

    const { formatMessage } = this.props.intl;

    if (type == "homepage") {
      return (
        <div
          className={cx(
            "bg-transparent",
            s.listNextPosition,
            className,
            "listNextPositionRTL"
          )}
        >
          <div className={cx(s.footerBlock, "flex ", s.bottomBlock)}>
            <div></div>
          </div>
        </div>
      );
    }

    return (
      <div className={""}>
        <div
          className={cx(
            "bg-transparent",
            s.listNextPosition,
            className,
            "listNextPositionRTL"
          )}
        >
          <div className={s.footerBlock}>
            <div
              className={cx(s.listNextBackButton, s.stepEditNextBtn, "!pb-0")}
            >
             
              <div className={cx(s.footerBtnFlex, "footerBtnFlexRTL")}>
                {/* <Button className={cx(bt.btnLarge, s.backBtnFooter, s.backButtonFooter, 'backBtnFooterRTL flex items-center')} onClick={() => previousPage(previousPagePath)}>
									<img src={leftIcon} className='mr-[11px] h-[15px]'/>
									<FormattedMessage {...messages.back} />
								</Button> */}
                <GrayButton onClick={() => previousPage(previousPagePath)}>
                  <img src={leftIcon} className="mr-[11px] h-[15px]" />
                  <div className="text-[#E5E6EB] mr-[8px]">
                    <FormattedMessage {...messages.back} />
                  </div>
                </GrayButton>
                {!type && (
                  <LinearButton
                    disabled={isDisabled}
                    onClick={() => nextPage(nextPagePath)}
                  >
                    <div className="text-[#E5E6EB]">
                      {skipLabel ? (
                        isAvailable ? (
                          <FormattedMessage {...messages.next} />
                        ) : (
                          <FormattedMessage {...messages.skip} />
                        )
                      ) : (
                        <FormattedMessage {...messages.next} />
                      )}
                    </div>
                  </LinearButton>
                )}
                {type && type == "submit" && (
                  <LinearButton
                    onClick={() => nextPage(nextPagePath)}
                    disabled={isDisabled}
                    type="submit"
                  >
                    <div>
                      <FormattedMessage {...messages.next} />
                    </div>
                  </LinearButton>
                )}

                {type && type != "submit" && isExistingList && (
                  <div
                    className={cx(
                      s.displayInlineBlock,
                      bt.neumorphicButtonContainer
                    )}
                  >
                    <Loader
                      type={"button"}
                      label={formatMessage(messages.next)}
                      show={mapUpdateLoading}
                      disabled={isDisabled}
					  neumorphic
                    //   className={cx(
                    //     bt.btnPrimary,
                    //     bt.btnLarge,
                    //     "arButtonLoader",
                    //     bt.gradientBackground
                    //   )}
                      handleClick={() => updateListingMap()}
                    />
                  </div>
                )}
                {type && type != "submit" && !isExistingList && (
                  <div className={cx(s.displayInlineBlock)}>
                    <Loader
                      type={"button"}
                      label={formatMessage(messages.next)}
                      buttonType={"submit"}
                      show={loading}
                      disabled={isDisabled}
					  neumorphic
                    //   className={cx(
                    //     bt.btnPrimary,
                    //     bt.btnLarge,
                    //     "arButtonLoader",
                    //     bt.gradientBackground
                    //   )}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  isExistingList: state.location.isExistingList,
  loading: state.loader.location,
  mapUpdateLoading: state.location.mapUpdateLoading,
});

const mapDispatch = {};

export default injectIntl(
  withStyles(s, bt)(connect(mapState, mapDispatch)(FooterButton))
);
