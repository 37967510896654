import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Elements } from "react-stripe-elements";

import PaymentDetails from "./PaymentDetails";
import PaymentForm from "./PaymentForm";
import Avatar from "../../Avatar";
import ListCoverPhoto from "../../ListCoverPhoto";
import StarRating from "../../StarRating";
import Navigation from "../../Navigation/Navigation";

import messages from "../../../locale/messages";
import { convert } from "../../../helpers/currencyConvertion";

import RightArrowIcon from "../../../../public/rightArrow.svg";
import s from "./Payment.css";
import {applyPaymentMethod} from "../../../actions/modalActions";
import { getBalanceUser } from '../../../actions/getBalanceUser';
import { formValueSelector } from "redux-form";
import { BASE_PAYMENT } from "../../../constants";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentType: 3,
      total: 0,
      platformFees: 0,
      initialValues: {},
      // Move from old render
      hostDisplayName: '',
    };
  }

  static propTypes = {
    listId: PropTypes.number.isRequired,
    hostId: PropTypes.string.isRequired,
    guestId: PropTypes.string.isRequired,
    guestEmail: PropTypes.string.isRequired,
    hostDisplayName: PropTypes.string.isRequired,
    hostPicture: PropTypes.string,
    coverPhoto: PropTypes.string,
    listTitle: PropTypes.string.isRequired,
    allowedPersonCapacity: PropTypes.number.isRequired,
    listType: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    houseRules: PropTypes.arrayOf(
      PropTypes.shape({
        listsettings: PropTypes.shape({
          itemName: PropTypes.string.isRequired,
        }),
      })
    ),
    checkIn: PropTypes.object.isRequired,
    checkOut: PropTypes.object.isRequired,
    guests: PropTypes.number.isRequired,
    basePrice: PropTypes.number.isRequired,
    cleaningPrice: PropTypes.number,
    currency: PropTypes.string.isRequired,
    weeklyDiscount: PropTypes.number,
    monthlyDiscount: PropTypes.number,
    listPhotos: PropTypes.array,
    serviceFees: PropTypes.shape({
      guest: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
      }).isRequired,
      host: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    base: PropTypes.string.isRequired,
    rates: PropTypes.object.isRequired,
    bookingType: PropTypes.string.isRequired,
    policyName: PropTypes.string.isRequired,
    formatMessage: PropTypes.any,
  };

  UNSAFE_componentWillMount() {
    this.props.getBalanceUser()
  }

  onChangeValueHandler = ({paymentType, dataForDetail}) => {
    console.log({
      debugPaymentOnChangeValueHandler: {
        paymentType: parseInt(paymentType),
        dataForDetail: dataForDetail,
        total: dataForDetail?.total,
        platformFees: dataForDetail?.platformFees
      }
    });
    this.setState({
      paymentType: parseInt(paymentType),
    });

    this.forceUpdate();
  }

  render() {
    const {paymentType} = this.state;
    console.log({debugStateRender: this.state});
    const {
      guestEmail,
      hostDisplayName,
      hostProfileId,
      hostPicture,
      coverPhoto,
      listPhotos,
      bookingType,
      policyName,
      policyContent,
      isPreApprove,
      total
    } = this.props;
    const {
      listId,
      listTitle,
      listType,
      city,
      state,
      country,
      allowedPersonCapacity,
    } = this.props;
    const { houseRules, hostId, guestId } = this.props;
    const { guests, checkIn, checkOut, guestPicture, taxRate, threadId } =
      this.props;
    const {
      basePrice,
      cleaningPrice,
      currency,
      weeklyDiscount,
      monthlyDiscount,
    } = this.props;
    const {
      serviceFees,
      base,
      rates,
      specialPricing,
      bookingData,
      stripePayment,
      spotWalletPayment,
      R3VWalletPayment,
      checkInForm,
      checkOutForm,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const paymentTypeDefault = (currency === 'R3V') ? 4 : paymentType;

    let guestServiceFee = 0, hostServiceFee = 0, priceForDays = 0, hostServiceFeeType = '', hostServiceFeeValue = 0;
    let discount = 0, discountType, totalPrice = 0, totalPriceR3v=0, totalPriceAED=0, bookingSpecialPricing = [],  totalWithoutServiceFee = 0, discountLessBasePrice = 0, VATFee = 0, extraCharges = 0, extraChargesR3v = 0;
    let totalWithoutFees = 0, momentStartDate, momentEndDate, dayDifference, isAverage = 0, currentDay, starRatingValue = 0;
    let  isSpecialPriceAssigned = false, isDayTotal = 0, taxRateFee = 0, taxPrice = 0 , totalWithoutTax = 0;

    if (checkIn != null && checkOut != null) {
      momentStartDate = moment(checkInForm || checkIn);
      momentEndDate = moment(checkOutForm || checkOut);
      dayDifference = momentEndDate?.diff(momentStartDate, "days");
      // priceForDays = Number(basePrice) * Number(dayDifference);

      //New
      if (dayDifference > 0) {
        let stayedNights = [],
          i,
          currentDate;
        // Find stayed nights
        for (i = 0; i < dayDifference; i++) {
          currentDate = moment(checkIn).add(i, "day");
          stayedNights?.push(currentDate);
        }

        if (stayedNights?.length > 0) {
          stayedNights?.map((item, key) => {
            let isSpecialPricing, pricingRow, currentPrice;
            if (item) {
              currentDay = moment(item).format("dddd").toLowerCase();
              // isSpecialPricing = specialPricing.find(o => moment(item).format('MM/DD/YYYY') == moment(o.blockedDates).format('MM/DD/YYYY'));
              isSpecialPricing = specialPricing?.find(
                (o) =>
                  moment(item).format("MM/DD/YYYY") ==
                  moment(o?.blockedDates).format("MM/DD/YYYY")
              );

              if (isSpecialPricing?.isSpecialPrice) {
                isSpecialPriceAssigned = true;
                currentPrice = Number(isSpecialPricing?.isSpecialPrice);
              } else {
                currentPrice = Number(basePrice);
              }
              // Price object
              pricingRow = {
                blockedDates: item,
                isSpecialPrice: currentPrice,
              };
              bookingSpecialPricing?.push(pricingRow);
            }
          });
        }
      }

      if (isSpecialPriceAssigned) {
        bookingSpecialPricing?.map((item, index) => {
          priceForDays = priceForDays + Number(item?.isSpecialPrice);
        });
      } else {
        bookingSpecialPricing?.map((item, index) => {
          priceForDays = priceForDays + Number(item?.isSpecialPrice);
        });
      }
    }

    isAverage = Number(priceForDays) / Number(dayDifference);
    isDayTotal = isAverage?.toFixed(2) * dayDifference;
    priceForDays = isDayTotal;

    if (dayDifference >= 7) {
      if (monthlyDiscount > 0 && dayDifference >= 28) {
        discount = (Number(priceForDays) * Number(monthlyDiscount)) / 100;
        discountType =
          monthlyDiscount + formatMessage(messages.monthlyPriceDiscount);
      } else {
        if (weeklyDiscount > 0) {
          discount = (Number(priceForDays) * Number(weeklyDiscount)) / 100;
          discountType =
            weeklyDiscount + formatMessage(messages.weeklyPriceDiscount);
        }
      }

      console.log({
        debugPaymentDiscount: {
          discount: discount,
          discountType: discountType,
          weeklyDiscount: weeklyDiscount,
          monthlyDiscount: monthlyDiscount,
          priceForDays: priceForDays
        }
      });
    }
    discountLessBasePrice = isDayTotal - discount;
    extraCharges = cleaningPrice + VATFee;
    taxRateFee =
      taxRate > 0
        ? (discountLessBasePrice + cleaningPrice) * (Number(taxRate) / 100)
        : 0;
    // totalWithoutServiceFee = (isDayTotal + cleaningPrice + taxRateFee) - discount;
    // totalWithoutServiceFee = isDayTotal + cleaningPrice - discount;
    totalWithoutServiceFee = isDayTotal + extraCharges - discount

    if (serviceFees) {
      if (serviceFees?.guest?.type === "percentage") {
        guestServiceFee =
          totalWithoutServiceFee * (Number(serviceFees?.guest?.value) / 100);
      } else {
        guestServiceFee = convert(
          base,
          rates,
          serviceFees?.guest?.value,
          serviceFees?.guest?.currency,
          currency
        );
      }

      if (serviceFees?.host?.type === "percentage") {
        hostServiceFeeType = serviceFees?.host?.type;
        hostServiceFeeValue = serviceFees?.host?.value;
        hostServiceFee =
          totalWithoutServiceFee * (Number(serviceFees?.host?.value) / 100);
      } else {
        hostServiceFeeType = serviceFees?.host?.type;
        hostServiceFeeValue = serviceFees?.host?.value;
        hostServiceFee = convert(
          base,
          rates,
          serviceFees?.host?.value,
          serviceFees?.host?.currency,
          currency
        );
      }
    }

    let checkInStart, checkInEnd, checkInDate, checkOutDate;

    checkInStart = bookingData?.listingData?.checkInStart;
    checkInEnd = bookingData?.listingData?.checkInEnd;

    // total = (priceForDays + guestServiceFee + cleaningPrice + taxRateFee) - discount;
    // totalWithoutFees = (priceForDays + cleaningPrice + taxRateFee) - discount;

    checkInDate =
      checkIn != null
        ? moment(moment(checkIn).format("YYYY-MM-DD")).format("ddd, Do MMM")
        : "";
    checkOutDate =
      checkOut != null
        ? moment(moment(checkOut).format("YYYY-MM-DD")).format("ddd, Do MMM")
        : "";

    if (bookingData?.reviewsCount > 0 && bookingData?.reviewsStarRating > 0) {
      starRatingValue = Math.round(
        bookingData?.reviewsStarRating / bookingData?.reviewsCount
      );
    }


    // totalWithoutFees = priceForDays + cleaningPrice - discount;
    totalWithoutFees = priceForDays + extraCharges - discount;
    let platformFeesPrice = guestServiceFee;
    let sr = 0;
    // Scenario 1
    if (currency == BASE_PAYMENT && (paymentTypeDefault == 3 || paymentTypeDefault == 2)) {
      // platformFeesPrice = 0.15 * totalWithoutFees;
      platformFeesPrice = 0.15 * discountLessBasePrice;
      totalPrice = totalWithoutFees + platformFeesPrice;
      sr = 1;
    } else {
      // Scenario 2
      if (currency == 'R3V' && paymentTypeDefault == 4) {
        // platformFeesPrice = 0.2075 * totalWithoutFees;
        platformFeesPrice = 0.15 * discountLessBasePrice;
        totalPrice = totalWithoutFees + platformFeesPrice;    
        sr = 2;
      } else {
        // Scenario 3
        if (currency == BASE_PAYMENT && paymentTypeDefault == 4) {
          // platformFeesPrice = 0.218 * totalWithoutFees;
          platformFeesPrice = 0.2075 * discountLessBasePrice;
          extraCharges = 1.0575 * extraCharges
          totalPrice = discountLessBasePrice + extraCharges + platformFeesPrice;  
          sr = 3;
        } else {
          // Scenario 4
          if (currency == 'R3V' && (paymentTypeDefault == 3 || paymentTypeDefault == 2)) {
            // platformFeesPrice = 0.2075 * totalWithoutFees;
            platformFeesPrice = 0.2075 * discountLessBasePrice;
            totalPrice = totalWithoutFees + platformFeesPrice;
            sr = 4;
          }
        }
      }
    }
    totalPriceR3v = 1.0575 * (cleaningPrice + VATFee) + 1.2075 * discountLessBasePrice;
    totalPriceAED = totalWithoutFees + 0.15 * discountLessBasePrice;
    console.log("totalPriceAED", totalPriceAED)


    // totalPrice = totalWithoutFees + platformFeesPrice;
    const dataForDetail = {total: totalPrice, totalPriceR3v, platformFees: platformFeesPrice, discountLessBasePrice, totalWithoutFees};
    console.log(dataForDetail, checkIn, checkOut, 'dataForDetaildataForDetail');
    let initialValues = {
      listId,
      listTitle,
      hostId,
      guestId,
      guests,
      checkIn,
      checkOut,
      basePrice,
      currency,
      cleaningPrice: extraCharges,
      discount,
      discountType,
      guestServiceFee: platformFeesPrice,
      hostServiceFee,
      total: totalWithoutFees,
      bookingType,
      paymentType: spotWalletPayment ? 3 : (R3VWalletPayment ? 4 : (stripePayment ? 2 : 1)),
      // paymentType: (paymentType == 3 || paymentType == 4) ? paymentType : (stripePayment ? "2" : "1"),
      guestEmail,
      isSpecialPriceAssigned,
      bookingSpecialPricing: JSON.stringify(bookingSpecialPricing),
      isSpecialPriceAverage: isAverage.toFixed(2),
      dayDifference,
      taxRate: taxRateFee,
      checkInStart,
      checkInEnd,
      hostServiceFeeType,
      hostServiceFeeValue,
      priceForDays,
      totalValue: totalPrice,
      threadId,
    };

    console.log({
      debugPaymentInitialValues: {
        initialValues: initialValues
      }
    });

    return (
      <Row>
        <Col lg={7} md={7}>
          <Elements>
            <PaymentForm
              hostDisplayName={hostDisplayName}
              houseRules={houseRules}
              allowedPersonCapacity={allowedPersonCapacity}
              initialValues={initialValues}
              listId={listId}
              guestPicture={guestPicture}
              hostPicture={hostPicture}
              hostProfileId={hostProfileId}
              isPreApprove={isPreApprove}
              spotWalletPayment={spotWalletPayment}
              R3VWalletPayment={R3VWalletPayment}
              dataForDetail={dataForDetail}
              currency={currency}
              paymentType={paymentTypeDefault}
              cleaningPrice={cleaningPrice}
              total={totalPrice}
              totalPriceR3v={totalPriceR3v}
              totalPriceAED={totalPriceAED}
              platformFees={platformFeesPrice}
              onChangePaymentTypeValue={this.onChangeValueHandler}
            />
          </Elements>
        </Col>
        <Col lg={5} md={5} className={cx(s.positionSticky, 'pl-[100px] pt-[72px]')}>
          <div className={cx(s.summaryCard, s.colCenter)}>
            <div className={s.postionRelative}>
              <div className={s.secondSection}>
                <div
                  className={cx(
                    s.displayInline,
                    s.avatarWidth,
                    s.vtrMiddle,
                    "avatarWidthPaymentRTL",
                    s.vtrTopSm
                  )}
                >
                  <ListCoverPhoto
                    className={cx(s.bannerImage, s.backgroundCover)}
                    coverPhoto={coverPhoto}
                    listPhotos={listPhotos}
                    photoType={"x_medium"}
                    bgImage
                  />
                </div>
                <div
                  className={cx(s.displayInline, s.contentWidth, s.vtrMiddle)}
                >
                  {starRatingValue > 0 && (
                    <div className={s.reviewFlex}>
                      <div className={s.reviewFlex}>
                        <span className={cx(s.starCss, "starCssRTL")}>
                          <StarRating name={"review"} value={starRatingValue} />
                        </span>
                        <span>{starRatingValue}</span>
                      </div>
                      <span className={s.dotsCss}>&#8226;</span>
                      <span>
                        {bookingData?.reviewsCount}{" "}
                        {bookingData?.reviewsCount > 1 ? (
                          <FormattedMessage {...messages.reviews} />
                        ) : (
                          <FormattedMessage {...messages.review} />
                        )}
                      </span>
                    </div>
                  )}
                  <div
                    className={cx(s.textLarge, s.colorWhite, s.propertyText)}
                  >
                    <span>{listTitle}</span>
                  </div>
                  <div
                    className={cx(
                      s.textMuted,
                      s.colorWhite,
                      s.listItem,
                      "textWhite"
                    )}
                  >
                    <ul className={cx(s.listStyle, "listStyleRTL")}>
                      <li>
                        {listType}
                        {bookingData?.beds > 0 && (
                          <span>
                            <span>&nbsp;-&nbsp;</span>
                            {bookingData?.beds}{" "}
                            {bookingData?.beds > 1
                              ? formatMessage(messages.beds)
                              : formatMessage(messages.bed)}
                          </span>
                        )}
                      </li>
                    </ul>
                    <div className={s.colorWhite}>
                      {" "}
                      {city}, {state}, {country}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <PaymentDetails
              totalPriceR3v={totalPriceR3v}
              totalPriceAED={totalPriceAED}
              basePrice={basePrice}
              cleaningPrice={extraCharges}
              currency={paymentTypeDefault == 4 ? "R3V" : "AED"}
              platformFeesPrice={platformFeesPrice}
            />
          </div>
          <div className={s.policyCss}>
            <span className={s.checkInText}>
              <FormattedMessage {...messages.cancellationPolicy} />:{" "}
            </span>
            <a
              href={"/cancellation-policies/" + policyName}
              target="_blank"
              className={s.policyColor}
            >
              {policyName}
            </a>
            <div className={cx(s.checkInText)}>{policyContent}</div>
          </div>
        </Col>
      </Row>
    );
  }
}

const selector = formValueSelector('PaymentForm');

const mapState = (state) => ({
  stripePayment: state?.book?.stripePayment,
  specialPricing: state?.viewListing?.specialPricing,
  checkInForm: selector(state, 'checkIn'),
	checkOutForm: selector(state, 'checkOut'),
});

const mapDispatch = {getBalanceUser};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(Payment))
);
