import React from 'react';
import PropTypes from 'prop-types';
// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ListingDetails.css';
import {
  Button,
  Row,
  Col,
  Collapse
} from 'react-bootstrap';
import cx from 'classnames';
import * as FontAwesome from 'react-icons/lib/fa';
// Translation
import { injectIntl, FormattedMessage } from 'react-intl';
// Locale
import messages from '../../../locale/messages';
// Redux
import { connect } from 'react-redux';
// Redux Action
import { getSpecificSettings } from '../../../actions/getSpecificSettings';
import { contactHostOpen } from '../../../actions/message/contactHostModal';
// Helper functions
import { checkIn, checkValue } from './helper';
// Internal Component
import ListItem from './ListItem';
import Link from '../../Link';
import Loader from '../../Loader';
import ListBedTypes from './ListBedTypes';

//image
import roomTypeIcon from '../../../../public/SiteIcons/listingRoomTypeIcon.svg';
import guestIcon from '../../../../public/SiteIcons/listingGuestIcon.svg';
import bedIcon from '../../../../public/SiteIcons/listingBedIcon.svg';
import bathIcon from '../../../../public/SiteIcons/listingBathIcon.svg';
import checkInIcon from '../../../../public/SiteIcons/listingCheckIcon.svg';
import rightArrow from '../../../../public/SiteIcons/viewReceiptRight.svg';
import totalRoom from '../../../../public/SiteIcons/totalRooms.svg';
import dotSpace from '../../../../public/SiteIcons/dotSpace.svg';
import StarRating from '../../StarRating/StarRating';
import lineHori from '../../../../public/SiteIcons/lineHori.svg';
import Avatar from '../../Avatar/Avatar';

class ListingDetails extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      listingData: PropTypes.shape({
        cancellation: PropTypes.shape({
          policyName: PropTypes.string.isRequired,
          policyContent: PropTypes.string.isRequired
        })
      })
    }),
    getSpecificSettings: PropTypes.any,
    settingsData: PropTypes.object,
    isHost: PropTypes.bool.isRequired,
    formatMessage: PropTypes.any,
    userBanStatus: PropTypes.number,
    urlParameters: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      guests: PropTypes.string,
      listTitle: PropTypes.string
    })
  };
  static defaultProps = {
    isHost: false,
    description: []
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoad: false,
    }
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoad: false });
  }

  UNSAFE_componentWillMount() {
    this.setState({ isLoad: true });
  }

  handleClick() {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { data } = this.props;
    const { formatMessage } = this.props.intl;
    const { open, isLoad } = this.state;

    let checkInStart, checkInEnd, roomType, buildingSize;
    let userAmenities = [], userSafetyAmenities = [];
    let sharedSpaces = [], houseRules = [], listBedTypes = [];
    let description, personCapacity, bathrooms, bedrooms, bedCounts = 0;
    let count = 200, firstArray, restArray, dotString = false;
    let isAmenities, isSharedSpaces, bedResult = [];

    if (data.listingData != undefined) {
      checkInStart = checkValue(data.listingData.checkInStart, '');
      checkInEnd = checkValue(data.listingData.checkInEnd, '');
    }
    if (data.settingsData != undefined && data.settingsData.length > 0) {
      roomType = checkValue(data.settingsData && data.settingsData[0] && data.settingsData[0].listsettings && data.settingsData[0].listsettings.itemName, '');
      buildingSize = checkValue(data.settingsData && data.settingsData[2] && data.settingsData[2].listsettings && data.settingsData[2].listsettings.itemName, '');
    }
    sharedSpaces = checkValue(data.userSpaces, []);
    houseRules = checkValue(data.houseRules, []);
    userAmenities = checkValue(data.userAmenities, []);
    userSafetyAmenities = checkValue(data.userSafetyAmenities, []);
    description = checkValue(data.description, '');
    personCapacity = checkValue(data.personCapacity, 0);
    bathrooms = checkValue(data.bathrooms, 0);
    bedrooms = checkValue(data.bedrooms, 0);
    listBedTypes = checkValue(data.listBedTypes, []);
    let starRatingValue = 0;
    const { reviewsCount, reviewsStarRating } = data
    if (reviewsCount > 0 && reviewsStarRating > 0) {
      starRatingValue = Math.round(reviewsStarRating / reviewsCount)
    }
    if (listBedTypes && listBedTypes.length > 0) {
      bedResult = listBedTypes.reduce((acc, o) => (acc[o.listsettings && o.listsettings.itemName] = (acc[o.listsettings && o.listsettings.itemName] || 0) + 1, acc), {});
      bedCounts = Object.values(bedResult);
      bedCounts = bedCounts.reduce((a, b) => a + b, 0)
    }

    if (description) {
      firstArray = description.slice(0, count);
      restArray = description.slice(count, description.length);
    }
    if (restArray && restArray.length > 0) {
      dotString = true;
    }

    userAmenities.map((item) => {
      if (item.listsettings.isEnable == '1') {
        isAmenities = true;
      }
    });

    const allAme = userAmenities.concat(userSafetyAmenities, sharedSpaces)

    sharedSpaces.map((item) => {
      if (item.listsettings.isEnable == '1') {
        isSharedSpaces = true
      }
    });

    return (
      <div className={cx(s.pageContent, 'listingDetail')}>
        <div className={cx('flex mt-[8px] mb-[12px]', s.smallText)}>
          <div>{personCapacity} {personCapacity > 1 ? <FormattedMessage {...messages.guests} /> : <FormattedMessage {...messages.guest} />}</div>
          <img src={dotSpace} className='px-2' />
          <div>{bedrooms} {bedrooms > 1 ? <FormattedMessage {...messages.bedrooms} /> : <FormattedMessage {...messages.bedroom} />}</div>
          <img src={dotSpace} className='px-2' />
          <div>{bathrooms} {bathrooms > 1 ? <FormattedMessage {...messages.bathrooms} /> : <FormattedMessage {...messages.bathroom} />}</div>
        </div>
        <div>
          <div className='flex items-center text-[16px]'>
            {starRatingValue > 0 && <><span><StarRating name={'review'} value={starRatingValue} /></span>
              <span className={cx(s.countGap, 'countGapRTL')}>{starRatingValue}</span></>}
            <img src={lineHori} className='px-2' />
            <div>{reviewsCount} rating(s)</div>
          </div>
        </div>
        <div className={cx('flex items-center mt-[24px]', s.smallText)}>
          <FormattedMessage {...messages.hostedBy} />   <Avatar
            source={data.user.profile.picture}
            type={"small"}
            height={32}
            width={32}
            title={data.user.profile.firstName}
            className={s.profileAvatar}
            withLink
            linkClassName={s.profileAvatarLink}
            profileId={data.user.profile.profileId}
          /> {' '}  <span className='font-medium color-[#E5E6EB]'>{data.user.profile.firstName}</span>
        </div>
        <div className={s.listingLine}></div>
        <div className={cx(s.horizontalLineThrough, 'mb-[39px]')}>
          <div>
            <p className={cx(s.listingFontSize, s.smallText)} >
              {!this.state.open && count >= 200 &&
                <span className={cx(s.subText, s.lineBreak)}>  {firstArray} {dotString === true && <span>...</span>}</span>
              }
              {
                restArray && restArray.length > 0 &&
                <span>
                  <Collapse in={open}>
                    <div> <span className={cx(s.subText, s.lineBreak)}>
                      {this.state.open && firstArray} {restArray}
                    </span></div>
                  </Collapse>
                  {
                    dotString && <div className={s.btnContainer}>
                      <div className={s.showHidePadding}>
                        <Button
                          bsStyle="link"
                          className={cx(s.button, s.noPadding, s.btnLInk, s.buttonShowmore, 'bgTransparent no-underline flex mt-2')}
                          onClick={() => this.handleClick()}
                        >
                          {this.state.open ? <FormattedMessage {...messages.closeAll} /> : <FormattedMessage {...messages.showDescription} />}

                          {
                            this.state.open && <FontAwesome.FaAngleUp className={s.navigationIcon} />
                          }
                          {
                            !this.state.open && <FontAwesome.FaAngleDown className={s.navigationIcon} />
                          }
                        </Button>
                      </div>
                    </div>
                  }
                </span>
              }
            </p>
          </div>
        </div>
        {
          userAmenities && userAmenities.length > 0 && isAmenities && <div> <ListItem
            itemList={allAme}
            label={formatMessage(messages.aminities)}
            showLabel={formatMessage(messages.showAmenities)}
            hideLabel={formatMessage(messages.closeAmenities)}
            icon={true}
          />
            <div className={s.listingLine}></div>
          </div>
        }

        {/* {
          houseRules.length > 0 && <div> <ListItem
            itemList={houseRules}
            label={formatMessage(messages.houseRules)}
            showLabel={formatMessage(messages.showAllHouseRules)}
            hideLabel={formatMessage(messages.closeHouseRules)}
            icon={false}
            houseRulesIcon={true}
          />
            <div className={s.listingLine}></div>
          </div>
        } */}

        {/* {
          data && data.listingData && data.listingData.cancellation && <div className={cx(s.horizontalLineThrough)}>
            <div className={cx(s.space1)}>
              <p className={s.sectionTitleText}><FormattedMessage {...messages.cancellations} /></p>
            </div>
            <div className={cx(s.spaceTop1)}>
              <p className={s.listingFontSize}>
                <span className={cx(s.text)}><strong>{data.listingData.cancellation.policyName}</strong></span>
              </p>
              <p className={s.listingFontSize}>
                <span className={cx(s.text)}>{data.listingData.cancellation.policyContent}</span>
              </p>
              <div className={cx(s.listingFontSize, s.showHideMargin)}>
                <Link
                  to={"/cancellation-policies/" + data.listingData.cancellation.policyName}
                  className={cx(s.sectionCaptionLink, s.viewFlex)}
                >
                  <FormattedMessage {...messages.viewDetails} /> <img src={rightArrow} className={'detailsArrowRTL'} />
                </Link>
              </div>
            </div>
            <div className={s.listingLine}></div>
          </div>
        } */}
      </div>
    );
  }
}
const mapState = (state) => ({
  settingsData: state.viewListing.settingsData,
});
const mapDispatch = {
  getSpecificSettings,
  contactHostOpen
};
export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ListingDetails)));