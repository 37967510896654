import { gql, graphql } from 'react-apollo';

import {
  GET_BALANCE_USER_START,
  GET_BALANCE_USER_SUCCESS,
  GET_BALANCE_USER_ERROR
} from '../constants';

import { initialize } from 'redux-form';

const query = gql`
   {
    getBalanceByUser {
      id,
      user_id,
      currency_id,
      balance,
      is_active,
      air_balance,
      staked_air_balance,
      avg_token_balance
    }
  }
`;

export function getBalanceUser() {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: GET_BALANCE_USER_START,
    });

    try {
      const {data} = await client.query({ query, fetchPolicy: 'network-only' });
      if(data && data?.getBalanceByUser) {
        // Dispatch a success action
        dispatch({
          type: GET_BALANCE_USER_SUCCESS,
          updatedBalanceData: data?.getBalanceByUser
        });
      }
    

    } catch (error) {
      dispatch({
        type: GET_BALANCE_USER_ERROR,
        payload: {
          error
        }
      });
      return false;
    }

    return true;
  };
}
