import React from 'react';
import Layout from '../../components/Layout';
import messages from '../../locale/messages';
import NotFound from './NotFound';

const title = 'Page Not Found';

export default function action({ intl }) {
  const title = intl?.formatMessage(messages.pageNotFoundTab);
  return {
    title,
    component:
      <Layout>
        <NotFound title={title} />,
      </Layout>,
    status: 404,
  };
}