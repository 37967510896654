import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Col, FormControl, FormGroup, Grid, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import history from "../../core/history";
import messages from "../../locale/messages";
import bt from '../../components/commonStyle.css';
import cx from 'classnames';
import s from "./StakingRequest.css";
import clsx from "clsx"
import { isMobile } from 'react-device-detect';
class StakingMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: '/new-time-boxing'

    }
  }
  handleSelect(key) {
    history.push(key)
  }
  componentDidMount() {
    if (history.location) {
      this.setState({
        location: history.location.pathname
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (history.location) {
      this.setState({
        location: history.location.pathname
      });
    }
  }
  render() {
  
    if(isMobile)
    return (
      <div>  
      <div className="text-3xl font-medium whitespace-nowrap mb-[30px]">
        Time-Boxing
      </div>
    
     <div className={clsx("overflow-x-auto scrollbar-hidden",s.mobileMenu)}>
      <div className="flex justify-between border-b-[2px] border-[#606570] !w-fit">
        <div className={clsx("text-[14px] font-medium whitespace-nowrap pb-2",
        {'font-semibold border-b-[3px] border-[#E5E6EB]':this.state.location==='/new-time-boxing'}
        )}
        onClick={()=>history.push('/new-time-boxing')}
        ><FormattedMessage {...messages.myTimeBox} /></div>
        <div className={clsx("text-[14px] font-medium whitespace-nowrap ml-[21px] pb-2",
          {'font-semibold border-b-[3px] border-[#E5E6EB]':this.state.location==='/time-boxing-history'}
        )}
        onClick={()=>history.push('/time-boxing-history')}
        ><FormattedMessage {...messages.timeBoxHistory} /></div>
        <div className={clsx("text-[14px] font-medium whitespace-nowrap ml-[21px] pb-2",
         {'font-semibold border-b-[3px] border-[#E5E6EB]':this.state.location==='/time-boxing-pool-data'}
        )}
        onClick={()=>history.push('/time-boxing-pool-data')}
        ><FormattedMessage {...messages.timeBoxData} /></div>
      </div>
    </div>
    
  </div>
  )
  else
    return (
      <div>  
          <div className="text-3xl font-medium whitespace-nowrap mb-[30px]">
            Time-Boxing
          </div>
        
        <div>
       { <Panel className={cx('bgBlack border-none')}>
        <div className={cx('listingTab', 'listingMobileTab', 'tabReservation')}>
          <Tabs activeKey={this.state.location} id="controlled-tab-example" onSelect={(e) => this.handleSelect(e)}>
            <Tab eventKey={'/new-time-boxing'} title={<FormattedMessage {...messages.myTimeBox} />} />
            <Tab eventKey={'/time-boxing-history'} title={<FormattedMessage {...messages.timeBoxHistory} />} />
            <Tab eventKey={'/time-boxing-pool-data'} title={<FormattedMessage {...messages.timeBoxData} />} />
          </Tabs>
        </div>
      </Panel>}
    </div>
  
      </div>)
  }

}

export default (injectIntl, withStyles(bt))(StakingMenu);