import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DropzoneComponent from "react-dropzone-component";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { toastr } from "react-redux-toastr";
import s from "!isomorphic-style-loader!css-loader!./filepicker.css";
import { FormattedMessage, injectIntl } from "react-intl";

import PhotosList from "../PhotosList";

import dropzoneErrors from "../../helpers/dropzoneErrors";
import { getSpecificConfig } from "../../helpers/getConfigValue";
import {
  createListPhotos,
  removeListPhotos,
} from "../../actions/manageListPhotos";
import messages from "../../locale/messages";

import PictureImage from "../../../public/SiteIcons/photoUpload2.svg";

class PhotosUpload extends Component {
  static propTypes = {
    createListPhotos: PropTypes.any.isRequired,
    removeListPhotos: PropTypes.any.isRequired,
    listId: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.success = this.success.bind(this);
    this.complete = this.complete.bind(this);
    this.dropzone = null;
    this.error = this.error.bind(this);
    this.state = {
      djsConfig: {},
      maxSize: 10,
    };
  }

  async componentDidMount() {
    const { placeholder } = this.props;
    const isBrowser = typeof window !== "undefined";
    const isDocument = typeof document !== undefined;
    const siteData = await getSpecificConfig({ name: ["maxUploadSize"] });
    this.setState({
      maxSize: siteData && siteData.maxUploadSize,
    });
    if (isBrowser && isDocument) {
      document.querySelector(".dz-hidden-input").style.visibility = "visible";
      document.querySelector(".dz-hidden-input").style.opacity = "0";
      document.querySelector(".dz-hidden-input").style.height = "100%";
      document.querySelector(".dz-hidden-input").style.width = "100%";
      document.querySelector(".dz-hidden-input").style.cursor = "pointer";
    }

    if (placeholder) {
      this.setState({
        djsConfig: {
          dictDefaultMessage: placeholder,
          addRemoveLinks: false,
          maxFilesize: this.state.maxSize,
          maxFiles: 20,
          acceptedFiles: "image/jpeg,image/png",
          hiddenInputContainer: ".dzInputContainer",
          // dictFileTooBig: '',
        },
      });
    }
  }

  UNSAFE_componentWillMount() {
    const { placeholder } = this.props;

    if (placeholder) {
      this.setState({
        djsConfig: {
          dictDefaultMessage: placeholder,
          addRemoveLinks: false,
          maxFilesize: this.state.maxSize,
          maxFiles: 20,
          acceptedFiles: "image/jpeg,image/png",
          hiddenInputContainer: ".dzInputContainer",
          // dictFileTooBig: '',
        },
      });
    }
  }

  success(file, fromServer) {
    /*const { listId, createListPhotos } = this.props;
    const { files } = fromServer;
    let fileName = files[0].filename;
    let fileType = files[0].mimetype;
    // Calling Redux action to create a record for uploaded file
    if(listId != undefined) {
      createListPhotos(listId, fileName, fileType);
    }*/
  }

  async error(file) {
    const { maxSize } = this.state;
    dropzoneErrors(file, maxSize);
  }

  complete(file) {
    const { listId, createListPhotos } = this.props;
    if (file && file.xhr) {
      const { files } = JSON.parse(file.xhr.response);
      let fileName = files[0].filename;
      let fileType = files[0].mimetype;
      if (listId != undefined) {
        createListPhotos(listId, fileName, fileType);
      }
      this.dropzone.removeFile(file);
    }
  }

  render() {
    const { placeholder, listId } = this.props;
    const { djsConfig } = this.state;
    const componentConfig = {
      iconFiletypes: [".jpg", ".png"],
      //showFiletypeIcon: true,
      postUrl: "/photos",
    };
    const eventHandlers = {
      init: (dz) => (this.dropzone = dz),
      success: this.success,
      complete: this.complete,
      error: this.error,
    };

    return (
      <div className={cx("listPhotoContainer")}>
        <div className={cx("dzInputContainer", "svgImg")}>
          <DropzoneComponent
            config={componentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          >
            <img
              src={PictureImage}
              className={"photoUploadImg"}
              alt="PictureImage"
            />
          </DropzoneComponent>
        </div>
        {/* <div className={"uploadSizeCss"}>
          <FormattedMessage {...messages.uploadSizedLabel} />{" "}
          {this.state.maxSize}MB
        </div> */}
        <PhotosList listId={listId} />
      </div>
    );
  }
}

const mapState = (state) => ({});

const mapDispatch = {
  createListPhotos,
  removeListPhotos,
};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(PhotosUpload))
);
