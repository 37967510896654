import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './NewStaking.css';
import { Col, FormControl, FormGroup, Grid, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import cx from 'classnames'
import { FormattedMessage } from 'react-intl';
import messages from '../../locale/messages';
import history from '../../core/history';
import StakingMenu from '../../components/Staking/StakingMenu';
import { Table, Tr, Td, Thead, Th } from 'reactable';
import { compose, gql, graphql } from 'react-apollo';
import moment from 'moment';
import { STAKING_TYPE } from '../../constants';

class StakingHistory extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };


  render() {
    const { getStakingHistory } = this.props
    return (
      <Grid fluid className='dashBoardContainer'>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={cx('')}>
              <StakingMenu />
              <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin mt-[32px]')}>
                <Table className="table table-custom timeboxing-history"
                  noDataText={'No Record'}
                >
                  <Thead>
                    <Th scope="col">Date</Th>
                    <Th scope="col">Amount(R3V)</Th>
                    <Th scope="col">Action</Th>
                  </Thead>
                  {
                    getStakingHistory?.getStakingHistory
                    && getStakingHistory?.getStakingHistory?.length > 0
                    && getStakingHistory?.getStakingHistory?.filter(el => el.action == STAKING_TYPE.ACTION_PENDING_WITHDRAW
                      || el.action == STAKING_TYPE.ACTION_STAKE
                      || el.action == STAKING_TYPE.ACTION_WITHDRAW
                      || el.action == STAKING_TYPE.ACTION_CANCEL_PENDING_WITHDRAW).map((value, key) => {
                        return (
                          <Tr key={key}>
                            <Td column='Date' >
                              <div className='flex justify-center'>
                                <div className='text-left w-fit'>
                                  <div className='text-base'>{moment(value.created_at).format('MMMM Do YYYY')}</div>
                                  <div className='text-base text-[var(--common-text-Secondry-color)]'>{moment(value.created_at).format('h:mm:ss')}</div>
                                </div>
                              </div>
                            </Td>
                            <Td data-label='Amount(R3V)' column='Amount(R3V)'  >{value.amount}</Td>
                            <Td data-label='Action' column='Action'  >{value.action_name}</Td>
                          </Tr>
                        )
                      })
                  }
                </Table>
              </div>
            </div>
          </Col>
        </Row>

      </Grid>
    );
  }
}


export default compose(withStyles(s), graphql(gql`
query {
  getStakingHistory{
    action
    userId
    action_name
    pool_id
    amount
    created_at
  }
}

`, {
  name: 'getStakingHistory',
  options: {
    ssr: false
  }
}))(StakingHistory);

