import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Withdraw.css';
import { Button, Col, FormControl, FormGroup, Grid, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import cx from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../locale/messages';
import history from '../../core/history';
import BalanceMenu from '../../components/Balance/BalanceMenu';
import FooterButton from '../../components/ListPlaceStep1/FooterButton';
import { Field } from 'redux-form';
import bt from '../../components/commonStyle.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { SHA3 } from 'crypto-js';
import Loader from '../../components/Loader/Loader';
import ButtonLoader from '../../components/Loader/ButtonLoader';

var isAddress = function (address) {
  if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
      // check if it has the basic requirements of an address
      return false;
  } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
      // If it's all small caps or all all caps, return true
      return true;
  } else {
      // Otherwise check each case
      return isChecksumAddress(address);
  }
};

/**
* Checks if the given string is a checksummed address
*
* @method isChecksumAddress
* @param {String} address the given HEX adress
* @return {Boolean}
*/
var isChecksumAddress = function (address) {
  // Check each case
  address = address.replace('0x','');
  var addressHash = SHA3(address.toLowerCase());
  for (var i = 0; i < 40; i++ ) {
      // the nth letter should be uppercase if the nth digit of casemap is 1
      if ((parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) || (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])) {
          return false;
      }
  }
  return true;
};
class Withdraw extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      address: '',
      transaction: '',
      error: null,
      isLoading: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  async handleSubmit(event) {
    event.preventDefault();
    const { formatMessage } = this.props.intl;
    const { error, amount, address } = this.state
    if (!amount || !address) {
      this.setState({
        error: {
          amount: amount ? '' : formatMessage(messages.required),
          address: address ? '' : formatMessage(messages.required)
        }
      })
      return
    }

    if (error?.amount || error?.address) {
      return
    }
    this.setState({ isLoading: true });
    const { data } = await axios.post('https://api.testrentals.co.in/token/withdraw', {
      user_id: this.props.userData.userId,
      user_address: this.state.address,
      amount: Number(this.state.amount)
    })
    if (data?.data?.hash) {
      this.setState({
        transaction: data?.data?.hash
      })
    }
    this.setState({ isLoading: false });
    toastr.success("Withdraw Success!", "Transaction can take up to 1 minutes to update your balance");
  }

  handleChange(event) {
    var input = event.target.value;
    this.setState({
      address: input,
    });
    if (!isAddress(input)) {
      this.setState({
        error: {
          address: 'Address invalid!'
        }
      });
    } else {
      this.setState({
        error: {
          address: null
        }
      });
    }
  }

  handleChangeAmount(event) {
    var input = event.target.value;
    this.setState({
      amount: input,
     
    });
    if (input <= 0 || input > this.props.balance?.data?.air_balance) {
      this.setState({
        error: {
          amount: 'Amount invalid!'
        }
      });
    } else {
      this.setState({
        error: {
          amount: null
        }
      });
    }
   
  }

  render() {
    const { transaction, error, isLoading } = this.state
    const { data: dataBalance } = this.props.balance
    return (
      <Grid fluid className='dashBoardContainer'>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={cx('withdrawContainer')}>
              <BalanceMenu />
              <form onSubmit={this.handleSubmit} className='w-[400px] mt-[40px]'>
                <div>
                  <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                    Address
                  </label>
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="text"
                      name="address"
                      id="address"
                      className={cx("block w-full rounded-md py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6", s.inputText)}
                      value={this.state.address} onChange={this.handleChange}
                    />
                    {error?.address && <span className={bt.errorMessage}>{error.address}</span>}
                  </div>
                </div>
                <div className='mb-6 mt-4'>
                  <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                    Amount (My balances: {dataBalance?.air_balance || 0} R3V)
                  </label>
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="number"
                      name="amount"
                      id="amount"
                      className={cx("block w-full rounded-md py-1.5 pr-[45px] px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6", s.inputText)}
                      value={this.state.amount} onChange={this.handleChangeAmount}
                    />
                    <div className="absolute inset-y-0 right-2 flex items-center">
                      R3V
                    </div>
                  </div>
                  {error?.amount && <span className={bt.errorMessage}>{error.amount}</span>}
                </div>
                {transaction && <div className='text-green-600 my-3'>Transaction hash: <a href={`https://testnet.bscscan.com/tx/${transaction}`} target='_blank'>{transaction}</a></div>}
                <ButtonLoader className={cx(bt.btnPrimary, bt.btnLarge, 'mt-7')} label={'Withdraw'} show={isLoading} type="submit">
                </ButtonLoader>
           
              </form>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapState = (state) => ({
  userData: state.account.data,
  balance: state?.balance
});

const mapDispatch = {
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(Withdraw)));
