// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux Form
import { reduxForm, formValueSelector } from 'redux-form';

// Redux
import { connect } from 'react-redux';

// Translation
import { injectIntl, FormattedMessage } from 'react-intl';

// Locale
import messages from '../../locale/messages';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
  Grid,
  Button,
  Row,
  FormGroup,
  Col,
} from 'react-bootstrap';
import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';

// Component
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';

// Helpers
import validateStep3 from './validateStep3';
import history from '../../core/history';
import { LinearButton } from '../Button/GrayButton';

class LocalLaws extends Component {

  static propTypes = {
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
    minNightData: PropTypes.number,
    maxNightData: PropTypes.number,
    siteName: PropTypes.string.isRequired,
    listingSteps: PropTypes.shape({
      step1: PropTypes.string.isRequired,
      step2: PropTypes.string.isRequired,
      step3: PropTypes.string.isRequired,
      guestDisplayName: PropTypes.string,
      listing: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isReady: PropTypes.bool.isRequired,
        isPublished: PropTypes.bool.isRequired
      }),
      user: PropTypes.shape({
        userBanStatus: PropTypes.number,
      }),
      userData: PropTypes.shape({
        firstName: PropTypes.string.isRequired
      }).isRequired
    }),
  };

  static defaultProps = {
    minNightData: 0,
    maxNightData: 0,
    userData: {
      firstName: ''
    }
  }

  render() {
    const { error, handleSubmit, submitting, dispatch, nextPage, previousPage, formErrors, minNightData, maxNightData, step, formPage, userData } = this.props;
    const { siteName } = this.props;
    const { formatMessage } = this.props.intl;
    let isDisabled = false;
    if (formErrors != undefined && formErrors.hasOwnProperty('syncErrors')) {
      isDisabled = true;
    }

    if (maxNightData > 0) {
      if (minNightData > maxNightData) {
        isDisabled = true;
      }
    }
    return (
      <div className={'flex justify-center items-center mt-[120px]'}>
        {/* <SidePanel
          title={formatMessage(messages.stepThreeCommonHeading)}
          landingContent={formatMessage(messages.tabLocalLaws)}
        /> */}
        
        <form onSubmit={handleSubmit}>
          <div className={cx(s.landingMainContent, 'px-[144px]', s.congratContainer)}>
            <div className='flex pt-[100px] text-center'>
              <div className='mx-auto px-[100px]'>
                <div className=' text-[32px] font-bold'>
                  Congratulations, {userData?.firstName}
                </div>
                <div className={s.congratStroke}></div>
                <div className='text-[#B1B3B8]  text-lg font-medium mt-[32px]'>
                  Welcome aboard.<br /> Thank you for sharing your home and helping to create incredible experiences for our guests.
                </div>
                <LinearButton containerClassname="ml-auto mt-[48px]" onClick={() => history.push('/rooms')}>
                  <div>Let’s get started</div>
                </LinearButton>
              </div>
            </div>
          </div>
          {/* <FooterButton
            isDisabled={isDisabled}
            previousPage={previousPage}
            previousPagePath={"local-laws"}
            type="homepage"
            formPage={formPage}
            step={step}
          /> */}
        </form>
      </div>
    );
  }
}

// Decorate with connect to read form values
const selector = formValueSelector('ListPlaceStep3'); // <-- same as form name

LocalLaws = reduxForm({
  form: 'ListPlaceStep3', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateStep3
})(LocalLaws);

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
  listingFields: state.listingFields.data,
  formErrors: state.form.ListPlaceStep3,
  minNightData: selector(state, 'minNight'),
  maxNightData: selector(state, 'maxNight'),
  userData: state.account.data,
});

const mapDispatch = {};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(LocalLaws)));
