import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './MyBalance.css';
import { Col, FormControl, FormGroup, Grid, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import cx from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../locale/messages';
import history from '../../core/history';
import BalanceMenu from '../../components/Balance/BalanceMenu';
import { connect } from 'react-redux';
import { getBalanceUser } from '../../actions/getBalanceUser';
import StakingRequestModal from '../../components/Staking/StakingRequestModal';
import WithdrawUsdModal from './withdrawUsdModal';
import { compose, gql, graphql } from 'react-apollo';
import { Table, Tr, Td, Thead, Th } from 'reactable';
import bt from '../../components/commonStyle.css';
import moment from 'moment';

class MyBalance extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  getBalance = async () => {
    const getPaymentMethods = await client.query({
      query: getPaymentMethodsQuery,
      fetchPolicy: 'network-only',
    });
  }

  componentDidMount() {
    this.props.getBalanceUser()
  }
  render() {
    const { data: dataBalance } = this.props.balance
    const { getBalanceUsdHistory } = this.props
    return (
      <Grid fluid className='dashBoardContainer'>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>

            <div className={cx('')}>
              <BalanceMenu />
              {/* <div class="col-12 grid grid-rows-1 grid-flow-col gap-2 mt-5 px-0">
                <div class=" bg-[#323773] px-[24px] py-[32px] mt-3 rounded-lg ">
                  <h3 class="text-[14px] text-white font-weight-600">My USD Balance
                    <span class="block w-fit text-white text-[24px] mt-[8px] "> 	{dataBalance?.balance || 0} USD </span>
                  </h3>
                </div>
                <div class="  bg-[#175CD3] px-[24px] py-[32px] mt-3 rounded-lg ">
                  <h3 class="text-[14px] text-white font-weight-600">My R3V Balance:
                    <span class="block w-fit text-white text-[24px] mt-[8px] "> {dataBalance?.air_balance || 0} R3V </span>
                  </h3>
                </div>
                <div class=" bg-[#152B64] px-[24px] py-[32px] mt-3 rounded-lg ">
						<h3 class="text-[14px] text-white font-weight-600">Staked R3V Token Balance:
						    <span class="block w-fit text-white text-[24px] mt-[8px] ">{dataBalance.staked_air_balance } R3V </span>
						</h3>
					</div>
                <div class="  bg-[#6992DB] px-[24px] py-[32px] mt-3 rounded-lg ">
                  <h3 class="text-[14px] text-white font-weight-600">Average R3V Token Balance in 30 days :
                    <span class="block w-fit text-white text-[24px] mt-[8px] "> {dataBalance?.avg_token_balance || 0} R3V </span>
                  </h3>
                </div>
              </div> */}

              <div className='flex justify-between mb-5 mt-8'>
                <div className='font-semibold text-lg pt-[5px]'></div>
                <WithdrawUsdModal refetch={getBalanceUsdHistory?.refetch} />
              </div>
              <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
                <Table className="table table-custom"
                  noDataText={'No Record'}
                >
                  <Thead>
                    <Th scope="col">Date</Th>
                    <Th scope="col">Withdraw Amount</Th>
                    <Th scope="col">Remaining Amount</Th>

                  </Thead>
                  {
                    getBalanceUsdHistory && getBalanceUsdHistory?.getBalanceUsdHistory?.length > 0 && getBalanceUsdHistory?.getBalanceUsdHistory.map((value, key) => {
                      return (
                        <Tr key={key}>
                          <Td column='Date' >
                            <div className='flex justify-center'>
                              <div className='text-left w-fit'>
                                <div className='text-base'>{moment(value.created_at).format('MMMM Do YYYY')}</div>
                                <div className='text-base text-[var(--common-text-Secondry-color)]'>{moment(value.created_at).format('h:mm:ss')}</div>
                              </div>
                            </div>
                          </Td>
                          <Td data-label='Withdraw Amount' column='Withdraw Amount' data={value?.withdraw_amount} />
                          <Td data-label='Remaining Amount' column='Remaining Amount' data={value?.remaining_amount} />
                        </Tr>
                      )
                    })
                  }
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapState = (state) => ({
  account: state?.account?.data,
  balance: state?.balance
});

const mapDispatch = {
  getBalanceUser
};

export default compose(withStyles(s, bt), graphql(gql`
query {
  getBalanceUsdHistory{
    withdraw_amount,
    remaining_amount,
    created_at
  }
}

`, {
  name: 'getBalanceUsdHistory',
  options: {
    ssr: false
  }
}), connect(mapState, mapDispatch))(MyBalance);

