exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Deposit-depositContainer-3ENw_ {\n    color: var(--common-text-Secondry-color);\n    color: var(--common-text-Secondry-color);\n}", ""]);

// exports
exports.locals = {
	"depositContainer": "Deposit-depositContainer-3ENw_"
};