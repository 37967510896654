import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

//Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./SideMenuTrips.css";
import cx from "classnames";

import { Col } from "react-bootstrap";

// Locale
import messages from "../../../locale/messages";

// Component
import Link from "../../Link";
import history from "../../../core/history";

class SideMenuTrips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
    };
  }

  handleClick() {
    history.push("/become-a-host");
  }

  handleSelect = async () => {
    const { handleClick } = this.props;
    await handleClick();
  };

  componentDidMount() {
    if (history.location) {
      this.setState({
        location: history.location.pathname,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (history.location) {
      this.setState({
        location: history.location.pathname,
      });
    }
  }

  render() {
    const { location } = this.state;
    const { menuItemOne, menuItemTwo, linkOne, linkTwo } = this.props;
    return (
      <div>
        <ul className={"flex border-b border-border-secondary px-2 mb-10"}>
          <li
            className={cx(
              "whitespace-nowrap border-b-[3px] py-4 px-1 text-sm font-medium flex justify-center cursor-pointer",
              {
                "border-slate-900 text-gray-500": location === linkOne,
                "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700":
                  location !== linkOne,
              }
            )}
            onClick={() => this.handleSelect()}
          >
            <Link to={linkOne} className={cx("")}>
              {menuItemOne}
            </Link>
          </li>
          <li
            className={cx(
              "whitespace-nowrap border-b-[3px] py-4 px-1 text-sm font-medium flex justify-center cursor-pointer",
              {
                "border-slate-900 text-gray-500": location === linkTwo,
                "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700":
                  location !== linkTwo,
              }
            )}
            onClick={() => this.handleSelect()}
          >
            <Link to={linkTwo} className={cx("")}>
              {menuItemTwo}
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default withStyles(s)(SideMenuTrips);
