import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./AccountSettingsSideMenu.css";
import cx from "classnames";

// Component
import Link from "../Link";

// Locale
import messages from "../../locale/messages";
import history from "../../core/history";
class AccountSettingsSideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
    };
  }

  componentDidMount() {
    if (history.location) {
      this.setState({
        location: history.location.pathname,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (history.location) {
      this.setState({
        location: history.location.pathname,
      });
    }
  }

  render() {
    const { location } = this.state;

    return (
      <ul className="flex border-b border-border-secondary px-2 mb-10">
        <li
          className={cx(
            "whitespace-nowrap border-b-[3px] px-5 py-3 text-sm font-medium flex justify-center cursor-pointer",
            {
              "border-slate-900 text-gray-500":
                location === "/user/payout" || location === "/user/addpayout",
              "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700":
                !(
                  location === "/user/payout" || location === "/user/addpayout"
                ),
            }
          )}
        >
          <Link to={"/user/payout"} className="hover:no-underline text-inherit">
            <FormattedMessage {...messages.payoutPreferences} />
          </Link>
        </li>
        <li
          className={cx(
            "whitespace-nowrap border-b-[3px] px-5 py-3 text-sm font-medium flex justify-center cursor-pointer",
            {
              "border-slate-900 text-gray-500":
                location.startsWith("/user/transaction"),
              "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700":
                !location.startsWith("/user/transaction"),
            }
          )}
        >
          <Link
            to={"/user/transaction/host"}
            className="hover:no-underline text-inherit"
          >
            <FormattedMessage {...messages.transactionHistory} />
          </Link>
        </li>
        <li
          className={cx(
            "whitespace-nowrap border-b-[3px] px-5 py-3 text-sm font-medium flex justify-center cursor-pointer items-center",
            {
              "border-slate-900 text-gray-500": location === "/users/security",
              "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700":
                !(location === "/users/security"),
            }
          )}
        >
          <Link
            to={"/users/security"}
            className="hover:no-underline text-inherit"
          >
            <FormattedMessage {...messages.security} />
          </Link>
        </li>
      </ul>
    );
  }
}

export default injectIntl(withStyles(s)(AccountSettingsSideMenu));
