// General
import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Login.css";
import bt from "../../components/commonStyle.css";
import cx from "classnames";
import { Col } from "react-bootstrap";

// Components
import LoginForm from "../../components/LoginForm";
import SocialLogin from "../../components/SocialLogin";
import Link from "../../components/Link";

// Locale
import messages from "../../locale/messages";
import clsx from "clsx";

class Login extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    warning: PropTypes.bool,
    formatMessage: PropTypes.func,
    refer: PropTypes.string,
  };

  static defaultProps = {
    warning: false,
  };

  render() {
    const { warning, refer } = this.props;
    let initialValues = {};
    let socialLoginRefer;
    let registerURL = "/register";
    if (refer) {
      initialValues = {
        refer,
      };
      socialLoginRefer = refer;
      if (socialLoginRefer && socialLoginRefer != null) {
        socialLoginRefer =
          socialLoginRefer.indexOf("?") >= 0
            ? socialLoginRefer.replace("?", "------")
            : socialLoginRefer;
        socialLoginRefer =
          socialLoginRefer.indexOf("&") >= 0
            ? socialLoginRefer.replace("&", "--")
            : socialLoginRefer;
      }
      registerURL = "/register?refer=" + refer;
    }

    return (
      <div className={cx(s.root, s.mobTopSpace)}>
        <div
          className={clsx(
            s.container,
            "rounded-xl sm:border  bg-[#242526] border-[#606570] !max-w-[787px] "
          )}
        >
          {warning && (
            <div className={s.loginHeading}>
              <FormattedMessage {...messages.loginConfirmation} />
            </div>
          )}

          <LoginForm initialValues={initialValues} />
          <div className="pb-[40px] px-[20px] sm:!px-[200px]">
            <div className="relative flex items-center justify-center space-x-2 text-[#B1B3B8]">
              <hr className="w-1/2" />
              <div className="font-medium">or</div>
              <hr className="w-1/2" />
            </div>

            <SocialLogin refer={socialLoginRefer} />

            <p className={cx(s.linkText, 'text-[16px]')}>
              <FormattedMessage {...messages.noAccount} />
              <Link
                className={clsx(s.modalCaptionLink, "!text-blue-500")}
                to={registerURL}
              >
                <FormattedMessage {...messages.signUpHere} />
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(s, bt)(Login));
