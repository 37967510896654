// General
import React from 'react';
import PropTypes from 'prop-types';
import { flowRight as compose } from 'lodash';
// Redux
import { connect } from 'react-redux';
// Redux Form
import { reduxForm } from 'redux-form';
// Translation
import { FormattedMessage, injectIntl } from 'react-intl';

// Styles
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Header.css';
import cx from 'classnames';
import {
  Nav,
  NavDropdown,
  Navbar
} from 'react-bootstrap';

// Internal Components
import Link from '../Link';
import Navigation from '../Navigation';
import Logo from '../Logo';

// External Components
import Toaster from '../Toaster';
import LoadingBar from 'react-redux-loading-bar';
import HeaderLocationSearch from './HeaderLocationSearch';

// Redux action
import { toggleOpen, toggleClose } from '../../actions/Menu/toggleControl';

import history from '../../core/history';
import DropDownMenu from '../DropDownMenu/DropDownMenu';

//Image
import closeIcon from '../../../public/SiteIcons/loginClose.svg';

import mainLogo from "../../../public/SiteImages/mainLogo.svg";
import MenuItemLink from '../MenuItemLink/MenuItemLink';
import messages from '../../locale/messages';
import darkLogo from "../../../public/SiteIcons/dark-logo.svg";
import {
  openLoginModal,
  openSignupModal
} from '../../actions/modalActions';
import { isMobile } from 'react-device-detect';
import { LinearButton } from '../Button/GrayButton';

class HomeHeader extends React.Component {
  static propTypes = {
    borderLess: PropTypes.bool,
    showMenu: PropTypes.bool,
    toggleOpen: PropTypes.any.isRequired,
    formatMessage: PropTypes.any,
    checked: PropTypes.any,
  };

  static defaultProps = {
    borderLess: false,
    showMenu: false,
    searchDisablePages: [
      '/',
      '/home',
      '/become-a-host'
    ]
  }

  constructor(props) {
    super(props);
    this.state = {
      searchHide: true,
      load: false,
      isOpen: 0,
      fixedBgClass: '', //fixedBgHeader
      checked: false,
      scrollLogo: false
    };
    this.handleMenu = this.handleMenu.bind(this);
    this.handleDisableSearchPages = this.handleDisableSearchPages.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.openClose = this.openClose.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleDisableSearchPages();
  }

  componentDidMount() {
    this.setState({
      load: true
    });
    this.handleDisableSearchPages();
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  handleMenu() {
    const { showMenu, toggleOpen, toggleClose } = this.props;
    if (showMenu) {
      toggleClose();
    } else {
      toggleOpen();
    }
  }

  handleDisableSearchPages() {
    const { searchDisablePages } = this.props;
    let location = history.location ? history.location.pathname : null;
    let searchHide = false;
    if (location && searchDisablePages.length > 0) {
      searchHide = searchDisablePages.find((o) => location === o);
      searchHide = (searchHide) ? true : false;
    }
    this.setState({
      searchHide
    })
  }
  async openMenu() {
    this.setState({
      isOpen: 1,
    })
  }

  async openClose() {

    this.setState({
      isOpen: 0,
    })
  }



  handleScroll(event) {
    let top = document.documentElement.scrollTop || document.body.scrollTop,
      isWeb =
        (document.documentElement.clientWidth || document.body.clientWidth) >=
        1200
          ? true
          : false;
    let fixedBgClass = "",
      scrollLogo = false;
    if (isWeb && top >= 900) {
      fixedBgClass = "fixedBgHeader";
      scrollLogo = true;
    }
    this.setState({
      fixedBgClass,
      scrollLogo,
    });
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  render() {
    const {
      siteSettings,
      borderLess,
      showMenu,
      toggleOpen,
      layoutType,
      homeHeaderOnly,
      theme,
      isAuthenticated,
      openLoginModal,
      openSignupModal,
    } = this.props;
    const { searchHide, load } = this.state;
    const { fixedBgClass, scrollLogo } = this.state;
    let borderClass, headerDesignClass;
    let location;
    if (borderLess) {
      borderClass = s.commonHeaderBorderLess;
    }

    if (layoutType && layoutType != 2) {
      headerDesignClass = "headerNewDesign";
    }

    if (history.location) {
      location = history.location.pathname;
    }
    // if(isMobile) return null
    if (!load) {
      return (
        <div className={cx(headerDesignClass, "!bg-[#18191A]")}>
          <div className={s.root} key={new Date().getTime()}>
            <Toaster />
            <LoadingBar />
            <div className={cx(s.container, "!bg-[#18191A]")}>
              <Navbar
                className={cx(
                  s.commonHeader,
                  "commonHeader fixedBgHeader",
                  borderClass,
                  { ["homeHeader"]: location === "/" || location === "/home" },
                  layoutType == 5 ? fixedBgClass : "",
                  layoutType == 2 ? "darkBgHeder" : ""
                )}
                expanded={showMenu}
                onToggle={this.handleMenu}
              >
                <div className={s.headerFlexBox}>
                  <div>
                    <Navbar.Header
                      className={cx(
                        "logoPadding",
                        !showMenu ? "normalPosition" : "fixedPosition",
                        s.fullWidthSM
                      )}
                    >
                      <Navbar.Brand className={s.homeLogo}>
                        <img
                          src={theme === "dark" ? darkLogo : mainLogo}
                          alt="..."
                          className=""
                        />
                        {/* <Logo link={"/"} className={cx(s.brand, s.brandImg)} scrollLogo={scrollLogo} /> */}
                      </Navbar.Brand>
                    </Navbar.Header>
                    <Nav className="pl-[25px] ml-[25px] border-l border-solid hidden md:block border-gray-300 !pt-[12px]">
                      <NavDropdown
                        noCaret
                        title={
                          <div className="flex scrollOff">
                            Stay
                            <img
                              className="ml-[10px]"
                              src={"/SiteIcons/arrowDown.svg"}
                            />
                          </div>
                        }
                        id="basic-nav-dropdown23"
                      >
                        <MenuItemLink to="/s?&guests=1">
                          <div className="flex">
                            <img
                              className="mr-[16px] w-[24px]"
                              src={"/SiteIcons/house.svg"}
                            />
                            <FormattedMessage {...messages.placesToStay} />
                          </div>
                        </MenuItemLink>
                        <MenuItemLink to="/become-a-host?mode=new">
                          <div className="flex">
                            <img
                              className="mr-[16px]  w-[24px]"
                              src={"/SiteIcons/guest.svg"}
                            />
                            <FormattedMessage {...messages.becomeAHost} />
                          </div>
                        </MenuItemLink>
                      </NavDropdown>
                    </Nav>
                  </div>
                  <div onClick={() => this.openMenu()}>
                    <div className={"hidden-lg hamburgerButton"}>
                      <DropDownMenu />
                    </div>
                  </div>
                  <div
                    className={cx(
                      { [s.menuOpen]: this.state.isOpen == 1 },
                      s.mobileMenu
                    )}
                  >
                    <div
                      className={cx(
                        { [s.menuClose]: this.state.isOpen == 0 },
                        s.rightMenuClose,
                        "hidden-lg"
                      )}
                    >
                      <div
                        className={cx(
                          s.closeButtonPosition,
                          "closeButtonPositionDark"
                        )}
                      >
                        <div
                          className={cx(
                            s.closeColor,
                            "textWhite",
                            "closeColorRTL",
                            "svgImg"
                          )}
                          onClick={() => this.openClose()}
                        >
                          <img src={closeIcon} />
                        </div>
                      </div>
                    </div>
                    <div onClick={() => this.openClose()}>
                      <Navigation
                        openClose={this.state.isOpen}
                        layoutType={layoutType}
                        homePage
                      />
                    </div>
                  </div>
                </div>
              </Navbar>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={cx(headerDesignClass, "!bg-[#18191A]")}>
        <div className={s.root}>
          <Toaster />
          <LoadingBar />
          <div className={cx(s.container, "!bg-[#18191A]")}>
            <Navbar
              className={cx(
                s.commonHeader,
                "commonHeader fixedBgHeader",
                borderClass,
                { [s.fixedHeader]: location === "/s" },
                { ["homeHeader"]: location === "/" || location === "/home" },
                layoutType == 5 ? fixedBgClass : "",
                layoutType == 2 ? "darkBgHeder" : ""
              )}
              expanded={showMenu}
              onToggle={this.handleMenu}
            >
              <div className={s.headerFlexBox}>
                <div>
                <Navbar.Header className={cx((!showMenu ? 'normalPosition' : 'fixedPosition'), (layoutType == 2 ? 'layout2Logo' : 'logoPadding'))}>
                    <Navbar.Brand className={(s.homeLogo)}>

                      <img src={theme==='dark'? darkLogo : mainLogo} alt="..." className='' onClick={()=> history.push('/')}/>
                      {/* <Logo link={"/"} className={cx(s.brand, s.brandImg)} scrollLogo={scrollLogo} /> */}
                    </Navbar.Brand>
                  </Navbar.Header>
                  <Nav className="pl-[25px] ml-[25px] border-l border-solid hidden md:block border-gray-300 !pt-[12px]">
                    <NavDropdown
                      noCaret
                      title={
                        <div className="flex scrollOff">
                          Stay
                          <img
                            className="ml-[10px]"
                            src={"/SiteIcons/arrowDown.svg"}
                          />
                        </div>
                      }
                      id="basic-nav-dropdown23"
                    >
                      <MenuItemLink to="/s?&guests=1">
                        <div className="flex">
                          <img
                            className="mr-[16px] w-[24px]"
                            src={"/SiteIcons/house.svg"}
                          />
                          <FormattedMessage {...messages.placesToStay} />
                        </div>
                      </MenuItemLink>
                      <MenuItemLink to="/become-a-host?mode=new">
                        <div className="flex">
                          <img
                            className="mr-[16px]  w-[24px]"
                            src={"/SiteIcons/guest.svg"}
                          />
                          <FormattedMessage {...messages.becomeAHost} />
                        </div>
                      </MenuItemLink>
                    </NavDropdown>
                  </Nav>
                </div>
                <div onClick={() => this.openMenu()}>
                  <div className={cx("hidden-lg", "positionRelative")}>
                    <DropDownMenu />
                  </div>
                </div>
                {!searchHide && (
                  <Navbar.Form pullLeft className={("hidden-xs", s.breakPoint)}>
                    <HeaderLocationSearch />
                  </Navbar.Form>
                )}
                <div
                  className={cx(
                    { [s.menuOpen]: this.state.isOpen == 1 },
                    s.mobileMenu,
                    "homeMobileMenu"
                  )}
                >
                  <div
                    className={cx(
                      { [s.menuClose]: this.state.isOpen == 0 },
                      s.rightMenuClose,
                      "hidden-lg"
                    )}
                  >
                    <div
                      className={cx(
                        s.closeButtonPosition,
                        "closeButtonPositionDark"
                      )}
                    >
                      <div
                        className={cx(
                          s.closeColor,
                          "textWhite",
                          "closeColorRTL",
                          "svgImg"
                        )}
                        onClick={() => this.openClose()}
                      >
                        <img src={closeIcon} />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div onClick={() => this.openClose()}>
                      <Navigation
                        openClose={this.state.isOpen}
                        homeHeaderOnly={homeHeaderOnly}
                        homePage
                      />
                    </div>
                    {!isAuthenticated? <div className='flex items-center z-50'>
                      <button onClick={openLoginModal}  className={s.buttonNeoLogin}>
                          <div className={s.buttonNeoSigninBg}>
                          <text>Sign in</text>
                          </div>
                        </button>
                        <LinearButton onClick={openSignupModal} containerClassname="ml-[24px]">
                            <div className='text-[#E5E6EB] text-[16px]'>Sign up</div>
                        </LinearButton>
                        {/* <button onClick={openSignupModal} className={s.buttonNeoSignup}>
                          <div className={s.buttonNeoSignupBg}>
                          <text>Sign up</text>
                          </div>
                        </button> */}
                    </div>
                    :null}
                  </div>
                </div>
              </div>
            </Navbar>
          </div>
        </div>
      </div>
    );
  }
}

HomeHeader = reduxForm({
  form: "HomeHeaderForm", // a unique name for this form
})(HomeHeader);

const mapState = (state) => ({
  siteSettings: state.siteSettings.data,
  showMenu: state.toggle.showMenu,
  layoutType: state.siteSettings.data.homePageType,
  theme: state.currency.theme,
  isAuthenticated: state.runtime.isAuthenticated,
});

const mapDispatch = {
  toggleOpen,
  toggleClose,
  openLoginModal,
  openSignupModal,
};

export default compose(
  injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch)
)(HomeHeader);
