exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BecomeHost-titleText-37_OE {\n    color: #E5E6EB;\n    color: var(--Text-Text-Primary, #E5E6EB);\nfont-family: Inter;\nfont-size: 28px;\nfont-style: normal;\nfont-weight: 500;\nline-height: normal;\ntext-align: center;\npadding: 40px;\npadding-bottom: 0px;\n}\n.BecomeHost-saveExit-3s78P {\n    position: absolute;\n    top: -40px;\n    right: 50px;\n    z-index: 10000;\n    color: #E5E6EB!important;\n}", ""]);

// exports
exports.locals = {
	"titleText": "BecomeHost-titleText-37_OE",
	"saveExit": "BecomeHost-saveExit-3s78P"
};