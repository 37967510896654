// General
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Translation
import { FormattedMessage, injectIntl } from "react-intl";
import {
    FormGroup,
    Col,
    FormControl,
    ProgressBar,
} from 'react-bootstrap';

// Redux form
import { Field, reduxForm, change } from "redux-form";
import { graphql, gql, compose } from 'react-apollo';
// Locale
import messages from "../../locale/messages";
import s from './index.css';
import bt from '../../components/commonStyle.css';

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import loginIcon from "../../../public/SiteImages/loginIcon.svg";
import trashIcon from '../../../public/SiteIcons/trash.svg';
import { openForgotPasswordModal } from "../../actions/modalActions";
import addIcon from '../../../public/SiteIcons/add.svg';
import { getBalanceUser } from '../../actions/getBalanceUser';
import { toastr } from 'react-redux-toastr';
import * as FontAwesome from 'react-icons/lib/fa';
import ButtonLoader from '../Loader/ButtonLoader';
import history from '../../core/history';
import { delay } from "lodash";
import { GrayButton } from "../Button/GrayButton";
import LoadingAnimation from "./loading";

const checkAirbnbURL = (url) => {
    const regex = /^https:\/\/www\.airbnb\.(com|co\.[a-zA-Z]{2})\/(rooms|luxury\/listing)\/\d+(\?.*)?$/;
    return !/\s/.test(url) && regex.test(url);
};

class ImportListing extends Component {
    static propTypes = {
        formatMessage: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = {
            shown: false,
            disabled: true,
            loading: false,
            urlAirbnb: [''],
            error: [''],
            percent: 0
        };
        this.toggle = this.toggle.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.onChange = this.onChange.bind(this);
        this.addField = this.addField.bind(this);
        this.removeField = this.removeField.bind(this);
        this.calProgress = this.calProgress.bind(this)
        this.selectorRef = React.createRef(null);
    }

    toggle() {
        this.setState({
            shown: !this.state.shown
        });
    }

    onChange(e, index) {
        if (e.target.value && e.target.value.trim() != '') {
            const urlAirbnb = [...this.state.urlAirbnb]
            urlAirbnb[index] = e.target.value;
            console.log(urlAirbnb);
            this.setState({ urlAirbnb });
            if (checkAirbnbURL(e.target.value)) {
                this.setState({ disabled: false, error: [] });

            } else {
                const updatedErrors = [...this.state.error];
                updatedErrors[index] = 'URL invalid';
                this.setState({ disabled: true, error: updatedErrors });
            }
        } else {
            const updatedErrors = [...this.state.error];
            updatedErrors[index] = 'URL cannot be empty';
            this.setState({ disabled: true, error: updatedErrors });
        }
    }

    renderFormControl = ({ input, label, type, meta: { touched, error }, className, placeholder }) => {
        const { formatMessage } = this.props.intl;
        return (
            <FormGroup className={cx(s.formGroup, 'row  !mb-0')}>
                <Col xs={12} sm={12} md={12} lg={12} className={'rtlWishListInput'}>
                    {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    <FormControl {...input} placeholder={placeholder} type={type} className={className} />
                </Col>
            </FormGroup>
        );
    }

    calProgress = () => {
        const interval = setInterval(() => {
            if (this.state.percent < 100) {
              this.setState((prevState) => ({
                percent: prevState.percent+100/80
              }))
            } else {
              clearInterval(interval);
            }
          }, 1000);
    }

    async submitForm() {
        const { change, importListing } = this.props;
        const { urlAirbnb } = this.state
        event.preventDefault();
        if (urlAirbnb.filter(el => !!el).length > 0) {

            this.setState({
                loading: true
            });
            this.calProgress()
            const res = await importListing({
                variables: {
                    urlAirbnb: urlAirbnb
                },

            });
            if (res?.data?.importListing?.status?.length && res?.data?.importListing?.status?.every(el => el === 'Import success')) {
                toastr.success("Import success!")
                this.setState({ error: [] })
                history.push('/listings')
            } else {
                this.setState({
                  loading: false
                });
                this.setState({ error: res?.data?.importListing?.status })
            }
            this.setState({
                percent: 100
            })
            await delay(500)
            this.setState({
                loading: false
            });

        }
    }


    addField = () => {
        const { urlAirbnb, error } = this.state;
        if (urlAirbnb.length > 4) {
            toastr.info("You can import 5 listings every time")
            return
        }
        this.setState({
            urlAirbnb: [...urlAirbnb, ''],
            error: [...error, ''],
        });
    };

    removeField = (index) => {
        const { urlAirbnb, error } = this.state;
        if (urlAirbnb.length < 2) return
        urlAirbnb.splice(index, 1);
        error.splice(index, 1);
        this.setState({ urlAirbnb, error });
    };


    render() {
        const { submitting, importListing } = this.props;
        const { formatMessage } = this.props.intl;
        const { disabled, shown, loading, error, urlAirbnb } = this.state;

        return (
          <div className={s.root}>
            <div className={cx(s.container, "rtlWishListInput", "relative", loading ? s.containerHeight : "")}>
              {loading ? (
                <div className={s.importProcess}>
                  <div className={cx(s.importProcesstext, "mt-[32px]")}>
                    Importing please wait...
                  </div>
                  <LoadingAnimation />
                  <ProgressBar
                    now={this.state.percent}
                    className={cx(s.importProgress, "importProgress")}
                  />
                </div>
              ) : null}

              {!loading ? (
                <div className={cx("inputFocusColor", "py-[32px] px-[70px]")}>
                  <div className={"w-full flex justify-center  pb-[40px]"}>
                    <div className={s.modalheading}>
                      Import <span className={s.airbnb_text}>Airbnb</span>{" "}
                      listing
                    </div>
                  </div>

                  <form onSubmit={this.submitForm} autoComplete="off">
                    {
                      <div
                        className={cx(
                          s.textAignRight,
                          "textAlignLeftRtl",
                          "mt-3"
                        )}
                      >
                        {urlAirbnb.map((value, index) => (
                          <>
                            <div className="flex gap-4 mt-3">
                              <div className="w-full relative">
                                <Field
                                  name={`urlAirbnb[${index}]`}
                                  type="text"
                                  value={urlAirbnb[index]}
                                  component={this.renderFormControl}
                                  placeholder={
                                    index == 0
                                      ? "https://www.airbnb.com/rooms/770410970869283356"
                                      : " "
                                  }
                                  className={cx(
                                    bt.commonControlInput,
                                    s.space1,
                                    "w-full self-center",
                                    index == 0 ? "" :s.urlInput
                                  )}
                                  onChange={(e) => this.onChange(e, index)}
                                />
                              </div>
                              <div className="flex items-center pb-[4px] w-[50px] justify-end">
                                {index + 1 == urlAirbnb.length ? (
                                  <GrayButton
                                    containerClassname="h-[40px] w-[40px]"
                                    onClick={this.addField}
                                  >
                                    <img
                                      src={addIcon}
                                      alt="..."
                                      className="self-center"
                                    />
                                  </GrayButton>
                                ) : (
                                  <GrayButton
                                    containerClassname="h-[40px] w-[40px]"
                                    onClick={() => this.removeField(index)}
                                  >
                                    <img
                                      src={trashIcon}
                                      className="self-center"
                                      alt="..."
                                    />
                                  </GrayButton>
                                )}
                              </div>

                              {/* <Button className='h-[58px]' onClick={() => this.removeField(index)}><FontAwesome.FaMinus
                                            size={18}
                                            className='inline-block '
                                        /></Button> */}
                            </div>
                            {error[index] && error[index].length > 0 && (
                              <span
                                className={cx(
                                  bt.errorMessage,
                                  error[index] === "Import success"
                                    ? "!text-lime-600"
                                    : ""
                                )}
                              >
                                {error[index]}
                              </span>
                            )}
                          </>
                        ))}

                        {/* <Button onClick={this.addField}><FontAwesome.FaPlus
                                size={18}
                                className='inline-block'
                            />Add field</Button> */}
                        <FormGroup
                          className={cx(s.formGroup, "text-center mb-0 mt-28")}
                        >
                          <ButtonLoader
                            neumorphic
                            label={"Import"}
                            show={loading}
                            type="submit"
                          ></ButtonLoader>
                        </FormGroup>
                      </div>
                    }
                  </form>
                </div>
              ) : null}
            </div>
          </div>
        );
    }
}

ImportListing = reduxForm({
    form: "LoginForm",
    destroyOnUnmount: false,
})(ImportListing);

const mapState = (state) => ({
    siteName: state.siteSettings.data.siteName,
});

const mapDispatch = {
    openForgotPasswordModal,
};

export default compose(injectIntl,
    withStyles(s, bt),
    connect(mapState, mapDispatch),
    graphql(gql`
        mutation importListing(
            $urlAirbnb:[String],
        ){
            importListing(
                urlAirbnb: $urlAirbnb,
            ) {
                status,
                id
            }
        }
    `, {
        name: 'importListing'
    })
)(ImportListing);