// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import {
    Modal,
    ListGroup,
    ListGroupItem
} from 'react-bootstrap';

// Redux
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SumsubWebSdk from '@sumsub/websdk-react'
import s from './KYCModal.css'


// Actions

class KYCModal extends Component {
    static propTypes = {

    };

    constructor(props) {
        super(props);
        this.state = {
            showingModal: true,
            isFormOpen: false,
            copied: false,
        };
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { accessTokenExpirationHandler, accessToken, messageHandler, errorHandler } = this.props;
        const { showingModal } = this.state;
        return (
            <div>
                <Modal show={showingModal && accessToken} animation={false} onHide={() => {
                    this.setState({ showingModal: false })
                }} dialogClassName={cx(s.kycModal, 'kycModal','signupModal', 'sharesocialModal')} >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body bsClass={''}>
                        <SumsubWebSdk
                            accessToken={accessToken}
                            expirationHandler={accessTokenExpirationHandler}
                            onMessage={messageHandler}
                            onError={errorHandler}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


const mapState = state => ({

});

const mapDispatch = {

};

export default (injectIntl)(withStyles(s)(connect(mapState, mapDispatch)(KYCModal)));