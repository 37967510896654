import React from 'react';
import s from './HomeSlider.css';
import nextBtnIcon from '../../../../public/SiteIcons/SideArrow.svg';
import prevBtnIcon from '../../../../public/SiteIcons/SideArrowLeft.svg';
const NavigateButton = ({seeall, onNext, onPrev}) => {
  return (
    <div className={s.navigateContainer}>
      <div>
        {seeall}
      </div>
      <div className={s.prevButton} onClick={onPrev}>
        <img src={prevBtnIcon} />
      </div>
      <div className={s.nextButton} onClick={onNext}>
        <img src={nextBtnIcon} />
      </div>
    </div>
  );
};
export default NavigateButton;
