import React from "react";
import { flowRight as compose } from "lodash";

import withStyles from "isomorphic-style-loader/lib/withStyles";

import s from "./Layout5.css";
import cx from "classnames";
import { connect } from "react-redux";

// Components
import SearchForm from "../SearchForm/SearchForm";
import Animation from "./animation.jsx";
import { isMobile } from "react-device-detect";
class Layout5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideMenu: "", //hideMenu
      videoUrl:
        "https://res.cloudinary.com/drr1rnoxf/video/upload/v1708451806/image_wt_reflection_vel62u.mp4",
    };
    this.scrollTop = this.scrollTop.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleDisableSearchPages = this.handleDisableSearchPages.bind(this);
  }

  scrollTop() {
    window.scrollTo({
      top: screen.height,
      behavior: "smooth",
    });
  }

  componentDidMount() {
    this.setState({
      load: true,
    });
    this.handleDisableSearchPages();
    document.addEventListener("scroll", this.handleScroll);

    // Define your logic to set different video sources based on viewport size
    const isMobile = window.innerWidth <= 450; // Example: consider screens <= 768px as mobile

    if (isMobile) {
      this.setState({ videoSource: "https://mobile-source-url.mp4" });
    } else {
      this.setState({
        videoSource:
          "https://res.cloudinary.com/drr1rnoxf/video/upload/v1708451806/image_wt_reflection_vel62u.mp4",
      });
    }
  }
  handleDisableSearchPages() {}
  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    let top = document.documentElement.scrollTop || document.body.scrollTop,
      isWeb =
        (document.documentElement.clientWidth || document.body.clientWidth) >=
        1200
          ? true
          : false;
    let hideMenu = "";
    if (isWeb && top >= 420) {
      hideMenu = "scrollMenu";
    }
    if (isWeb && top >= 910) {
      hideMenu = "";
    }

    this.setState({
      hideMenu,
    });
  }

  render() {
    const { title, content, homeBannerImages } = this.props;
    const { hideMenu, videoUrl } = this.state;

    let path = "/images/home/",
      homeBannerFirst,
      layout5Css = "layout5Css";

    if (homeBannerImages && homeBannerImages.length > 0)
      homeBannerFirst = path + homeBannerImages[0].name;

    return (
      <div
        className={cx(s.bannerLayoutContainer, s.backgroundVideo, "layoutFive")}
      >
        <div className={cx(s.animationContainer)}>
          <Animation />
        </div>
        <div className={s.videoContainer}>
          {isMobile ? (
            <div className={s.mobileBackgroundImage}></div>
          ) : (
            <video autoPlay muted loop className={s.bgvideo}>
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        {/* {isMobile && <div>dsadas</div>} */}
        <div className={s.videoContainer}>
          <video autoPlay muted loop className={s.bgvideo}>
            <source
              src="https://res.cloudinary.com/drr1rnoxf/video/upload/v1708451806/image_wt_reflection_vel62u.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={s.searchFormContainer}>
          <div className={cx(s.bannerBackgroundImage)} />
          <div> </div>
          <div>
            <div className="flex text-[40px] text-[#050505] mb-4 justify-center">
              <div className={s.heroHeader}>Book. Stay. Earn.</div>
            </div>
            <div className={cx(s.marginTop, hideMenu, "mt-[32px]")}>
              <SearchForm layout5Css={layout5Css} />
            </div>
          </div>
          {/* <div className={s.positionRelative}>
              <h1 className={cx(s.noMargin, s.bannerCaptionText)}>
                <span>{title}</span>
                {' '} {content}
              </h1>
            </div> */}
        </div>
      </div>
    );
  }
}

export default compose(withStyles(s))(Layout5);
