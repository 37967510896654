import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import SearchForm from '../../components/Home/SearchForm/SearchForm';
import s from "./index.css"
import MobileHeader from "../../../src/components/Header/MobileHeader"
import { isMobile } from 'react-device-detect'; // Import isMobile from react-device-detect

class Search extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMobileView: false // Initialize with false
    };
  }

  componentDidMount() {
    this.setState({ isMobileView: isMobile }); // Set isMobileView once component mounts
  }

  render() {
    const { isMobileView } = this.state;

    // Redirect to a different route if viewed on a device with width greater than 450px
    if (typeof window !== 'undefined' && window.innerWidth > 450 && !isMobileView) {
      window.location.href = '/';
      return null; // Redirecting, so don't render anything
    }

    // Render normally if viewed in mobile, or on the server, or on a device with width less than or equal to 450px
    return (
      <>
        <MobileHeader open={'search'} />
        <SearchForm />
      </>
    );
  }
}

export default withStyles(s)(Search);
