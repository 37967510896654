import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import cx from "classnames";

// Component
import PanelItem from "../PanelItem";
import NoItem from "../NoItem";

// Locale
import messages from "../../../locale/messages";

import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./PanelWrapper.css";

import { Tabs, Tab, Button } from "react-bootstrap";

class PanelWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      tabIndex: 0,
    };
  }

  static propTypes = {
    data: PropTypes.array.isRequired,
    formatMessage: PropTypes.any,
  };

  handleClick() {
    history.push("/become-a-host");
  }

  setValue = (value) => {
    this.setState({ searchValue: value });
  };

  render() {
    const {
      data: { ManageListings, refetch, loading },
    } = this.props;
    let listedItems = [];
    let inProgressItems = [];
    let unListedItems = [];
    const { searchValue } = this.state;

    if (
      ManageListings &&
      ManageListings.status == 200 &&
      ManageListings.userListingCount > 0
    ) {
      ManageListings.results &&
        ManageListings.results.length > 0 &&
        ManageListings.results
          .filter((item) => item?.importedId)
          .map((item) => {
            console.log(item);
            if (!item.isReady) {
              inProgressItems.push(item);
            } else if (item.isReady && item.isPublished) {
              listedItems.push(item);
            } else if (item.isReady && !item.isPublished) {
              unListedItems.push(item);
            }
          });
      return (
        <div
          className={cx(
            "listingTab",
            "listingMobileTab",
            "tabReservation",
            s.layoutContainer
          )}
        >
          <div className={cx(s.tabButtonContainer)}>
            <div
              className={cx(
                this.state.tabIndex == 0 ? s.tabBtnActive : s.tabBtnDeactive
              )}
              onClick={() => this.setState({ tabIndex: 0 })}
            >
              <div
                className={cx(
                  this.state.tabIndex == 0
                    ? s.tabBtnActiveContent
                    : s.tabBtnDeactiveContent
                )}
              >
                <FormattedMessage {...messages.panelHeader1} />
              </div>
            </div>
            <div
              className={cx(
                this.state.tabIndex == 1 ? s.tabBtnActive : s.tabBtnDeactive
              )}
              onClick={() => this.setState({ tabIndex: 1 })}
            >
              <div
                className={cx(
                  this.state.tabIndex == 1
                    ? s.tabBtnActiveContent
                    : s.tabBtnDeactiveContent
                )}
              >
                <FormattedMessage {...messages.listed} />
              </div>
            </div>
            <div
              className={cx(
                this.state.tabIndex == 2 ? s.tabBtnActive : s.tabBtnDeactive
              )}
              onClick={() => this.setState({ tabIndex: 2 })}
            >
              <div
                className={cx(
                  this.state.tabIndex == 2
                    ? s.tabBtnActiveContent
                    : s.tabBtnDeactiveContent
                )}
              >
                <FormattedMessage {...messages.unListed} />
              </div>
            </div>
          </div>
          <div className="mt-[32px]">
          {
            this.state.tabIndex == 0
            ? <PanelItem data={inProgressItems} refetch={refetch} loading={loading} searchKey={searchValue} setValue={this.setValue} />
            : this.state.tabIndex == 1
            ? <PanelItem data={listedItems} refetch={refetch} loading={loading} searchKey={searchValue} setValue={this.setValue} />
            : <PanelItem data={unListedItems} notShowQst={true} refetch={refetch} loading={loading} searchKey={searchValue} setValue={this.setValue} />
          }
          </div>
        </div>
      );
    } else {
      return <NoItem />;
    }
  }
}

export default injectIntl(withStyles(s)(PanelWrapper));
