import React from "react";
import PropTypes from "prop-types";
import Swiper from "react-id-swiper";
import { flowRight as compose } from "lodash";

import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./index.css";
import { Grid, Row, Col } from "react-bootstrap";
import { injectIntl, FormattedMessage } from "react-intl";
import history from "../../../core/history";

//images
import nextIcon from "../../../../public/SiteIcons/popular-right.png";
import prevIcon from "../../../../public/SiteIcons/popular-left.png";
import navigateRight from "../../../../public/SiteIcons/navigate-right.svg";
import ArrowCorner from "../../../../public/SiteIcons/Arrow-Up--Streamline-Ultimate 1.svg";
//Helper
import { isRTL } from "../../../helpers/formatLocale";

// Locale
import messages from "../../../locale/messages";
import HomeSliderSkleton from "../../Skeleton/HomeSliderSkleton";

const nextArrowStyle = {
  position: "absolute",
  right: "-18px",
  background: "transparent",
  color: "#00B0CD",
  zIndex: "2",
  width: "auto",
  height: "auto",
  top: "38%",
  fontSize: "40px",
  cursor: "pointer",
  borderRadius: "50%",
  textAlign: "center",
};

const prevArrowStyle = {
  position: "absolute",
  left: "-13px",
  background: "transparent",
  color: "#00B0CD",
  zIndex: "2",
  width: "auto",
  height: "auto",
  top: "38%",
  fontSize: "40px",
  cursor: "pointer",
  borderRadius: "50%",
  textAlign: "center",
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={nextArrowStyle} onClick={onClick}>
      <img src={nextIcon} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={prevArrowStyle} onClick={onClick}>
      <img src={prevIcon} />
    </div>
  );
}
class PopularLocation extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.swiper = null;
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
    this.progress = this.progress.bind(this);
    this.state = {
      isClient: false,
      load: true,
      isBeginning: true,
      isEnd: false,
      showArrow: false,
    };
  }

  static defaultProps = {
    data: [],
    arrow: true,
  };

  componentDidMount() {
    const { data } = this.props;
    const isBrowser = typeof window !== "undefined";
    let smallDevice = isBrowser
      ? window.matchMedia("(max-width: 640px)").matches
      : false;
    let showArrow = false;
    if (smallDevice) {
      showArrow = data && data.length > 1 ? true : false;
    } else {
      showArrow = data && data.length > 4 ? true : false;
    }
    this.setState({
      load: true,
      isClient: true,
      showArrow,
    });
  }

  componentDidUpdate(prevProps) {
    const { locale } = this.props.intl;
    const { locale: prevLocale } = prevProps.intl;

    if (locale !== prevLocale) {
      this.setState({
        load: false,
      });
      clearTimeout(this.loadSync);
      this.loadSync = null;
      this.loadSync = setTimeout(() => {
        this.setState({
          load: true,
        });
        this.progress();
      }, 1);
    }
  }

  goNext() {
    this.swiper.slideNext();
    this.progress();
  }

  goPrev() {
    this.swiper.slidePrev();
    this.progress();
  }

  progress() {
    this.setState({
      isEnd: this.swiper.isEnd,
      isBeginning: this.swiper.isBeginning,
    });
  }

  render() {
    const { load, isClient, showArrow } = this.state;
    const { data, arrow } = this.props;
    const {
      intl: { locale },
    } = this.props;
    let arrow_display = this.props.arrow;
    arrow_display = false;
    let th = this;

    const params = {
      slidesPerView: 4,
      breakpoints: {
        991: {
          slidesPerView: 3,
        },
        767: {
          slidesPerView: 1,
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    if (showArrow === true) {
      params.loop = true;
    }

    return (
      <div
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={cx(
          "noPadding",
          "homeSliderPaading",
          "homeSwiper",
          s.marginTop
        )}
      >
        <h3 className={cx(s.containerTitle, "newTitleClass")}>
          <FormattedMessage {...messages.popularLocationHeading} />
        </h3>
        <div className={s.locationContainer}>
          <div className={s.sectionOne}>
            <div className={s.mainDesc}>
              Dubai, a dazzling metropolis in the United Arab Emirates, is
              renowned for its futuristic skyline dominated by architectural
              marvels. This global hub seamlessly combines traditional Arabian
              charm with ultramodern luxury, offering visitors a unique blend of
              opulent shopping, gourmet dining, and desert adventures.
            </div>

            <div className={s.buttonToVisit} onClick={() => history.push("/s")}>
              <span className={s.buttonText}>View all popular locations</span>
              <img className="ml-[12px]" src={navigateRight} alt="..." />
            </div>
          </div>
          <div className={cx(s.sectionTwo, "relative")}>
            {data &&
              data.length > 0 &&
              data.map((item, index) => {
                if (item.isEnable == "true") {
                  let path = "/images/popularLocation/" + item.image;
                  return (
                    <div className="relative">
                      {console.log("item", item)}
                      <img src={path} className={s.popularImg} alt="..." />
                      <div className={s.title}>{item.location || ""}</div>
                      <div
                        className={s.arrowDiv}
                        onClick={() =>
                          history.push(
                            "/s?&address=" +
                              item?.locationAddress +
                              "&chosen=1",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className={s.arroWIcon}
                          src={ArrowCorner}
                          alt="..."
                        />
                      </div>
                    </div>
                  );

                  // return <div key={index}><PopularLocationGridItem id={item.id} location={item.location} image={item.image} locationAddress={item.locationAddress} key={index} path={path} /></div>
                }
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(injectIntl, withStyles(s))(PopularLocation);
