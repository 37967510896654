// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Redux Form
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
// Translation
import { injectIntl, FormattedMessage } from 'react-intl';
// Redux
import { connect } from 'react-redux';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Row,
  Col
} from 'react-bootstrap';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import s from './ListPlaceStep1.css';
import bt from '../../components/commonStyle.css';

// Locale
import messages from '../../locale/messages';

// components
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';

// Helpers
import validate from './validate';
import update from './update';

//Image
import toolTipIcon from '../../../public/SiteIcons/listCommonToolTip.svg';

const listCategory = [
  { icon: '/SiteIcons/house.svg', name: 'City House', id: 5 },
  { icon: '/SiteIcons/Apartment.svg', name: 'Apartment', id: 6 },
  { icon: '/SiteIcons/Bed.svg', name: 'Bed n breakfast', id: 7 },
  { icon: '/SiteIcons/Beachhouse.svg', name: 'Beach house', id: 130 },
  { icon: '/SiteIcons/cabin.svg', name: 'Cabin', id: 131 },
  { icon: '/SiteIcons/hotel.svg', name: 'Hotel', id: 132 },
  { icon: '/SiteIcons/house.svg', name: 'Countryside', id: 133 },
  { icon: '/SiteIcons/farm.svg', name: 'Farms', id: 134 },
  { icon: '/SiteIcons/island.svg', name: 'Islands', id: 135 }
]


class Page9 extends Component {

  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      houseType: [],
      roomType: [],
      buildingSize: [],
    }
    this.onSelectType = this.onSelectType.bind(this)
  }

  UNSAFE_componentWillMount() {
    const { listingFields } = this.props;
    change('houseType', 5)
    if (listingFields != undefined) {
      this.setState({
        houseType: listingFields.houseType,
        roomType: listingFields.roomType,
        buildingSize: listingFields.buildingSize
      });
    }
  }

  componentDidMount() {
    const { valid } = this.props;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { valid, listingFields } = nextProps;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }

    if (listingFields != undefined) {
      this.setState({
        houseType: listingFields.houseType,
        roomType: listingFields.roomType,
        buildingSize: listingFields.buildingSize
      });
    }
  }

  renderSelectField = ({ input, label, meta: { touched, error }, children }) => {
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <select
          {...input}
        >
          {children}
        </select>
        {touched && error && <span>{formatMessage(error)}</span>}
      </div>
    )
  }

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className} >
          {children}
        </FormControl>
      </div>
    )
  }

  onSelectType = (id) => {
    const {change, nextPage} = this.props
    change('houseType', id)
  }

  render() {
    const { handleSubmit, previousPage, nextPage, existingList, formPage, houseType, step } = this.props;
    const { formatMessage } = this.props.intl;
    const { isDisabled, roomType, buildingSize } = this.state;
    let path = "index";
    if (existingList) {
      path = "home";
    }
    console.log(houseType);
    return (
      <div>
        <div className={""}>
          {/* <SidePanel
            title={formatMessage(messages.stepOneCommonHeading)}
            landingContent={formatMessage(messages.whatKindOfPlaceListing)}
          /> */}

          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <div className={cx(s.landingMainContent, s.pageHouseCont)}>
                  <Row
                    className={cx(
                      "homeSwiper localtionList relative houseTypeCont"
                    )}
                  >
                    {listCategory.map((el) => (
                      <Col
                        sm={6}
                        md={4}
                        lg={4}
                        xs={6}
                        className={cx(
                          s.responsiveMargin,
                          s.noPaddingMobile,
                          "p-3"
                        )}
                      >
                        <div
                          className={cx(
                            "flex flex-col justify-center items-stretch",
                            s.propertyOptionCard,
                            el.id === houseType ? s.selectedHouseType : "",
                          )}
                          onClick={() => {
                            this.onSelectType(el.id);
                            console.log(houseType, houseType, el.id);
                          }}
                        >
                          <div
                            className={cx(
                              "text-center flex w-full flex-col py-7",
                              s.propertyOptionCardContent,
                              el.id === houseType ? s.propertyOptionCardBorder : "",
                            )}
                          >
                            <img
                              loading="lazy"
                              src={el.icon}
                              className="aspect-square object-contain object-center w-[45px] overflow-hidden max-w-full mx-auto mt-5 self-start"
                            />
                            <div
                              className={cx(
                                "text-xl font-semibold self-stretch mt-2.5",
                                s.textDefault
                              )}
                            >
                              {el.name}
                            </div>
                            {/* <div className="text-base self-stretch whitespace-nowrap mt-3">
                        Small description about it
                      </div> */}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>

                <FooterButton
                  isDisabled={isDisabled}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  nextPagePath={"room"}
                  previousPagePath={path}
                  formPage={formPage}
                  step={step}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Page9 = reduxForm({
  form: 'ListPlaceStep1', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmit: update
})(Page9);

// Decorate with connect to read form values
const step1FormSelector = formValueSelector('ListPlaceStep1'); // <-- same as form name

const mapState = (state) => ({
  existingList: state.location.isExistingList,
  listingFields: state.listingFields.data,
  houseType: step1FormSelector(state, 'houseType'),
});

const mapDispatch = {change};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(Page9)));