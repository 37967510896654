// General
import React from 'react';
import PropTypes from 'prop-types';

// Translation
import { injectIntl } from 'react-intl';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Button } from 'react-bootstrap';
import s from './IncrementButton.css';
import * as FontAwesome from 'react-icons/lib/fa';
import cx from 'classnames';

//Images
import plusIcon from '../../../public/SiteIcons/plusIncrementIcon.svg';
import minusIcon from '../../../public/SiteIcons/minusDecrementIcon.svg';

class IncrementButton extends React.Component {
  static propTypes = {
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    labelSingluar: PropTypes.string,
    labelPlural: PropTypes.string,
    incrementBy: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.any,
  };

  increment = (value, maxValue, incrementBy) => {
    if (value < maxValue) {
      return (Number(value) + Number(incrementBy));
    }
  }

  decrement = (value, minValue, incrementBy) => {
    if (value > minValue) {
      return (Number(value) - Number(incrementBy));
    }
  }

  render() {

    const { input: { value, onChange }, maxValue, minValue, labelSingluar, labelPlural, incrementBy } = this.props;
    const { formatMessage } = this.props.intl;

    let label;
    if (value > 1) {
      label = labelPlural;
    } else {
      label = labelSingluar;
    }
    return (
      <div className={cx(s.inputFieldBox)}>
        <div
          className={cx(s.neumorphicButton)}
          onClick={() => onChange(this.decrement(value, minValue, incrementBy))}
        >
          <div className={cx(s.neumorphicButtonContent)}>
            <img
              className="w-[16px] filter brightness-0 invert-[0.6]"
              src={minusIcon}
            />
          </div>
        </div>
        <div className={cx(s.inputText)}>{value}</div>
        <div
          className={cx(s.neumorphicButton)}
          onClick={() => onChange(this.increment(value, maxValue, incrementBy))}
        >
          <div className={cx(s.neumorphicButtonContent)}>
            <img
              className="w-[16px] filter brightness-0 invert-[0.6]"
              src={plusIcon}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(IncrementButton));
