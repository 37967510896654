// General
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux form
import { Field, reduxForm } from 'redux-form';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';

// Internal Helpers
import validate from './validate';
import submit from './submit';

// Locale
import messages from '../../../locale/messages';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './ForgotPasswordForm.css';
import c from '../../../components/LoginModal/LoginModal.css';
import bt from '../../../components/commonStyle.css';
import {
  Button,
  FormGroup,
  FormControl,
} from 'react-bootstrap';
import forgotPassword from "../../../../public/SiteIcons/forgotPassword.svg";
import history from '../../../core/history';


class ForgotPasswordForm extends Component {

  static propTypes = {
    formatMessage: PropTypes.any,
  };

  renderFormControl = ({ input, label, type, meta: { touched, error }, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={bt.space3}>
        <FormControl {...input} placeholder={label} type={type} className={className} />
        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
      </FormGroup>
    )
  }

  render() {
    const { error, handleSubmit, submitting, dispatch } = this.props;
    const { formatMessage } = this.props.intl;
    const { openLoginModal } = this.props;
    return (
      <div className="w-[787px] mx-auto max-w-full text-white mt-[60px] mb-[300px]">
        <form
          onSubmit={handleSubmit(submit)}
          className="flex flex-col items-center text-center"
        >
          {error && (
            <span className={bt.errorMessage}>{formatMessage(error)}</span>
          )}

          <div className="flex flex-col pt-5 pb-10 text-gray-200 rounded-3xl border border-solid bg-neutral-800 border-zinc-500 max-w-[787px]">
            <div className="flex gap-5 ml-6 max-w-full w-[463px] max-md:flex-wrap">
              <div className="flex flex-col justify-center p-0.5 text-base font-medium text-center whitespace-nowrap rounded-md shadow-md bg-neutral-800">
                <div
                  className={cx(s.backButtonContainer)}
                  onClick={() => history.push("/login")}
                >
                  <div className={cx(s.backButton)}>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/784017082f29e6d8a628f58b32018c95a519710e89064db15b54469a5861b496?"
                      className="shrink-0 w-4 aspect-square"
                    />
                    <div>Back</div>
                  </div>
                </div>
              </div>
              <div className="flex-auto my-auto text-2xl ml-[150px] font-semibold">
                Forgot password
              </div>
            </div>
            <div className="mt-5 w-full border border-solid bg-zinc-500 border-zinc-500 min-h-[1px] max-md:max-w-full" />
            <div className="flex flex-col items-center px-20 mt-11 w-full text-base font-medium max-md:px-5 max-md:mt-10 max-md:max-w-full">
              <div className="text-lg leading-8 text-center max-md:max-w-full">
                Give us your registered Email and we will help you reset your
                password.
              </div>
              <div className="w-96 max-w-full text-start mt-[32px]">Email</div>
              <Field
                name="email"
                type="text"
                component={this.renderFormControl}
                label={formatMessage(messages.email)}
                className={
                  cx("justify-center items-start p-4 mt-[16px] mb-[0px] h-[51px] w-96 max-w-full text-sm rounded-lg border border-solid bg-neutral-800 border-zinc-500 text-zinc-400 max-md:pr-5", s.noMb)
                }
              />
              <div className="flex flex-col justify-center mt-[14px] w-96 max-w-full">
                <button
                  type="submit"
                  disabled={submitting}
                  className={cx(s.signupButtonBgContainer)}
                >
                  <div className={cx(s.linearButton)}>
                    <div className={cx(s.effectButton)}>Send reset link</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

}

ForgotPasswordForm = reduxForm({
  form: 'ForgotPasswordForm', // a unique name for this form
  validate,
  destroyOnUnmount: true
})(ForgotPasswordForm);

export default injectIntl(withStyles(s, bt, c)(ForgotPasswordForm));