import React, { Component } from 'react';

// packages
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Table, Tr, Td, Thead, Th } from 'reactable';
import { connect } from 'react-redux';
import { FormControl } from 'react-bootstrap';
import { flowRight as compose } from 'lodash';
import { toastr } from 'react-redux-toastr';

// Components
import Payout from './Payout';
import Refund from './Refund';
import CurrencyConverter from '../../CurrencyConverter';
import Link from '../../Link';
import ModalForm from './ModalForm';
import CustomPagination from '../../CustomPagination';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// Redux Action
import { viewReceiptAdmin } from '../../../actions/Reservation/viewReceiptAdmin';

// Translation
import messages from '../../../locale/messages';

// Helper
import formatReservationState from '../../../helpers/formatReservationState';
import { debounce } from '../../../helpers/debounce';
import history from '../../../core/history';

// Images
import ExportImage from '../../../../public/adminIcons/export.png';

// Style
import s from './ReservationManagement.css';

class ReservationManagement extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.any.isRequired,
      getTransactionHistory: PropTypes.shape({
        count: PropTypes.number.isRequired,
        reservationData: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number.isRequired,
          listId: PropTypes.number.isRequired,
          hostId: PropTypes.string.isRequired,
          guestId: PropTypes.string.isRequired,
          checkIn: PropTypes.string.isRequired,
          checkOut: PropTypes.string.isRequired,
          guestServiceFee: PropTypes.number.isRequired,
          hostServiceFee: PropTypes.number.isRequired,
          total: PropTypes.number.isRequired,
          currency: PropTypes.string.isRequired,
          reservationState: PropTypes.string.isRequired,
          listData: PropTypes.shape({
            title: PropTypes.string.isRequired
          }),
          hostData: PropTypes.shape({
            profileId: PropTypes.number.isRequired,
            firstName: PropTypes.string.isRequired
          }),
          hostPayout: PropTypes.shape({
            id: PropTypes.number.isRequired,
            payEmail: PropTypes.string.isRequired,
            methodId: PropTypes.number.isRequired,
            currency: PropTypes.string.isRequired,
            last4Digits: PropTypes.number
          }),
          hostTransaction: PropTypes.shape({
            id: PropTypes.number.isRequired,
          }),
          guestData: PropTypes.shape({
            profileId: PropTypes.number.isRequired,
            firstName: PropTypes.string.isRequired
          }),
          transaction: PropTypes.shape({
            payerEmail: PropTypes.string.isRequired,
            paymentType: PropTypes.string.isRequired,
            total: PropTypes.number.isRequired,
            currency: PropTypes.string.isRequired,
            paymentMethodId: PropTypes.number
          }),
          refundStatus: PropTypes.shape({
            id: PropTypes.number.isRequired,
            receiverEmail: PropTypes.string.isRequired,
            total: PropTypes.number.isRequired,
            currency: PropTypes.string.isRequired
          }),
          cancellationDetails: PropTypes.shape({
            refundToGuest: PropTypes.number.isRequired,
            payoutToHost: PropTypes.number.isRequired,
            total: PropTypes.number.isRequired,
            currency: PropTypes.string.isRequired,
            guestServiceFee: PropTypes.number.isRequired,
            hostServiceFee: PropTypes.number.isRequired,
          }),
        })),
      }),
    }).isRequired,
    viewReceiptAdmin: PropTypes.any.isRequired,
  };

  static defaultProps = {
    getAllReservations: {
      loading: true,
      getAllReservationAdmin: {
        count: null,
        reservationData: []
      }
    }
  };
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
      selectedRefund: [],
      successRefund: [],
      selectedPayout: [],
      successPayout: [],
    };
    this.paginationData = this.paginationData.bind(this);
    this.changeState = this.changeState.bind(this);
    this.handleSearchChange = debounce(this.handleSearchChange.bind(this));
  }

  changeState(type, value) {
    const { selectedRefund, successRefund, selectedPayout, successPayout } = this.state;
    const { searchList, currentPage, getAllReservations: { refetch } } = this.props;
    let variables = {};

    if (type === 'addRefund') {
      variables = { selectedRefund: [...selectedRefund, value] };
    }
    if (type === 'removeRefund') {
      let index = selectedRefund.findIndex(i => i === value);
      if (index === -1) return '';
      let data = [...selectedRefund];
      data.splice(index, 1)
      variables = { selectedRefund: data };
    }
    if (type === 'successRefund') {
      variables = { successRefund: [...successRefund, value] };
    }

    if (type === 'addPayout') {
      variables = { selectedPayout: [...selectedPayout, value] };
    }
    if (type === 'removePayout') {
      let index = selectedPayout.findIndex(i => i === value);
      if (index === -1) return '';
      let data = [...selectedPayout];
      data.splice(index, 1)
      variables = { selectedPayout: data };
    }
    if (type === 'successPayout') {
      variables = { successPayout: [...successPayout, value] };
      refetch({ currentPage, searchList });
    }
    this.setState(variables)
  }

  paginationData(currentPage) {
    const { getAllReservations: { refetch }, changeStateValues, searchList } = this.props;
    let variables = { currentPage: currentPage || this.state.currentPage, searchList };
    changeStateValues({ currentPage });
    this.setState(variables)
    refetch(variables);
  }

  handleSearchChange(e) {
    const { getAllReservations: { refetch }, changeStateValues } = this.props;
    let variables = {
      currentPage: 1,
      searchList: e.target.value,
    };
    changeStateValues({
      currentPage: 1,
      searchList: e.target.value,
    });
    refetch(variables);
  }

  takeAction = async (id, type) => {
    const { getAllReservations: { refetch } } = this.props;
    let query = `query checkReservationData ($id:Int,$type:String){
          checkReservationData (id:$id,type:$type){
              status,
              errorMessage
          }
      }`;

    const resp = await fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { id, type },
      }),
      credentials: 'include',
    });

    const { data } = await resp.json();
    if (data?.checkReservationData?.status == '200') {
      if (type == 'cancellation') {
        history.push("/siteadmin/cancel/" + id + '/' + 'host')
        return true
      }
    } else {
      toastr.error('Error!', data?.checkReservationData?.errorMessage)
      let variables = {
        searchKey: '',
        currentPage: 1
      };
      await refetch(variables);
      return false
    }
  }

  render() {
    const { data, title, viewReceiptAdmin, getAllReservations, toCurrency, currentPage, searchList, payoutStatus } = this.props;
    const { getAllReservations: { loading, getAllReservationAdmin, refetch } } = this.props;
    const { selectedRefund, successRefund, selectedPayout, successPayout } = this.state;
    let userType = 'host';
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <ModalForm />
        <div>
          <h1 className={s.headerTitle}><FormattedMessage {...messages.adminManageReservation} /></h1>
          <div className={cx(s.exportSection, s.exportSectionGridSub, 'bgBlack')}>
            <div>
              <FormControl
                type="text"
                placeholder={formatMessage(messages.search)}
                onChange={(e) => this.handleSearchChange(e)}
                className={cx('searchInputControl', 'searchInputControlWidth', 'searchInputControlAR')}
              />
            </div>
            <div>
              {
                getAllReservationAdmin && getAllReservationAdmin.reservationData && getAllReservationAdmin.reservationData.length > 0 && <a
                  href={`/export-admin-data?type=reservations&keyword=${searchList}&toCurrency=${toCurrency}`}
                  className={cx(s.exportText, 'commonFloatLeft', 'textWhite')}>
                  <span className={s.vtrMiddle}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                  <span className={cx(s.exportLinkImg, 'exportLinkImgCommon')}>
                    <img src={ExportImage} className={s.exportImg} />
                  </span>
                </a>
              }
            </div>
          </div>
          <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
            <Table className="table"
              noDataText={formatMessage(messages.noRecordFound)}
              // filterable={['Code']}
              sortable={true}
            // itemsPerPage={20}
            >
              <Thead>
                <Th scope="col">{formatMessage(messages.reservationId)}</Th>
                <Th scope="col">{formatMessage(messages.codeLabel)}</Th>
                <Th scope="col">{formatMessage(messages.adminListTitle)}</Th>
                <Th scope="col">{formatMessage(messages.bookingStatus)}</Th>
                <Th scope="col">{formatMessage(messages.bookingAction)}</Th>
                <Th scope="col">{formatMessage(messages.refundToGuest)}</Th>
                <Th scope="col">{formatMessage(messages.subTotalLabel)}</Th>
                <Th scope="col">{formatMessage(messages.payoutLabelAdmin)}</Th>
                <Th scope="col">{formatMessage(messages.details)}</Th>
                <Th scope="col">{formatMessage(messages.setEnableDisable)}</Th>
              </Thead>
              {
                getAllReservationAdmin && getAllReservationAdmin.reservationData && getAllReservationAdmin.reservationData.length > 0 && getAllReservationAdmin.reservationData.map((value, index) => {
                  let subTotal = value.total + value.guestServiceFee;
                  return (
                    <Tr key={index}>
                      <Td data-label={formatMessage(messages.reservationId)} column={formatMessage(messages.reservationId)} data={value.id} />
                      <Td data-label={formatMessage(messages.codeLabel)} column={formatMessage(messages.codeLabel)}>
                        {value.confirmationCode}
                      </Td>
                      {
                        value.listData && <Td data-label={formatMessage(messages.adminListTitle)} column={formatMessage(messages.adminListTitle)}>
                          <a href={"/rooms/" + value.listId} target='_blank'>
                            {value.listData.title}
                          </a>
                        </Td>
                      }
                      {
                        !value.listData && <Td column={formatMessage(messages.adminListTitle)} data={formatMessage(messages.dataMissing)} />
                      }
                      <Td className={s.ChangeToUpperCase} data-label={formatMessage(messages.bookingStatus)} column={formatMessage(messages.bookingStatus)} data={formatReservationState(value.reservationState)} />
                      <Td data-label={formatMessage(messages.bookingAction)} column={formatMessage(messages.bookingAction)}>
                        {
                          value?.reservationState == 'pending' ?
                            <a onClick={() => this.takeAction(value.id, 'request')} target="_blank" href={"/message/" + value?.threadData?.threadId + "/" + userType}>
                              <FormattedMessage {...messages.manageLabel} />
                            </a>
                            :
                            <p>-</p>
                        }
                      </Td>
                      <Td data-label={formatMessage(messages.refundToGuest)} column={formatMessage(messages.refundToGuest)}>
                        <Refund
                          id={value.id}
                          reservationState={value.reservationState}
                          transactionData={value.transaction}
                          refundData={value.refundStatus}
                          cancelData={value.cancellationDetails}
                          selectedRefund={selectedRefund}
                          changeState={this.changeState}
                          successRefund={successRefund}
                        />
                      </Td>
                      <Td data-label={formatMessage(messages.subTotalLabel)} column={formatMessage(messages.subTotalLabel)}>
                        <CurrencyConverter
                          amount={subTotal}
                          from={value.currency}
                        />
                      </Td>
                      <Td data-label={formatMessage(messages.payoutLabelAdmin)} column={formatMessage(messages.payoutLabelAdmin)}>
                        <Payout
                          hostId={value.hostId}
                          checkIn={value.checkIn}
                          id={value.id}
                          hostPayout={value.hostPayout}
                          amount={value.total}
                          currency={value.currency}
                          hostTransaction={value.hostTransaction}
                          reservationState={value.reservationState}
                          cancelData={value.cancellationDetails}
                          hostData={value.hostData}
                          hostServiceFee={value.hostServiceFee}
                          country={value.listData ? value.listData.country : ''}
                          selectedPayout={selectedPayout}
                          successPayout={successPayout}
                          changeState={this.changeState}
                        />
                      </Td>
                      <Td data-label={formatMessage(messages.details)} column={formatMessage(messages.details)}>
                        <Link to={"/siteadmin/viewreservation/" + value.id + '/reservation'} >
                          <FormattedMessage {...messages.viewLabel} />
                        </Link>
                      </Td>
                      <Td data-label={formatMessage(messages.setEnableDisable)} column={formatMessage(messages.setEnableDisable)}>
                        {
                          value.reservationState == 'approved' ?
                            <span onClick={() => this.takeAction(value?.id, 'cancellation')} className={s.cancelBtn}>
                              <FormattedMessage {...messages.deSelect} />
                            </span>
                            :
                            <div>
                              -
                            </div>
                        }
                      </Td>
                    </Tr>
                  )
                })
              }
            </Table>
          </div>
          {
            getAllReservationAdmin && getAllReservationAdmin.reservationData && getAllReservationAdmin.reservationData.length > 0
            && <div>
              <CustomPagination
                total={getAllReservationAdmin.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={10}
                change={this.paginationData}
                paginationLabel={formatMessage(messages.panelReservation)}
              />
            </div>
          }
        </div>
      </div>
    );
  }

}

const mapState = (state) => ({
  completed: state.reservation.completed,
  loading: state.reservation.loading,
  toCurrency: state.currency.to || state.currency.base,
});

const mapDispatch = {
  viewReceiptAdmin,
};

export default compose(injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch)
)(ReservationManagement);

// data={formatReservationState(value.reservationState)}