import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './BecomeHost.css';
import cx from 'classnames';

// Component
import ListPlaceStep1 from '../../components/ListPlaceStep1';
import { Grid, ProgressBar } from 'react-bootstrap';
import { step1Pages, step2Pages, step3Pages } from '.';
import history from '../../core/history';
import SaveButton from '../../components/Header/List/SaveButton';

const textPages = [
  { page: 'house-type', title: 'What kind of property will you host?' },
  { page: 'room', title: 'What type of place will guests have?' },
  { page: 'bedrooms', title: 'Share some basics about your place' },
  { page: 'location', title: 'Where’s your place located?' },
  { page: 'map', title: 'Is the pin in the right spot?' },
]

const urlNoBackground = [
  'house-type',
  'room',
  'congrat'
]

class BecomeHost extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    mode: PropTypes.string,
    listId: PropTypes.number,
    formBaseURI: PropTypes.string,
    mode: PropTypes.string
  };

  render() {
    const { title, formPage, formBaseURI, mode, listId, baseCurrency, step, params } = this.props;
    let inPages = []
    let lastPages
    if (step == 3) {
      inPages = step3Pages
      lastPages = step1Pages.length + step2Pages.length
    } else if (step == 2) {
      inPages = step2Pages
      lastPages = step1Pages.length
    } else {
      inPages = step1Pages
      lastPages = 0
    }
    const urlParams = history?.location?.pathname?.split('/') || []
    let searchParams = history?.location?.search
    console.log(urlParams, params?.listId, 'urlParamsurlParams');
    const stepPage = (inPages.indexOf(urlParams[urlParams.length - 1]) + 1 + lastPages) / (step1Pages.length + step2Pages.length + step3Pages.length)
    const textPage = textPages.find(el => el.page === urlParams[urlParams.length - 1])?.title
    console.log(history?.location?.pathname);
    return (
      <>
        <div>
          {stepPage >= 0 &&
            !history?.location?.search?.includes("mode=new") &&
            !history?.location?.pathname == "/become-a-host/index" && (
              <ProgressBar now={stepPage * 100} className="progressCustom" />
            )}
        </div>
        {textPage && <div className={s.titleText}>{textPage}</div>}
        <Grid
          fluid
          className={
            urlNoBackground.includes(params?.listId) ||
            urlParams?.[3] === 'home' ||
            history?.location?.pathname === "/become-a-host?mode=new"
              ? "dashBoardContainer createListingContainer"
              : "dashBoardContainer"
          }
        >
          <div className={"existingPage"}>
            <ListPlaceStep1
              listId={listId}
              formPage={formPage}
              formBaseURI={formBaseURI}
              mode={mode}
              baseCurrency={baseCurrency}
              step={step}
            />
          </div>
        </Grid>
        <SaveButton
          listId={listId}
          step={step}
          formPage={formPage}
          className={s.saveExit}
          />
      </>
    );
  }
}


export default injectIntl(withStyles(s)(BecomeHost));