exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Withdraw-inputText-2FKmF {\n    border: 1px solid var(--border-secondary-color);\n    border: 1px solid var(--border-secondary-color);\n}", ""]);

// exports
exports.locals = {
	"inputText": "Withdraw-inputText-2FKmF"
};