// General
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { formValueSelector } from 'redux-form';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
// Redux
import { connect } from 'react-redux';

// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ViewListing.css';
import bt from '../../components/commonStyle.css';
import {
  Button,
  Grid,
  Row,
  Collapse,
  Col
} from 'react-bootstrap';
import cx from 'classnames';
import * as FontAwesome from 'react-icons/lib/fa';
import defaultPic from '../../../public/SiteIcons/userNotlogin.svg';
// Components
import Photos from '../../components/ViewListing/Photos';
import ListingIntro from '../../components/ViewListing/ListingIntro';
import Calendar from '../../components/ViewListing/Calendar';
import ListingDetails from '../../components/ViewListing/ListingDetails';
import Reviews from '../../components/ViewListing/Reviews';
import HostDetail from '../../components/ViewListing/HostDetail';
import LocationMap from '../../components/ViewListing/LocationMap';
import Loader from '../../components/Loader';
import NotFound from '../notFound/NotFound';
import AvailabilityCalendar from '../../components/ViewListing/AvailabilityCalendar';
import StarRating from '../../components/StarRating';
import CurrencyConverter from '../../components/CurrencyConverter';
import BookingModal from '../../components/ViewListing/BookingModal';
import SimilarListings from '../../components/ViewListing/SimilarListings';
import HostDetails from '../../components/ViewListing/HostDetails';

// Graphql
import BlockedDatesQuery from './BlockedDates.graphql';
import ListingDataQuery from './getListingData.graphql';
import MoreReviewsQuery from './MoreReviews.graphql';
import SimilarListsQuery from './getSimilarListing.graphql';
import AutoAffix from 'react-overlays/lib/AutoAffix';
import starIcon from '../../../public/SiteIcons/Starcolor.svg';
import { contactHostOpen } from '../../actions/message/contactHostModal';

// ES6 Imports
import Scroll from 'react-scroll'; // Imports all Mixins

// Locale
import messages from '../../locale/messages';

import { openBookingModal } from '../../actions/BookingModal/modalActions';
import moment from 'moment';
import ThingsToKnow from '../../components/ViewListing/ThingsToKnow/ThingsToKnow';

// Or Access Link,Element,etc as follows
let Link = Scroll.Link;
let Element = Scroll.Element;
let Events = Scroll.Events;
let scroll = Scroll.animateScroll;
let scrollSpy = Scroll.scrollSpy;
var durationFn = function (deltaTop) {
  return deltaTop;
};

function formatDate(date) {
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  if (diffDays < 7) {
      if (diffDays === 1) {
          return '1 day ago';
      } else {
          return `${diffDays} days ago`;
      }
  } else if (diffDays < 30) {
      const diffWeeks = Math.floor(diffDays / 7);
      if (diffWeeks === 1) {
          return '1 week ago';
      } else {
          return `${diffWeeks} weeks ago`;
      }
  } else if (diffDays < 365) {
      const diffMonths = Math.floor(diffDays / 30);
      if (diffMonths === 1) {
          return '1 month ago';
      } else {
          return `${diffMonths} months ago`;
      }
  } else {
      const diffYears = Math.floor(diffDays / 365);
      if (diffYears === 1) {
          return '1 year ago';
      } else {
          return `${diffYears} years ago`;
      }
  }
}

class ViewListing extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    listId: PropTypes.number.isRequired,
    preview: PropTypes.bool,
    isAdmin: PropTypes.bool,
    account: PropTypes.shape({
      userId: PropTypes.string,
      userBanStatus: PropTypes.number,
    }),
    ListingBlockedDates: PropTypes.shape({
      loading: PropTypes.bool,
      UserListing: PropTypes.shape({
        blockedDates: PropTypes.array
      })
    }),
    getListingData: PropTypes.shape({
      loading: PropTypes.bool,
      UserListing: PropTypes.object
    }),
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    similarListsData: PropTypes.shape({
      loading: PropTypes.bool,
      getSimilarListing: PropTypes.array
    }),
    openBookingModal: PropTypes.func,
  };
  static defaultProps = {
    getListingData: {
      loading: false,
      UserListing: {
        userId: null
      }
    },
    ListingBlockedDates: {
      loading: true,
      UserListing: {
        blockedDates: []
      }
    },
    account: {
      userId: null,
      userBanStatus: 0,
    },
    isAdmin: false
  }

  constructor(props) {
    super(props);
    this.state = {
      open1: false,
      open2: false,
      open3: false,
    }
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState({ open1: !this.state.open1 })
  }
  render() {
    const { listId, title, getListingData: { loading, UserListing }, preview, averageBasePrice, contactHostOpen } = this.props;
    const { ListingBlockedDates } = this.props;
    const { listReviewsData } = this.props;
    const { openBookingModal, baseCurrency } = this.props;
    const { account: { userId, userBanStatus }, isAdmin } = this.props;
    const { lat, lng, startDate, endDate, guests, similarListsData, similarListsData: { getSimilarListing } } = this.props;
    const isBrowser = typeof window !== 'undefined';
    const smallDevice = isBrowser ? window.matchMedia('(max-width: 640px)').matches : undefined;

    let basePriceValue = UserListing && UserListing.listingData && UserListing.listingData.basePrice ? UserListing.listingData.basePrice : 0;
    let currencyValue = UserListing && UserListing.listingData && UserListing.listingData.currency ? UserListing.listingData.currency : "AED";

    if (loading && !UserListing) {
      return (
        <div className={cx(s.loaderMobileView, 'min-h-[500px]')}>
          <Loader type="text" />
        </div>
      )
    }
    const currentDate = moment();
    
    // Get the date of next 30 days from now
    const next30Days = currentDate.add(30, 'days');
    
    // Format the date as "DD MMM" (e.g., 14 Mar)
    const nextDate = next30Days.format('DD MMM');
    let isHost = false;
    if (UserListing) {
      if (userId && userId === UserListing.userId) {
        isHost = true;
      } else if (isAdmin) {
        isHost = true;
      }
    }
    let date = moment(UserListing?.user?.profile?.createdAt).format("MMMM YYYY");
    if (preview && !isHost) {
      return <NotFound title={title} />
    }
    if (!loading && !UserListing) {
      return <NotFound title={title} />
    }
    let reviewsCount = UserListing.reviewsCount;
    let reviewsStarRating = UserListing.reviewsStarRating;
    let starRatingValue = 0;
    if (reviewsCount > 0 && reviewsStarRating > 0) {
      starRatingValue = Math.round(reviewsStarRating / reviewsCount)
    }
   
    return (
      <div className={s.root}>
        <div className={s.container}>
          {
            UserListing && <div className={s.pageContainer}>
              <div className={s.pageContent}>
                <HostDetails data={UserListing}
                  reviewsCount={UserListing.reviewsCount}
                  reviewsStarRating={UserListing.reviewsStarRating}
                  loading={loading}
                />
              </div>
              <div className={(s.pageContent)}>
                <Photos data={UserListing} loading={loading} />
              </div>
              <div className={cx(s.horizontalLineThrough)}>
                <Row className={cx(s.pageContent, s.gridTop, s.safariTop)}>
                  <Col xs={12} sm={12} md={8} lg={8} className='2xl:pr-[120px] pr-[50px]'>
                    <Row>
                      <div className={s.stickyContainer}>
                        <AutoAffix viewportOffsetTop={0} container={this} affixClassName={s.showAffix}>
                          <div className={cx(s.zPanel, s.panelDefault, 'bgBlack')}>
                            <div className={cx("stickHeader", s.viewHeader)}>
                              <div className={s.headerNav}>
                                <div className={s.textColor}>
                                  <ul className={cx('list-inline', s.stickyMenu)}>
                                    <li>
                                      <Link className={cx(s.textList)} activeClass={cx(s.active, 'textWhite')} to="test1" spy={true} smooth={true} offset={-50} duration={800} onSetActive={this.handleSetActive}>
                                        <FormattedMessage {...messages.overview} />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className={cx(s.textList)} activeClass={cx(s.active, 'textWhite')} to="test2" spy={true} smooth={true} offset={-80} duration={800} onSetActive={this.handleSetActive}>
                                        <FormattedMessage {...messages.reviews} />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className={cx(s.textList)} activeClass={cx(s.active, 'textWhite')} to="test3" spy={true} smooth={true} offset={-70} duration={800} onSetActive={this.handleSetActive}>
                                        <FormattedMessage {...messages.theHost} />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className={cx(s.textList)} activeClass={cx(s.active, 'textWhite')} to="test4" spy={true} smooth={true} offset={-70} duration={800} onSetActive={this.handleSetActive}>
                                        <FormattedMessage {...messages.location} />
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AutoAffix>
                      </div>
                      <Element name="test1" className="element">
                        <div className={(s.listingIntroSection)}>
                          <div className={s.pageTitle}>
                            {UserListing?.title} in {UserListing.city && UserListing.city.toString().trimEnd()}, {UserListing.state && UserListing.state.toString().trimEnd()}, {UserListing.country}
                          </div>
                        </div>
                        <ListingDetails
                          data={UserListing}
                          isHost={isHost}
                          userBanStatus={userBanStatus}
                          urlParameters={{ listTitle: UserListing.title, startDate, endDate, guests }}
                        />
                      </Element>
                      <div>
                        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                          <div className="flex flex-col w-[36%] max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col grow items-center px-12 py-6 rounded border border-solid border-zinc-500 max-md:px-5 max-md:mt-10">
                              <img
                                loading="lazy"
                                srcSet={UserListing.user.profile.picture?'/images/avatar/'+UserListing.user.profile.picture: defaultPic}
                                className="max-w-full rounded-full aspect-square w-[100px]"
                              />
                              <div className="mt-6 text-2xl font-semibold text-center text-gray-200 whitespace-nowrap">
                                {UserListing.user.profile.firstName}
                              </div>
                              <div className="flex gap-2 mt-6 text-base text-blue-400">
                                <div className={s.starText}>
                                <img
                                  loading="lazy"
                                  srcSet={starIcon}
                                  className="shrink-0 w-6 aspect-[1.67]"
                                />
                                5
                                </div>
                                <div className="my-auto underline">24 ratings</div>
                              </div>
                              <div className="flex gap-2.5 justify-center mt-4 text-base text-gray-200 whitespace-nowrap">
                                <img
                                  loading="lazy"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/ac14371f2bce35292ec6ae5079e1f5724c01d00cef685c34397a914595823ef6?"
                                  className="shrink-0 w-6 aspect-square"
                                />
                                <div className="grow my-auto">Verified</div>
                              </div>
                              <div className="flex flex-col justify-center p-0.5 mt-16 max-w-full text-base font-medium text-center text-gray-200 whitespace-nowrap bg-white rounded-md shadow w-[126px] max-md:mt-10">
                                <div className="justify-center px-2.5 py-2.5 rounded bg-neutral-800 cursor-pointer" onClick={() => contactHostOpen(UserListing.id, { listTitle: UserListing.title, startDate, endDate, guests })}>
                                  Contact Host
                                </div>
                              </div>
                              <div className="flex gap-5 justify-between mt-10">
                                <img
                                  loading="lazy"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/6401e835b3b806f84475bca7903fedaa22a45491ead23e6eca1a9c4d1b51fd52?"
                                  className="shrink-0 w-6 aspect-square"
                                />
                                <img
                                  loading="lazy"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/0e17aab2833a4f59e8c92f752610572252206e3e3aefa2ea098c3d32e5e781d0?"
                                  className="shrink-0 w-6 aspect-square"
                                />
                              </div>
                              <div className="shrink-0 self-stretch mt-4 h-px border border-solid bg-zinc-500 border-zinc-500" />
                              <div className="self-stretch mt-4 text-sm text-center whitespace-nowrap text-zinc-400">
                                Member since {date}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col ml-5 w-[64%] max-md:ml-0 max-md:w-full">
                            <div className={s.reviewTxt}>{listReviewsData?.moreListReviews?.length> 0? `${listReviewsData?.moreListReviews?.length} reviews`:'No review'}</div>
                            <div className="flex flex-col px-5 mt-14 max-md:mt-10 max-md:max-w-full">
                              {listReviewsData?.moreListReviews?.map((item, index) => {
                                let dateRv = formatDate(new Date(item.createdAt));
                                console.log(item, 'itemitem');
                                return (
                                  <div>
                                    <div className="flex gap-4 self-start">
                                      <img
                                        loading="lazy"
                                        srcSet={item.authorData.picture?'/images/avatar/'+item.authorData.picture: defaultPic}
                                        className="shrink-0 w-10 rounded-full aspect-square"
                                      />
                                      <div className="flex flex-col flex-1 self-start">
                                        <div className="text-base font-semibold text-gray-200 whitespace-nowrap">
                                          {item.authorData.firstName}
                                        </div>
                                        <div className="flex gap-0.5 pr-3 mt-1">
                                          <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/54572a07b07f43b4721c8163e59cc05aecf8571fe5f61cf004e8d8e904b705d7?"
                                            className="shrink-0 w-4 mr-[2px] aspect-square fill-gray-200"
                                          />
                                          <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/54572a07b07f43b4721c8163e59cc05aecf8571fe5f61cf004e8d8e904b705d7?"
                                            className="shrink-0 w-4 mr-[2px] aspect-square fill-gray-200"
                                          />
                                          <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/54572a07b07f43b4721c8163e59cc05aecf8571fe5f61cf004e8d8e904b705d7?"
                                            className="shrink-0 w-4 mr-[2px] aspect-square fill-gray-200"
                                          />
                                          <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/54572a07b07f43b4721c8163e59cc05aecf8571fe5f61cf004e8d8e904b705d7?"
                                            className="shrink-0 w-4 mr-[2px] aspect-square fill-gray-200"
                                          />
                                          <img
                                            loading="lazy"
                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/54572a07b07f43b4721c8163e59cc05aecf8571fe5f61cf004e8d8e904b705d7?"
                                            className="shrink-0 w-4 mr-[2px] aspect-square fill-gray-200"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mt-4 text-base text-zinc-400 max-md:max-w-full">
                                      {
                                        item?.reviewContent && item.reviewContent?.trim() && (item.reviewContent.trim()).split("\n").map(function (content, index) {
                                          return (
                                            <span key={index}>
                                              {content}
                                              <br />
                                            </span>
                                          )
                                        })
                                      }
                                    </div>
                                    <div className="mt-2 text-sm text-zinc-400 max-md:max-w-full">
                                      {dateRv}
                                    </div>
                                  </div>
                                )
                              })}

                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className={'availabilityMobile'}>
                        <AvailabilityCalendar
                          listId={listId}
                          smallDevice={smallDevice}
                          loading={ListingBlockedDates.loading}
                          blockedDates={
                            ListingBlockedDates.UserListing != null ?
                              ListingBlockedDates.UserListing.blockedDates : undefined
                          }
                          listingData={UserListing.listingData || undefined}
                          country={UserListing.country}
                          queryStartDate={startDate}
                          queryEndDate={endDate}
                        />
                      </div> */}
                      {/* <Element name="test2" className="element">

                        <Reviews
                          reviewsCount={UserListing.reviewsCount}
                          reviewsStarRating={UserListing.reviewsStarRating}
                          data={listReviewsData}
                          listId={listId}
                        />
                      </Element> */}
                      <div className={s.listingLine}></div>
                      <Element name="test4" className="element">
                        <LocationMap data={UserListing} />
                      </Element>
                      <div className='min-h-[280px]'>
                      <div className={cx(s.space2)}>
          <h1 className={cx(s.sectionTitleText, 'mb-[32px] mt-0')}>Things to know</h1>
        </div>
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col text-sm text-gray-200 max-md:mt-10">
            <div className="text-base font-semibold">House rules</div>
           
            {!this.state.open1 && UserListing?.houseRules?.slice(0,3)?.map((el, key) => (<div className="mt-2.5" key={key}>{el?.listsettings?.itemName}</div>))}
            {
                UserListing && UserListing?.houseRules?.length > 3 &&
                <span>
                  <Collapse in={open}>
                    <div>
                    {this.state.open1 && UserListing?.houseRules?.map(el => (<div className="mt-2.5">{el?.listsettings?.itemName}</div>))}
                      </div>
                  </Collapse>
                  {
                    <div >
                      <div >
                      <div className="flex gap-0.5 self-start mt-6 font-medium cursor-pointer" onClick={() => this.setState({ open1: !this.state.open1 })}>
                      <div className="underline"> {this.state.open1 ? <FormattedMessage {...messages.closeAll} /> : <FormattedMessage {...messages.showDescription} />}</div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff9814e98c54b8552e278a6ff84dde24d3ce8b88f65c22f0bd6727adbdd50354?"
                        className="shrink-0 self-start w-[14px] mt-[3px] aspect-square"
                      />
                    </div>
                      </div>
                    </div>
                  }
                </span>
              }
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col text-sm text-gray-200 max-md:mt-10">
            <div className="text-base font-semibold">Safety</div>
            {!this.state.open2 && UserListing?.userSafetyAmenities?.slice(0,3)?.map((el, key) => (<div className="mt-2.5" key={key}>{el?.listsettings?.itemName}</div>))}
            {
                UserListing && UserListing?.userSafetyAmenities?.length > 3 &&
                <span>
                  <Collapse in={open}>
                    <div>
                    {this.state.open2 && UserListing?.userSafetyAmenities?.map(el => (<div className="mt-2.5">{el?.listsettings?.itemName}</div>))}
                      </div>
                  </Collapse>
                  {
                    <div >
                      <div >
                      <div className="flex gap-0.5 self-start mt-6 font-medium cursor-pointer" onClick={() => this.setState({ open2: !this.state.open2 })}>
                      <div className="underline"> {this.state.open2 ? <FormattedMessage {...messages.closeAll} /> : <FormattedMessage {...messages.showDescription} />}</div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff9814e98c54b8552e278a6ff84dde24d3ce8b88f65c22f0bd6727adbdd50354?"
                        className="shrink-0 self-start w-[14px] mt-[3px] aspect-square"
                      />
                    </div>
                      </div>
                    </div>
                  }
                </span>
              }
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow text-sm text-gray-200 max-md:mt-10">
            <div className="text-base font-semibold">Cancellation policy</div>
{/*            <div className="mt-2.5">Cancel before {nextDate} for a partial refund.*/}
{/*</div>*/}
<div className="mt-2.5">
Review the Host’s full cancellation policy which applies even if you cancel.</div>
          </div>
        </div>
      </div>
    </div>
                      {/* <Element name="test5" className="element">
                      <ThingsToKnow data={UserListing}/>
                      </Element> */}
                      {/* <Element name="test3" className="element">
                        <HostDetail
                          id={UserListing.id}
                          userId={UserListing.userId}
                          hostEmail={UserListing.user.email}
                          personCapacity={UserListing.personCapacity}
                          city={UserListing.city}
                          listingData={UserListing.listingData || undefined}
                          profile={UserListing.user.profile || undefined}
                          blockedDates={
                            ListingBlockedDates.UserListing != null ?
                              ListingBlockedDates.UserListing.blockedDates : undefined
                          }
                          isHost={isHost}
                          userBanStatus={userBanStatus}
                          country={UserListing.country}
                          urlParameters={{ listTitle: UserListing.title, startDate, endDate, guests }}
                        />
                      </Element> */}
                    </Row>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} className={cx(s.positionSticky, 'pl-[40px]')} >
                    {
                      !smallDevice && !loading && <div className={cx(s.responsiveNopadding, 'hidden-xs hidden-sm')}>
                        <Calendar
                          id={UserListing.id}
                          loading={ListingBlockedDates.loading}
                          blockedDates={
                            ListingBlockedDates.UserListing != null ?
                              ListingBlockedDates.UserListing.blockedDates : undefined
                          }
                          personCapacity={UserListing.personCapacity}
                          listingData={UserListing.listingData || undefined}
                          isHost={isHost}
                          bookingType={UserListing.bookingType}
                          userBanStatus={userBanStatus}
                          startDate={startDate}
                          endDate={endDate}
                          reviewsCount={UserListing.reviewsCount}
                          reviewsStarRating={UserListing.reviewsStarRating}
                          guests={guests}
                          country={UserListing.country}
                        />

                      </div>
                    }
                    {
                      !smallDevice && loading && <div className={cx(s.webDevice, s.responsiveNopadding, 'hidden-xs hidden-sm')}>
                        <Calendar
                          id={UserListing.id}
                          loading={ListingBlockedDates.loading}
                          blockedDates={
                            ListingBlockedDates.UserListing != null ?
                              ListingBlockedDates.UserListing.blockedDates : undefined
                          }
                          personCapacity={UserListing.personCapacity}
                          listingData={UserListing.listingData || undefined}
                          isHost={isHost}
                          bookingType={UserListing.bookingType}
                          userBanStatus={userBanStatus}
                          startDate={startDate}
                          endDate={endDate}
                          reviewsCount={UserListing.reviewsCount}
                          reviewsStarRating={UserListing.reviewsStarRating}
                          guests={guests}
                          country={UserListing.country}
                        />

                      </div>
                    }
                  
                  </Col>
                </Row>
                {/* {
                  similarListsData && similarListsData.getSimilarListing && similarListsData.getSimilarListing.length > 0 &&
                  <div className={cx(s.space2, s.noPaddingMobile,
                    s.pageContent, s.similarPadding)}>
                    <div className={cx(
                      s.sliderMargin, 'similarListing', s.similarPadding)}>
                      <div
                        className={cx(s.similarPaddingText)}>
                        <h2 className={cx(s.sectionTitleText)}><FormattedMessage {...messages.similarListings} /></h2>
                      </div>
                      <SimilarListings data={similarListsData.getSimilarListing} hideHeading={true} viewListingSimilarItem={'viewListingSimilarItem'} />
                    </div>
                  </div>
                } */}
              </div>
              
            </div>
          }
          <div className={cx(s.stickyBookButton, 'visible-sm visible-xs')}>
            <div className={cx(s.filtersFooter)}>
              <div className={cx(s.filtersContainer, 'bgBlackTwo')}>
                <Row className={s.footerContainer}>
                  <div className={cx(s.displayTable, s.displayTableMobile)}>
                    <div className={cx(s.smSpace, s.displayTableCell, s.displayBlockMobile)}>
                      <div className={(s.bookItPriceAmount)}>
                        <div className={s.bookItPrice}>
                          <CurrencyConverter
                            from={currencyValue || baseCurrency}
                            amount={averageBasePrice ? averageBasePrice : (basePriceValue || 0)}
                            className={s.bookItPrice}
                          />
                          {/* <span className={s.bookItNight}>{' '}/ <FormattedMessage {...messages.perNight} /></span> */}
                          {/* {
                            UserListing && UserListing.bookingType === "instant" && <span>
                              <FontAwesome.FaBolt className={s.instantIcon} />
                            </span>
                          } */}
                        </div>
                      </div>
                      {starRatingValue > 0 && <div>
                        <div className={cx(s.reviewSection, 'reviewSectionRTL')}>
                          <StarRating name={'review'} value={starRatingValue} />
                        </div>
                        <div>
                          <span>{starRatingValue}</span>
                        </div>
                      </div>}
                    </div>
                    <div className={cx(s.displayTableCell, s.displayBlockMobile)}>
                      <BookingModal
                        id={UserListing.id}
                        loading={ListingBlockedDates.loading}
                        blockedDates={
                          ListingBlockedDates.UserListing != null ?
                            ListingBlockedDates.UserListing.blockedDates : undefined
                        }
                        personCapacity={UserListing.personCapacity}
                        listingData={UserListing.listingData || undefined}
                        isHost={isHost}
                        bookingType={UserListing.bookingType}
                        reviewsCount={UserListing.reviewsCount}
                        reviewsStarRating={UserListing.reviewsStarRating}
                        country={UserListing.country}
                        startDate={startDate}
                        endDate={endDate}
                      />
                      <Button
                        className={cx(s.btn, bt.btnPrimary, s.fullWidth)}
                        onClick={openBookingModal}
                      >
                        <FormattedMessage {...messages.bookNow} />
                      </Button>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const selector = formValueSelector('BookingForm');

const mapState = (state) => ({
  account: state.account.data,
  isAdmin: state.runtime.isAdminAuthenticated,
  averageBasePrice: selector(state, 'averageBasePrice'),
});
const mapDispatch = {
  openBookingModal,
  contactHostOpen
};
export default compose(
  injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch),
  graphql(ListingDataQuery,
    {
      name: 'getListingData',
      options: (props) => ({
        variables: {
          listId: props.listId,
          preview: props.preview,
        },
        fetchPolicy: 'network-only',
        ssr: true
      })
    }
  ),
  graphql(BlockedDatesQuery,
    {
      name: 'ListingBlockedDates',
      options: (props) => ({
        variables: {
          listId: props.listId,
          preview: props.preview,
        },
        fetchPolicy: 'network-only',
        ssr: false
      })
    }
  ),
  graphql(MoreReviewsQuery,
    {
      name: 'listReviewsData',
      options: (props) => ({
        variables: {
          listId: props.listId,
        },
        fetchPolicy: 'network-only',
        ssr: false
      })
    }
  ),
  graphql(SimilarListsQuery,
    {
      name: 'similarListsData',
      options: (props) => ({
        variables: {
          listId: props.listId,
          lat: props.lat,
          lng: props.lng
        },
        fetchPolicy: 'network-only',
        ssr: false
      })
    }
  )
)(ViewListing);
