import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Button, Label, OverlayTrigger, Tooltip } from "react-bootstrap";
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";

// helpers
import history from "../../../core/history";
import messages from "../../../locale/messages";
import { removePayout } from "../../../actions/Payout/removePayoutAction";
import { setDefaultPayout } from "../../../actions/Payout/setDefaultPayout";
import { verifyPayout } from "../../../actions/Payout/verifyPayout";

//Images
import tootipIcon from "../../../../public/SiteIcons/editInfoTipIcon.svg";
import delteIcon from "../../../../public/SiteIcons/deleteIcon.svg";
import addIcon from "../../../../public/SiteIcons/addListIcon.svg";

//style
import s from "../Payout.css";
import bt from "../../../components/commonStyle.css";

class PayoutList extends Component {
  static defaultProps = {
    payoutRemoveLoader: false,
    payoutDefaultLoader: false,
    payoutVerifyLoader: false,
    data: [],
  };

  handleClick() {
    history.push("/user/addpayout");
  }

  render() {
    const {
      data,
      removePayout,
      setDefaultPayout,
      currentAccountId,
      verifyPayout,
      userId,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const { payoutRemoveLoader, payoutDefaultLoader, payoutVerifyLoader } =
      this.props;

    return (
      <div>
        <div className="mb-4 text-3xl font-semibold">Payment & Payout</div>
        <p className={cx(s.textMuted, "textWhite")}>
          <FormattedMessage {...messages.payoutTitleBlock1} />
        </p>

        <div className="flex mt-6 overflow-x-auto space-x-6 flex-nowrap">
          <div
            className="flex h-[255px] w-24 rounded-xl cursor-pointer shadow border border-border-secondary items-center justify-center"
            onClick={this.handleClick}
          >
            <svg
              fill="#cbd5e1"
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 45.402 45.402"
            >
              <path
                d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141
		c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27
		c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435
		c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z"
              />
            </svg>
          </div>

          {data.length > 0 &&
            data.map((item, index) => {
              return (
                <div
                  key={index}
                  className="relative inline-flex flex-col items-center space-y-3"
                >
                  <div className="shadow-md h-[255px] w-[455px] rounded-xl bg-gradient-to-r from-slate-900 to-indigo-900 relative">
                    <div className="absolute left-8 top-10 text-[20px] text-white font-medium">
                      {item.paymentMethod.name}
                    </div>

                    <div className="left-8 bottom-10 absolute text-white">
                      {item.methodId == 1 && <span>{item.payEmail}</span>}
                      {item.methodId == 2 && (
                        <span className={"rtlPayoutEmail"}>
                          ******{item.last4Digits}
                        </span>
                      )}
                      <span className={"rtlPayoutEmail"}>
                        ({item.currency})
                      </span>
                    </div>
                  </div>
                  <div className='flex gap-3'>
                  {item.default ? (
                    <div className="bg-slate-900 px-6 py-1.5 rounded-full text-white">
                      Default
                    </div>
                  ) : null}

                  {!item.default && item.isVerified === true && (
                    <div
                      className="py-1.5 cursor-pointer"
                      onClick={() => {
                        if (!payoutDefaultLoader && !payoutRemoveLoader) {
                          setDefaultPayout(item.id);
                        }
                      }}
                    >
                      Set as default
                    </div>
                  )}

                  {!item.default && item.isVerified !== true && (
                    <div
                      className="py-1.5 cursor-pointer"
                      onClick={() => {
                        if (!payoutVerifyLoader && !payoutRemoveLoader) {
                          verifyPayout(item.payEmail, userId);
                        }
                      }}
                    >
                      Verify
                    </div>
                  )}

                  {item.default ? (
                    <div onClick={() => {
                      if (!payoutRemoveLoader && !payoutDefaultLoader) {
                        removePayout(item.id);
                      }
                    }}
                     className="bg-slate-900 px-6 py-1.5 rounded-full text-white cursor-pointer">
                      Remove
                    </div>
                  ) : null}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  payoutRemoveLoader: state.loader.payoutRemove,
  payoutDefaultLoader: state.loader.payoutDefault,
  payoutVerifyLoader: state.loader.payoutVerify,
  userId: state.account.data.userId,
});

const mapDispatch = {
  removePayout,
  setDefaultPayout,
  verifyPayout,
};

export default injectIntl(
  withStyles(s, bt)(connect(mapState, mapDispatch)(PayoutList))
);
