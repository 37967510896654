import messages from '../../../locale/messages';

const validate = values => {

  const errors = {}
  if (!values.amountUSD) {
    errors.amountUSD = messages.required;
  } else if (isNaN(values.amountUSD)) {
    errors.amountUSD = messages.onlyNumericKey;
  }

  return errors
}

export default validate;