import React from 'react';
import UserLayout from '../../components/Layout/UserLayout';
import messages from '../../locale/messages';
import ImportListing from '../.../../../components/ImportListing';

const title = 'Staking';

export default function action({ store, intl }) {

  const title = 'Import listing';

  // From Redux Store
  let isAuthenticated = store.getState().runtime.isAuthenticated;

  if (!isAuthenticated) {
    return { redirect: '/login' };
  }

  return {
    title,
    component: <UserLayout><ImportListing title={title} /></UserLayout>,
  };
};
