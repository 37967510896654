// import Lottie from 'react-lottie';
import React, { useEffect, useRef, useState } from "react";
const animationLoading = {
  v: "5.7.1",
  ip: 0,
  op: 80,
  fr: 60,
  w: 64,
  h: 64,
  nm: "Ã",
  assets: [],
  layers: [
    {
      ind: 1,
      ty: 4,
      nm: "Ã",
      sr: 1.0,
      ks: {
        o: { a: 0, k: 100.0 },
        r: { a: 0, k: 0.0 },
        p: { a: 0, k: [50.0, 50.0, 0.0] },
        a: { a: 0, k: [0.0, 0.0, 0.0] },
        s: { a: 0, k: [100.0, 100.0, 100.0] },
      },
      shapes: [
        {
          ty: "gr",
          nm: "Ã",
          bm: 0,
          it: [
            {
              ty: "rc",
              nm: "Ã",
              d: 1,
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 12,
                    s: [28.0, 28.0],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 20,
                    s: [28.0, 64.0],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 40,
                    s: [28.0, 28.0],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 72,
                    s: [28.0, 28.0],
                  },
                  { t: 80, s: [64.0, 28.0] },
                ],
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 12,
                    s: [0.0, 0.0],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 20,
                    s: [0.0, -18.0],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 40,
                    s: [0.0, -36.0],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 72,
                    s: [0.0, -36.0],
                  },
                  { t: 80, s: [-18.0, -36.0] },
                ],
              },
              r: { a: 0, k: 8.0 },
            },
            {
              ty: "fl",
              nm: "Ã",
              bm: 0,
              c: {
                a: 0,
                k: [0.360784322023, 0.368627458811, 0.901960790157, 1.0],
              },
              o: { a: 0, k: 100.0 },
              r: 1,
            },
            {
              ty: "tr",
              o: { a: 0, k: 100.0 },
              r: { a: 0, k: 0.0 },
              p: { a: 0, k: [0.0, 0.0] },
              a: { a: 0, k: [0.0, 0.0] },
              s: { a: 0, k: [100.0, 100.0] },
              nm: "Ã",
              sk: { a: 0, k: 0.0 },
              sa: { a: 0, k: 0.0 },
            },
          ],
        },
      ],
      ip: 0,
      op: 300,
      st: 0.0,
    },
    {
      ind: 2,
      ty: 4,
      nm: "Ã",
      sr: 1.0,
      ks: {
        o: { a: 0, k: 100.0 },
        r: { a: 0, k: 0.0 },
        p: { a: 0, k: [14.0, 50.0, 0.0] },
        a: { a: 0, k: [0.0, 0.0, 0.0] },
        s: { a: 0, k: [100.0, 100.0, 100.0] },
      },
      shapes: [
        {
          ty: "gr",
          nm: "Ã",
          bm: 0,
          it: [
            {
              ty: "rc",
              nm: "Ã",
              d: 1,
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 32,
                    s: [28.0, 28.0],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 40,
                    s: [64.0, 28.0],
                  },
                  { t: 60, s: [28.0, 28.0] },
                ],
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 32,
                    s: [0.0, 0.0],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 40,
                    s: [18.0, 0.0],
                  },
                  { t: 60, s: [36.0, 0.0] },
                ],
              },
              r: { a: 0, k: 8.0 },
            },
            {
              ty: "fl",
              nm: "Ã",
              bm: 0,
              c: {
                a: 0,
                k: [0.360784322023, 0.368627458811, 0.901960790157, 1.0],
              },
              o: { a: 0, k: 100.0 },
              r: 1,
            },
            {
              ty: "tr",
              o: { a: 0, k: 100.0 },
              r: { a: 0, k: 0.0 },
              p: { a: 0, k: [0.0, 0.0] },
              a: { a: 0, k: [0.0, 0.0] },
              s: { a: 0, k: [100.0, 100.0] },
              nm: "Ã",
              sk: { a: 0, k: 0.0 },
              sa: { a: 0, k: 0.0 },
            },
          ],
        },
      ],
      ip: 0,
      op: 300,
      st: 0.0,
    },
    {
      ind: 3,
      ty: 4,
      nm: "Ã",
      sr: 1.0,
      ks: {
        o: { a: 0, k: 100.0 },
        r: { a: 0, k: 0.0 },
        p: { a: 0, k: [32.0, 14.0, 0.0] },
        a: { a: 0, k: [0.0, 0.0, 0.0] },
        s: { a: 0, k: [100.0, 100.0, 100.0] },
      },
      shapes: [
        {
          ty: "gr",
          nm: "Ã",
          bm: 0,
          it: [
            {
              ty: "rc",
              nm: "Ã",
              d: 1,
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 0,
                    s: [64.0, 28.0],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 20,
                    s: [28.0, 28.0],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 52,
                    s: [28.0, 28.0],
                  },
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 60,
                    s: [28.0, 64.0],
                  },
                  { t: 80, s: [28.0, 28.0] },
                ],
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 0,
                    s: [0.0, 0.0],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 20,
                    s: [-18.0, 0.0],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 52,
                    s: [-18.0, 0.0],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 60,
                    s: [-18.0, 18.0],
                  },
                  { t: 80, s: [-18.0, 36.0] },
                ],
              },
              r: { a: 0, k: 8.0 },
            },
            {
              ty: "fl",
              nm: "Ã",
              bm: 0,
              c: {
                a: 0,
                k: [0.360784322023, 0.368627458811, 0.901960790157, 1.0],
              },
              o: { a: 0, k: 100.0 },
              r: 1,
            },
            {
              ty: "tr",
              o: { a: 0, k: 100.0 },
              r: { a: 0, k: 0.0 },
              p: { a: 0, k: [0.0, 0.0] },
              a: { a: 0, k: [0.0, 0.0] },
              s: { a: 0, k: [100.0, 100.0] },
              nm: "Ã",
              sk: { a: 0, k: 0.0 },
              sa: { a: 0, k: 0.0 },
            },
          ],
        },
      ],
      ip: 0,
      op: 300,
      st: 0.0,
    },
  ],
  markers: [],
};
const LoadingAnimation = () => {
  const Lottie = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      // import('react-lottie').then(module => {
      //   setLottie(module.default);
      // });
      (async () => {
        Lottie.current = (await import("react-lottie"))?.default;
      })();
    }
  }, []);
  return (
    <div className="h-[100%] flex items-center pb-[40px]">
      {Lottie.current && (
        <Lottie.current
          options={{
            loop: true,
            autoplay: true,
            animationData: animationLoading,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={100}
          width={100}
        />
      )}
    </div>
  );
};
export default LoadingAnimation;
