import React from 'react';
import Layout from '../../components/Layout';
import messages from '../../locale/messages';
import ForgotPassword from './ForgotPassword';

const title = 'Forgot Password';

export default function action({ intl }) {

  return {
    title,
    component: <Layout><ForgotPassword title={title} /></Layout>,
  };
}
