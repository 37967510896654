import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Home.css";
import cx from "classnames";
import { injectIntl } from "react-intl";

//Components
import BannerCaption from "../../components/Home/BannerCaption";
import HomeSlider from "../../components/Home/HomeSlider/HomeSlider";
import PopularHost from "../../components/Home/PopularHost/PopularHost.js";
import MostViewed from "../../components/Home/MostViewed/MostViewed.js";
import NewsBox from "../../components/Home/NewsBox";
import SearchForm from "../../components/Home/SearchForm";
import Loader from "../../components/Loader";
import HomeKindofTrip from "../../components/Home/HomeKindofTrip";
import PopularLocationGrid from "../../components/Home/PopularLocationGrid";
import PopularLocation from "../../components/Home/PopularLocation";
import Layout4 from "../../components/Home/Layout4";
import Layout1 from "../../components/Home/Layout1";
import Layout3 from "../../components/Home/Layout3";
import Layout5 from "../../components/Home/Layout5";
import Animation from "../../components/Home/Layout5/animation.jsx";
import Feedback from "../../components/Feedback";
import { connect } from "react-redux";
import messages from "../../../src/locale/messages.js";

// Graphql
import getRecommendQuery from "./getRecommend.graphql";
import getMostViewedListingQuery from "./getMostViewedListing.graphql";
import getPopularLocationQuery from "./getPopularLocation.graphql";
import getImageBannerQuery from "./getImageBanner.graphql";
import getStaticBlockInfoQuery from "./getStaticBlockInfo.graphql";
import MobileHeader from "../../../src/components/Header/MobileHeader.js"
// Skeleton Loader
import Shimmer from "../../components/Skeleton/Shimmer";
import HomeSliderSkleton from "../../components/Skeleton/HomeSliderSkleton";
import HomePopularSkeleton from "../../components/Skeleton/HomePopularSkeleton";
import NewsBoxSkeleton from "../../components/Skeleton/NewsBoxSkeleton";
import HomeKindofTripSkeleton from "../../components/Skeleton/HomeKindofTripSkeleton";
import l from "../../components/Skeleton/Skeleton.css";
import HostSlider from "../../components/Home/HostSlider/HostSlider";
import { Col, Grid, Row } from "react-bootstrap";

const listCategory = [
  { icon: "/SiteIcons/house.svg", name: "City House", id: 5 },
  { icon: "/SiteIcons/Apartment.svg", name: "Apartment", id: 6 },
  { icon: "/SiteIcons/Beachhouse.svg", name: "Beach house", id: 130 },
  { icon: "/SiteIcons/cabin.svg", name: "Cabin", id: 131 },
  { icon: "/SiteIcons/hotel.svg", name: "Hotel", id: 132 },
  { icon: "/SiteIcons/Bed.svg", name: "Bed n breakfast", id: 7 },
];

const liveCate = [
  { image: "/SiteImages/live-img-1.png", name: "Farms", id: 134 },
  { image: "/SiteImages/live-img-2.png", name: "Countryside", id: 133 },
  { image: "/SiteImages/live-img-3.png", name: "Islands", id: 135 },
];

class Homepage extends React.Component {
  static propTypes = {
    getRecommendData: PropTypes.shape({
      loading: PropTypes.bool,
      getRecommendData: PropTypes.array,
    }),
    getImageBannerData: PropTypes.shape({
      loading: PropTypes.bool,
      getImageBanner: PropTypes.object,
    }),
    getMostViewedListingData: PropTypes.shape({
      loading: PropTypes.bool,
      GetMostViewedListing: PropTypes.array,
    }),
    getPopularLocationData: PropTypes.shape({
      loading: PropTypes.bool,
      GetMostViewedListing: PropTypes.array,
    }),
    getHomeBannerData: PropTypes.shape({
      loading: PropTypes.bool,
      getHomeBanner: PropTypes.object,
    }),
    formatMessage: PropTypes.func,
  };

  static defaultProps = {
    getRecommendData: {
      loading: true,
    },
    getPopularLocationData: {
      loading: true,
    },
    getMostViewedListingData: {
      loading: true,
    },
    getImageBannerData: {
      loading: true,
    },
    homeBannerImages: {
      loading: true,
    },
    getHomeBanner: {
      loading: true,
    },
    getStaticBlockInfoData: {
      loading: true,
    },
  };

  render() {
    const {
      layoutType,
      wholeData,
      getRecommendData,
      getMostViewedListingData,
    } = this.props;
    const {
      getImageBannerData,
      getStaticBlockInfoData,
      getPopularLocationData,
      listingFields,
    } = this.props;

    let title, content, getHomeBanner;
    if (wholeData) {
      title = wholeData.title;
      content = wholeData.content;
      getHomeBanner = wholeData.getHomeBanner;
    }
    console.log(listingFields, "listingFieldslistingFields");
    const newCate1 = listCategory.map((el) => {
      const typeF = listingFields?.houseType.find((ele) => ele.id == el.id);
      return { ...el, countListing: typeF?.countListing || 0 };
    });
    const newCate2 = liveCate.map((el) => {
      const typeF = listingFields?.houseType.find((ele) => ele.id == el.id);
      return { ...el, countListing: typeF?.countListing || 0 };
    });
    if (!wholeData) return <Loader type="text" />;

    return (
      <div className={s.root}>
        <MobileHeader />
        <div>
          {layoutType && layoutType == 1 && (
            <Layout1
              title={title}
              content={content}
              homeBannerImages={getHomeBanner}
            />
          )}

          {layoutType && layoutType == 3 && (
            <Layout3
              title={title}
              content={content}
              homeBannerImages={getHomeBanner}
            />
          )}

          {layoutType && layoutType == 4 && (
            <Layout4
              title={title}
              content={content}
              homeBannerImages={getHomeBanner}
            />
          )}

          {layoutType && layoutType == 5 && (
            <Layout5
              title={title}
              content={content}
              homeBannerImages={getHomeBanner}
            />
          )}
          {layoutType && layoutType == 2 && (
            <div className={s.container}>
              <div className={cx(s.pageContainer, s.layout2Bottom)}>
                <BannerCaption title={title} content={content} />
                <div className={s.pageContainer}>
                  <SearchForm />
                </div>
              </div>
            </div>
          )}
          {/* <div className={cx(s.container)}>
            <Animation />
           </div> */}

          {getRecommendData && getRecommendData.loading ? (
            <div className={s.pageContainer}>
              <div className={cx(s.container, "containerLoaderRTL")}>
                <div className={cx(l.popularSkeletonTitle, "bgBlackTwo")}>
                  <Shimmer />
                </div>
                <div className={s.displayGrid}>
                  {[1, 2, 3, 4, 5].map((n) => (
                    <HomeSliderSkleton key={n} />
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className={s.pageContainer}>
              <div className={s.container}>
                <HomeSlider
                  data={getRecommendData && getRecommendData.getRecommend}
                  isRecommand={true}
                />
              </div>
            </div>
          )}
          {/* RecommendData */}
          {/* {getRecommendData && getRecommendData.loading ? (<div className={s.pageContainer}>
            <div className={cx(s.container, 'containerLoaderRTL')}>
              <div className={cx(l.popularSkeletonTitle, 'bgBlackTwo')}>
                <Shimmer />
              </div>
              <div className={s.displayGrid}>
                {[1, 2, 3, 4, 5].map((n) => (
                  <HomeSliderSkleton key={n} />
                ))}
              </div>
            </div>
          </div>
          ) : (
            <div className={s.container}>
              <div className={s.pageContainer}>
                <HomeSlider
                  data={getRecommendData && getRecommendData.getRecommend}
                  isRecommand={true}
                />
              </div>
            </div>
          )} */}

          {/* Popular location */}
          {/* {getPopularLocationData && getPopularLocationData.loading ? (<div className={cx(s.popularGridWidth, 'bgBlackTwo')}>
            <div className={s.container}>
              <div className={cx(l.popularSkeletonTwoTitle, 'bgBlackTwo')}>
                <Shimmer />
              </div>
              <div className={s.displayGridTwo}>
                {[1, 2, 3, 4, 5].map((n) => (
                  <HomePopularSkeleton key={n} />
                ))}
              </div>
            </div>
          </div>
          ) : (
            getPopularLocationData && getPopularLocationData.getPopularLocationAdmin && getPopularLocationData.getPopularLocationAdmin.length > 0 &&
            <div className={s.container}>
              <div className={s.pageContainer}>
                <div className={s.container}>
                  <PopularLocationGrid data={getPopularLocationData.getPopularLocationAdmin} />
                </div>
              </div>
            </div>
          )} */}
        </div>
        {/* <div className={s.container}>
          <div className={s.pageContainer}>
            <Grid fluid>
              <Row cols={4} className={cx('homeSwiper localtionList relative')}>
                <Col sm={3} md={3} lg={3} xs={6} className={cx(s.responsiveMargin, s.noPaddingMobile, 'px-2')}>
                  <div className={s.titleCont}>
                    Live anywhere
                  </div>
                  <div className={s.descCont}>
                    where do you want to live
                  </div>
                </Col>
                {newCate2.map(el => <Col sm={3} md={3} lg={3} xs={6} className={cx(s.responsiveMargin, s.noPaddingMobile, 'px-2')}>
                  <a className={s.linkContainer} href={"/s?category=" + el.id}>
                    <div className="flex flex-col justify-center items-stretch">
                      <div className="shadow-sm flex grow flex-col items-stretch max-md:mt-6 ">
                        <div className="img-hover-zoom--colorize live-img">
                        <img
                          loading="lazy"
                          src={el.image}
                          className=" object-contain object-center w-full overflow-hidden child-img"
                        />
                        </div>
                        <div className="text-[var(--common-text-color)] text-center text-xl font-semibold self-center whitespace-nowrap mt-4">
                          {el.name}
                        </div>
                        <div className="text-neutral-500 text-center text-xl self-center whitespace-nowrap mt-3">
                          {el.countListing} properties
                        </div>
                      </div>

                    </div>
                  </a>
                </Col>)}

              </Row>
            </Grid>
          </div>
        </div> */}
        {/* {getImageBannerData && getImageBannerData.loading ?
          <div className={s.pageContainer}>
            <div className={s.container}>
              <NewsBoxSkeleton />
            </div>
          </div> : (
            <div className={s.pageContainer}>
              <div className={s.container}>
                <NewsBox data={getImageBannerData.getImageBanner} />
              </div>
            </div>
          )}
        <div className={s.pageContainer}>
          <Feedback />
        </div> */}
        {/* {getMostViewedListingData && getMostViewedListingData.loading ? (<div className={s.pageContainer}>
          <div className={cx(s.container, 'containerLoaderRTL')}>
            <div className={cx(l.popularSkeletonTitle, 'bgBlackTwo')}>
              <Shimmer />
            </div>
            <div className={s.displayGrid}>
              {[1, 2, 3, 4, 5].map((n) => (
                <HomeSliderSkleton key={n} />
              ))}
            </div>
          </div>
        </div>
        ) : (
          <div className={s.container}>
            <div className={s.pageContainer}>
              <HostSlider
                data={getMostViewedListingData.GetMostViewedListing}
                isRecommand={true}
              />
            </div>
          </div>
        )} */}
        {/* {getMostViewedListingData && getMostViewedListingData.loading ? (<div className={s.pageContainer}>
          <div className={cx(s.container, 'containerLoaderRTL')}>
            <div className={cx(l.popularSkeletonTitle, 'bgBlackTwo')}>
              <Shimmer />
            </div>
            <div className={s.displayGrid}>
              {[1, 2, 3, 4, 5].map((n) => (
                <HomeSliderSkleton key={n} />
              ))}
            </div>
          </div>
        </div>
        ) : (
          <div className={s.container}>
            <div className={s.pageContainer}>
              <HostSlider
                data={getMostViewedListingData.GetMostViewedListing}
                isRecommand={true}
              />
            </div>
          </div>
        )}
     */}

        {getMostViewedListingData && getMostViewedListingData.loading ? (
          <div className={s.pageContainer}>
            <div className={cx(s.container, "containerLoaderRTL")}>
              <div className={cx(l.popularSkeletonTitle, "bgBlackTwo")}>
                <Shimmer />
              </div>
              <div className={s.displayGrid}>
                {[1, 2, 3, 4, 5].map((n) => (
                  <HomeSliderSkleton key={n} />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={s.container}>
            <div className={s.pageContainer}>
              <PopularLocation
                data={getPopularLocationData.getPopularLocationAdmin}
                isRecommand={false}
              />
            </div>
          </div>
        )}
        {getMostViewedListingData && getMostViewedListingData.loading ? (
          <div className={s.pageContainer}>
            <div className={cx(s.container, "containerLoaderRTL")}>
              <div className={cx(l.popularSkeletonTitle, "bgBlackTwo")}>
                <Shimmer />
              </div>
              <div className={s.displayGrid}>
                {[1, 2, 3, 4, 5].map((n) => (
                  <HomeSliderSkleton key={n} />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={s.container}>
            <div className={s.pageContainer}>
              <MostViewed
                data={getMostViewedListingData.GetMostViewedListing}
                isRecommand={true}
                header={messages.mostViewed}
              />
            </div>
          </div>
        )}

        {getMostViewedListingData && getMostViewedListingData.loading ? (
          <div className={s.pageContainer}>
            <div className={cx(s.container, "containerLoaderRTL")}>
              <div className={cx(l.popularSkeletonTitle, "bgBlackTwo")}>
                <Shimmer />
              </div>
              <div className={s.displayGrid}>
                {[1, 2, 3, 4, 5].map((n) => (
                  <HomeSliderSkleton key={n} />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={s.container}>
            <div className={s.pageContainer}>
              <PopularHost
               data={getMostViewedListingData.GetMostViewedListing}
               isRecommand={false}
              />
            </div>
          </div>
        )}
        {/* {
          getStaticBlockInfoData && getStaticBlockInfoData.loading ? <div className="hidden-xs">
            <div className={s.container}>
              <HomeKindofTripSkeleton />
            </div>
          </div> : <div className="hidden-xs">
            <div className={s.container}>
              <HomeKindofTrip data={getStaticBlockInfoData.getStaticInfo} />{" "}
            </div>
          </div>
        } */}
        <div className={s.container}>
          <div className={s.pageContainer}>
            {/* <Grid fluid>
              <Row cols={5} className={cx('homeSwiper localtionList relative')}>
                {newCate1.map(el => <Col sm={3} md={2} lg={2} xs={6} className={cx(s.responsiveMargin, s.noPaddingMobile, 'px-2')}>
                  <a className={s.linkContainer} href={"/s?category=" + el.id}>
                    <div className="flex flex-col justify-center items-stretch">
                      <div className="border border-[color:var(--Border,#D3D4D5)] flex w-full flex-col pl-4 py-7 rounded-xl border-solid">
                        <div className=" text-base self-stretch">
                          {el.countListing} properties
                        </div>
                        <img
                          loading="lazy"
                          src={el.icon}
                          className="aspect-square object-contain object-center w-[45px] overflow-hidden max-w-full mt-5 self-start"
                        />
                        <div className="text-xl font-semibold self-stretch mt-2.5">
                          {el.name}
                        </div>
                      
                      </div>
                    </div>
                  </a>
                </Col>)}

              </Row>
            </Grid> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  listingFields: state.listingFields.data,
});

const mapDispatch = {};
export default compose(
  injectIntl,
  withStyles(s, l),
  connect(mapState, mapDispatch),
  graphql(getRecommendQuery, {
    name: "getRecommendData",
    options: {
      ssr: false,
    },
  }),
  graphql(getPopularLocationQuery, {
    name: "getPopularLocationData",
    options: {
      ssr: false,
    },
  }),
  graphql(getMostViewedListingQuery, {
    name: "getMostViewedListingData",
    options: {
      ssr: false,
    },
  }),
  graphql(getImageBannerQuery, {
    name: "getImageBannerData",
    options: {
      ssr: false,
    },
  }),
  graphql(getStaticBlockInfoQuery, {
    name: "getStaticBlockInfoData",
    options: {
      ssr: false,
    },
  })
)(Homepage);
