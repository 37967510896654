exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NewStaking-revBlock-1-1ex {\n    border-radius: 0px 12px 12px 0px;\n    background: #60B0FB;\n    -webkit-backdrop-filter: blur(3px);\n            backdrop-filter: blur(3px); \n    display: -webkit-box; \n    display: -ms-flexbox; \n    display: flex;\n    -webkit-box-pack: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    -webkit-backdrop-filter: blur(0px);\n            backdrop-filter: blur(0px);\n    height: 100%;\n    width: 110px;\n}\n.NewStaking-rowText-FyR0S{\n    color: #E5E6EB;\n    color: var(--Text-Text-Secondary, #E5E6EB);\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px; \n}\n.NewStaking-btnEndtime-NWU8Y {\n    height: 40px;\n    padding: 10px;\n    width: -webkit-fit-content;\n    width: -moz-fit-content;\n    width: fit-content;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    -webkit-box-align: start;\n        -ms-flex-align: start;\n            align-items: flex-start;\n    gap: 10px;\n    -ms-flex-negative: 0;\n        flex-shrink: 0;\n    border-radius: 6px;\nbackground: #242526;\nbackground: var(--background-bg-2-white, #242526);\n-webkit-box-shadow: 3px 3px 9px 0px rgba(0, 0, 0, 0.60), -2px -2px 6px 0px rgba(68, 74, 83, 0.60);\n        box-shadow: 3px 3px 9px 0px rgba(0, 0, 0, 0.60), -2px -2px 6px 0px rgba(68, 74, 83, 0.60);\ndisplay: -webkit-box;\ndisplay: -ms-flexbox;\ndisplay: flex;\n-webkit-box-align: center;\n    -ms-flex-align: center;\n        align-items: center;\n-webkit-box-pack: center;\n    -ms-flex-pack: center;\n        justify-content: center;\ncursor: pointer;\n}\n.NewStaking-rowContainer-3ICEI {\n    vertical-align: top !important;\n}", ""]);

// exports
exports.locals = {
	"revBlock": "NewStaking-revBlock-1-1ex",
	"rowText": "NewStaking-rowText-FyR0S",
	"btnEndtime": "NewStaking-btnEndtime-NWU8Y",
	"rowContainer": "NewStaking-rowContainer-3ICEI"
};