exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NewStaking-block2-alBn5 {\n    background: -webkit-gradient(linear, left top, right top, from(#DBE2DA), to(#76C5EC));\n    background: -webkit-linear-gradient(left, #DBE2DA 0%, #76C5EC 100%);\n    background: -o-linear-gradient(left, #DBE2DA 0%, #76C5EC 100%);\n    background: linear-gradient(90deg, #DBE2DA 0%, #76C5EC 100%); \n}\n\n.NewStaking-block1-2usb1 {\n    background: -webkit-gradient(linear, right top, left top, from(#9FBDF8), to(#A4C2FF));\n    background: -webkit-linear-gradient(right, #9FBDF8 0%, #A4C2FF 100%);\n    background: -o-linear-gradient(right, #9FBDF8 0%, #A4C2FF 100%);\n    background: linear-gradient(270deg, #9FBDF8 0%, #A4C2FF 100%);\n}", ""]);

// exports
exports.locals = {
	"block2": "NewStaking-block2-alBn5",
	"block1": "NewStaking-block1-2usb1"
};