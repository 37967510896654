import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table, Tr, Td, Thead, Th } from 'reactable';
import { connect } from 'react-redux';

import { deleteAdminReview } from '../../../actions/siteadmin/AdminReviews/deleteAdminReview';
import Link from '../../../components/Link';
import { graphql, gql, compose } from 'react-apollo';

// Toaster
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import Confirm from 'react-confirm-bootstrap';

// Translation
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../../locale/messages';

// Style
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ListBuyR3V.css';

import { censorEmail, censorPhone } from '../../../helpers/secureEmail';

import { FormControl } from 'react-bootstrap';
import getBuyR3V from './getBuyR3VQuery.graphql';
import CustomPagination from '../../../components/CustomPagination/CustomPagination';

class ListBuyR3V extends React.Component {

  static propTypes = {
    data: PropTypes.array,
    editUser: PropTypes.any,
    deleteAdminReview: PropTypes.any,
    title: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0
    }
    this.paginationData = this.paginationData.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  paginationData(currentPage) {

    const { getBuyR3V: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);

  }

  handleClick(searchList) {
    const { getBuyR3V: { refetch } } = this.props;
    const { currentPage } = this.state;
    let variables = {
      currentPage: 1,
      searchList: searchList
    };
    this.setState({ currentPage: 1 });
    refetch(variables);
  }

  handleSearchChange = (e) => {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      searchList: event.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleClick(self.state.searchList);
      }, 450)
    });
  }

  async approveBuyR3VItem(id) {
    console.log(this.props, id, 'this.propsthis.props');
    const { mutate: approveBuyR3V } = this.props;
    const { getBuyR3V: { refetch } } = this.props;

    const {data} = await approveBuyR3V({variables: {id}});
    if(data?.approveBuyR3V?.status) {
      toastr.success('Approved success!')
      refetch();
    } else {
      toastr.error('Approved failed!')
    }
  }

  render() {
    const { editUser, deleteAdminReview, title } = this.props;
    const { currentPage } = this.state;
    const { getBuyR3V: { loading, getBuyR3V } } = this.props;
    console.log(getBuyR3V, 'getBuyR3VgetBuyR3V');
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentAR')}>
        <div>
          <h1 className={s.headerTitle}> <FormattedMessage {...messages.adminReviews} /></h1>
          <div className={cx(s.exportSection, 'bgBlack')}>
            <div>
              <FormControl
                type="text"
                placeholder={formatMessage(messages.search)}
                onChange={(e) => this.handleSearchChange(e)}
                className={cx('searchInputControl', 'searchInputControlWidth', 'searchInputControlAR')}
              />
            </div>
          </div>
          <div className={cx('table-responsive', 'NewAdminResponsiveTable', 'NewResponsiveTableAdmin')}>
            <Table
              className="table"
              noDataText={formatMessage(messages.noRecordFound)}
              sortable={true}
            >
              <Thead>
                <Th scope="col">Proof of USD to USDT</Th>
                <Th scope="col">{'Proof of USDT to R3V'}</Th>
                <Th scope="col">{'Proof of transfer R3V'}</Th>
                <Th scope="col">{'USD withdraw amount'}</Th>
                <Th scope="col">{formatMessage(messages.approve)}</Th>
              </Thead>
              {
                getBuyR3V?.length > 0 && getBuyR3V.map((value, key) => {
                  console.log(value, 'valuevalue');
                  const { proofUSDtoUSDT, proofR3VTransfer, proofUSDTtoR3V, amountUSD } = JSON.parse(value?.data)
                  return (
                    <Tr key={key}>
                      <Td data-label={'Proof of USD to USDT'} column={'Proof of USD to USDT'}>
                        <div>
                          {proofUSDtoUSDT?.map(el => <div
                            style={{ backgroundImage: `url(/images/banner/${el})` }}
                            className={s.backgroundImg}
                          />)}
                        </div>
                      </Td>
                      <Td data-label={'Proof of USDT to R3V'} column={'Proof of USDT to R3V'}>
                        <div>
                          {proofUSDTtoR3V?.map(el => <div
                            style={{ backgroundImage: `url(/images/banner/${el})` }}
                            className={s.backgroundImg}
                          />)}
                        </div>
                      </Td>
                      <Td data-label={'Proof of transfer R3V'} column={'Proof of transfer R3V'}>
                        <div>
                          {proofR3VTransfer?.map(el => <div
                            style={{ backgroundImage: `url(/images/banner/${el})`, marginTop: '12px' }}
                            className={s.backgroundImg}
                          />)}
                        </div>
                      </Td>
                      <Td data-label={formatMessage(messages.reviewContentLabel)} column={formatMessage(messages.reviewContentLabel)} data={value.reviewContent} />

                      <Td data-label={'USD withdraw amount'} column={'USD withdraw amount'}>
                        <div>{amountUSD || 0} USD</div>
                      </Td>
                      <Td data-label={formatMessage(messages.approve)} column={formatMessage(messages.approve)}>
                        <div>
                          {value?.status == 1 ? <div className=''>Approved</div> : <Confirm
                            onConfirm={() => this.approveBuyR3VItem(value.id)}
                            body={`Are you sure want to approve withdraw ${amountUSD} USD this?`}
                            confirmText={formatMessage(messages.approve)}
                            cancelText={formatMessage(messages.cancel)}
                            title={formatMessage(messages.approve)}
                          >
                            <a href="javascript:void(0)">
                              <FormattedMessage {...messages.approve} />
                            </a>
                          </Confirm>}
                          
                        </div>
                      </Td>
                    </Tr>
                  )
                })
              }
            </Table>
          </div>
          {
            getBuyR3V?.length > 0
            && <div>
              <CustomPagination
                total={getBuyR3V?.length}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={10}
                change={this.paginationData}
                paginationLabel={formatMessage(messages.reviews)}
              />
            </div>
          }
        </div>
      </div>
    );
  }

}

const mapState = (state) => ({
});

const mapDispatch = {
  deleteAdminReview,
};

export default compose(injectIntl,
  withStyles(s),
  connect(mapState, mapDispatch),
  graphql(getBuyR3V, {
    name: 'getBuyR3V',
    options: {
      fetchPolicy: 'network-only',
    }
  }),
  graphql(gql`
mutation approveBuyR3V(
    $id: Int,
){
  approveBuyR3V(
    id: $id,
    ) {
        status,
    }
}
`)
)(ListBuyR3V);