// General
import React from "react";
import PropTypes from "prop-types";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./Register.css";
import bt from "../../components/commonStyle.css";
import { Col } from "react-bootstrap";

// Components
import RegisterForm from "../../components/RegisterForm";
import SocialLogin from "../../components/SocialLogin";
import Link from "../../components/Link";

// Translation
import { FormattedMessage, injectIntl } from "react-intl";

// Locale
import messages from "../../locale/messages";
import clsx from "clsx";

class Register extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    refer: PropTypes.string,
  };

  render() {
    const { refer } = this.props;
    let initialValues = {};
    let loginURL = "/login";
    if (refer) {
      initialValues = {
        refer,
      };
      loginURL = "/login?refer=" + refer;
    }

    return (
      <div className={s.root}>
        <div
          className={clsx(
            s.container,
            "rounded-xl border bg-[#242526] border-[#606570]"
          )}
        >
          <RegisterForm initialValues={initialValues} />

          <div className="pb-[40px] px-[20px] sm:!px-[200px]">
            <div className="relative flex items-center justify-center space-x-2 text-[#B1B3B8]">
              <hr className="w-1/2" />
              <div className="font-medium">or</div>
              <hr className="w-1/2" />
            </div>

            <SocialLogin refer={refer} />

            <p className={cx(s.linkText, 'text-[16px]')}>
              <FormattedMessage {...messages.alreadyHaveAccount} />
              <Link className={s.modalCaptionLink} to={loginURL}>
                <FormattedMessage {...messages.login} />
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(s, bt)(Register));
