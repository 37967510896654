import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './ViewProfile.css';
import {
  Grid,
  Row,
  Col
} from 'react-bootstrap';
import cx from "classnames";

// Components
import EditProfileForm from '../../components/EditProfileForm/EditProfileForm';
import DashBoardSideMenu from '../../components/Dashboard/DashBoardSideMenu';
import ViewUserProfile from '../../components/ViewUserProfile';

class ViewProfile extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    const { title } = this.props;
    return (
      <>
        <Grid fluid className={cx('dashBoardContainer', s.containerMain)}>
          <ViewUserProfile />
        </Grid>
      </>
    );
  }

}

export default withStyles(s)(ViewProfile);
