import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './NewStaking.css';
import { Col, FormControl, FormGroup, Grid, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import cx from 'classnames'
import { FormattedMessage } from 'react-intl';
import messages from '../../locale/messages';
import history from '../../core/history';
import StakingMenu from '../../components/Staking/StakingMenu';
import { Table, Tr, Td, Thead, Th } from 'reactable';
import { compose, gql, graphql } from 'react-apollo';
import moment from 'moment';
import { STAKING_TYPE } from '../../constants';

class StakingPoolData extends React.Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
  };


  render() {
    const { getStakingInfo, balance, updateStakeRequest } = this.props
    const { stakingInfo, stakingPool,
      stakingRequests } = getStakingInfo?.getStakingInfo || {}
    return (
      <Grid fluid className='dashBoardContainer'>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={cx('')}>
              <StakingMenu />
              <div>
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0 pt-[32px]">
                  <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                    <div className={cx("flex grow flex-col w-full pl-8 py-10 pb-8 rounded-xl items-start max-md:max-w-full max-md:mt-6 max-md:px-5", s.block1)}>
                      <div className="text-[#242526] text-[22px] font-medium whitespace-nowrap">
                      R3V Total Pending Timeboxing Rewards
                      </div>
                      <div className="text-[#242526] text-base font-medium mt-24 max-md:mt-10">
                        Balance
                      </div>
                      <div className="text-[#242526] text-[32px] font-medium mt-4">
                        {stakingPool?.total_staked || 0} REV
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                    <div className={cx("flex grow flex-col w-full pl-8 py-10 pb-8 rounded-xl items-start max-md:max-w-full max-md:mt-6 max-md:px-5", s.block2)}>
                      <div className="text-[#242526] text-[22px] font-medium whitespace-nowrap">
                      Total Timeboxed R3V
                      </div>
                      <div className="text-[#242526] text-base font-medium mt-24 max-md:mt-10">
                        Balance
                      </div>
                      <div className="text-[#242526] text-[32px] font-medium mt-4">
                        {stakingPool?.total_pending_reward_pool || 0} REV
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

      </Grid>
    );
  }
}


export default compose(withStyles(s), graphql(gql`
query {
  getStakingInfo{
  stakingInfo {
    userId
    pool_id
    total_staked
    total_pending_un_staked
    total_reward_unclaimed
    total_reward_claimed
  }
  stakingPool {
    pending_reward
    total_staked
    total_pending_reward_pool
  }
  stakingRequests {
    id
    status
    amount
    staked_time
    un_staked_time_request
    un_staked_time
    cancel_un_staked_time_request
    cancel_un_staked_time
    un_staked_predict_time
  }}
}

`, {
  name: 'getStakingInfo',
  options: {
    ssr: false
  }
}))(StakingPoolData);

