import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Field, reduxForm } from "redux-form";
import submit from "./submit";
import validate from "./validate";
import { injectIntl } from "react-intl";

// Locale
import messages from "../../locale/messages";
import PopulateData from "../../helpers/populateData";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";
import s from "./RegisterForm.css";
import bt from "../../components/commonStyle.css";
import {
  Button,
  FormGroup,
  FormControl,
  Row,
  Col,
  ControlLabel,
} from "react-bootstrap";

//Images
import ShowPassword from "../../../public/SiteIcons/pswVisible.svg";
import HidePassword from "../../../public/SiteIcons/pwdHidden.svg";
import clsx from "clsx";

class RegisterForm extends Component {
  static propTypes = {
    formatMessage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: "",
      dateOfBirthData: {},
      selected: "host",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectedChange = this.handleSelectedChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    let now = new Date();
    let currentYear = now.getFullYear();
    let years = PopulateData.generateData(1920, currentYear, "desc");
    let days = PopulateData.generateData(1, 31);
    let months = PopulateData.generateData(0, 11);
    this.setState({
      dateOfBirthData: {
        years: years,
        months: months,
        days: days,
      },
    });
  }

  handleChange(fieldName) {
    this.setState({
      showPassword: fieldName === this.state.showPassword ? "" : fieldName,
    });
  }

  handleSelectedChange(selected) {
    this.setState({
      selected,
    });
  }

  renderFormControl = ({
    input,
    label,
    type,
    meta: { touched, error },
    className,
    showPassword,
    placeHolder,
    maxlength,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(bt.space3, bt.pwdSection)}>
        <label className={s.loginLabel}>{label}</label>
        <FormControl
          {...input}
          placeholder={placeHolder ? placeHolder : label}
          type={showPassword === input.name ? input : type}
          className={className}
          maxlength={maxlength}
        />
        {type == "password" && (
          <span
            className={cx(
              bt.pwdImage,
              bt.regPwdSection,
              "svgImg",
              "pwdImageRTL"
            )}
            onClick={() => this.handleChange(input.name)}
          >
            {showPassword === input.name ? (
              <img src={ShowPassword} />
            ) : (
              <img src={HidePassword} />
            )}
          </span>
        )}
        {touched && error && (
          <span className={bt.errorMessage}>{formatMessage(error)}</span>
        )}
      </FormGroup>
    );
  };

  renderFormControlSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
  }) => {
    return (
      <FormGroup className={cx(bt.space3, bt.pwdSection)}>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </FormGroup>
    );
  };

  render() {
    const { error, handleSubmit, submitting, dispatch } = this.props;
    const { formatMessage } = this.props.intl;
    const { dateOfBirthData } = this.state;

    return (
      <div className=" mx-auto max-w-full text-white">
        <h4 className="border-b border-[#606570] py-[25px] text-center text-white text-[24px] font-medium">
          Sign up
        </h4>

        <form
          onSubmit={handleSubmit(submit)}
          className={cx("!px-[20px] sm:!px-[200px] SelectFocus", "inputDateHide")}
        >
          <div className="bg-[#242526] rounded-[40px] sm:px-5 mt-[24px] py-1 shadow-[-2px_-2px_6px_0px_rgba(68,74,83,0.6),4px_4px_12px_0px_rgba(0,0,0,0.6)] flex items-center gap-x-4 mb-8">
            <div
              className={clsx(
                "w-40 border-[3px] rounded-[40px] sm:text-base sm:font-medium cursor-pointer",
                "border-[#242526] h-10 flex items-center w-1/2 min-w-[130px] sm:min-w-[168px] flex-grow justify-center",
                {
                  "bg-[#18191A] shadow-[-2px_-2px_6px_0px_#444A53,4px_4px_4px_0px_rgba(0,0,0,0.25)] transition-all duration-300":
                    this.state.selected === "host",
                }
              )}
              onClick={() => this.handleSelectedChange("host")}
            >
              Sign up as a Host
            </div>

            <div
              className={clsx(
                "w-40 border-[3px] rounded-[40px] sm:text-base sm:font-medium cursor-pointer",
                "border-[#242526] h-10 flex items-center w-1/2 min-w-[130px] sm:min-w-[168px] flex-grow justify-center",
                {
                  "bg-[#18191A] shadow-[-2px_-2px_6px_0px_#444A53,4px_4px_4px_0px_rgba(0,0,0,0.25)] transition-all duration-300":
                    this.state.selected === "guest",
                }
              )}
              onClick={() => this.handleSelectedChange("guest")}
            >
              Sign up as a Guest
            </div>
          </div>

          {error && (
            <span className={bt.errorMessage}>{formatMessage(error)}</span>
          )}
          <Field
            name="firstName"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.firstName)}
            className={bt.commonControlInput}
            maxlength={25}
          />
          <Field
            name="lastName"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.lastName)}
            className={bt.commonControlInput}
            maxlength={25}
          />
          <Field
            name="email"
            type="text"
            component={this.renderFormControl}
            label={formatMessage(messages.email)}
            className={bt.commonControlInput}
          />
          <Field
            name="password"
            type="password"
            component={this.renderFormControl}
            label={formatMessage(messages.password)}
            className={bt.commonControlInput}
            showPassword={this.state.showPassword}
            maxlength={25}
          />
          <div className={s.Birthpadding}>
            <label className={s.loginLabel}>
              {formatMessage(messages.birthDay)}
            </label>
            <div className={s.birthFlex}>
           

              <Field
                name="day"
                component={this.renderFormControlSelect}
                className={cx(
                  s.birthForm,
                  "birthFormRtl signupFSelect",
                  bt.commonControlSelect
                )}
              >
                <option value="">{formatMessage(messages.transferDate)}</option>
                {dateOfBirthData &&
                  dateOfBirthData.days &&
                  dateOfBirthData.days.length > 0 &&
                  dateOfBirthData.days.map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </Field>
              <Field
                name="month"
                component={this.renderFormControlSelect}
                className={cx(
                  s.birthForm,
                  "birthFormRtl signupFSelect",
                  bt.commonControlSelect
                )}
              >
                <option value="">{formatMessage(messages.month)}</option>
                {dateOfBirthData &&
                  dateOfBirthData.months &&
                  dateOfBirthData.months.length > 0 &&
                  dateOfBirthData.months.map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {item + 1}
                      </option>
                    );
                  })}
              </Field>
              <Field
                name="year"
                component={this.renderFormControlSelect}
                className={cx(
                  s.birthForm,
                  "birthFormRtl signupFSelect",
                  bt.commonControlSelect
                )}
              >
                <option value="">{formatMessage(messages.year)}</option>
                {dateOfBirthData &&
                  dateOfBirthData.years &&
                  dateOfBirthData.years.length > 0 &&
                  dateOfBirthData.years.map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </Field>
            </div>
          </div>

          <button
            className={cx(s.signupButtonBgContainer)}
            block
            type="submit"
            disabled={submitting}
          >
            <div className={cx(s.linearButton)}>
              <div className={cx(s.effectButton)}>
                Sign up as a{" "}
                <span className="capitalize ml-[4px]">{this.state.selected}</span>
              </div>
            </div>
          </button>
        </form>
      </div>
    );
  }
}

RegisterForm = reduxForm({
  form: "RegisterForm", // a unique name for this form
  validate,
})(RegisterForm);

export default injectIntl(withStyles(s, bt)(RegisterForm));
