// General
import React, { Component } from "react";
import PropTypes from "prop-types";
// Redux Form
import { Field, reduxForm, formValueSelector, change } from "redux-form";
// Translation
import { injectIntl, FormattedMessage } from "react-intl";
// Redux
import { connect } from "react-redux";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";

import s from "./ListPlaceStep1.css";
import bt from "../../components/commonStyle.css";

// Locale
import messages from "../../locale/messages";

// components
import FooterButton from "./FooterButton";
import SidePanel from "./SidePanel";

// Helpers
import validate from "./validate";
import update from "./update";

//Image
import toolTipIcon from "../../../public/SiteIcons/listCommonToolTip.svg";

class Page2 extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      houseType: [],
      roomType: [],
      buildingSize: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { listingFields } = this.props;

    if (listingFields != undefined) {
      this.setState({
        houseType: listingFields.houseType,
        roomType: listingFields.roomType,
        buildingSize: listingFields.buildingSize,
      });
    }
  }

  componentDidMount() {
    const { valid } = this.props;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { valid, listingFields } = nextProps;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }

    if (listingFields != undefined) {
      this.setState({
        houseType: listingFields.houseType,
        roomType: listingFields.roomType,
        buildingSize: listingFields.buildingSize,
      });
    }
  }

  renderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
  }) => {
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <select {...input}>{children}</select>
        {touched && error && <span>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderFormControlSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </div>
    );
  };

  onSelectType = (id) => {
    const { change, nextPage } = this.props;
    change("roomType", id);
  };

  render() {
    const {
      handleSubmit,
      previousPage,
      nextPage,
      existingList,
      formPage,
      step,
      roomTypeValue,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const { isDisabled, houseType, roomType, buildingSize } = this.state;
    let path = "index";
    if (existingList) {
      path = "home";
    }

    return (
      <div>
        <div className={""}>
          {/* <SidePanel
            title={formatMessage(messages.stepOneCommonHeading)}
            landingContent={formatMessage(messages.whatKindOfPlaceListing)}
          /> */}
          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <div
                  className={cx(
                    s.landingMainContent,
                    "flex justify-around !px-[225px] houseTypeCont"
                  )}
                >
                  {roomType.map((value, key) => {
                    return (
                      value?.isEnable == 1 && (
                        <div
                          className={cx(
                            "flex h-[110px] flex-col justify-center items-stretch",
                            s.propertyOptionCard,
                            value.id === roomTypeValue
                              ? s.selectedHouseType
                              : ""
                          )}
                          onClick={() => this.onSelectType(value.id)}
                        >
                          <div
                            className={cx(
                              "block h-[110px] text-center items-center justify-between gap-5 px-6 py-3 max-md:max-w-full max-md:flex-wrap max-md:px-5",
                              s.propertyOptionCardContent,
                              value.id === roomTypeValue
                                ? s.propertyOptionCardBorder
                                : ""
                            )}
                          >
                            <img
                              loading="lazy"
                              src={value.image}
                              className="aspect-square mx-auto mb-[16px] object-contain object-center w-[45px] overflow-hidden shrink-0 max-w-full my-auto"
                            />
                            <div className="flex flex-col items-stretch my-auto">
                              <div className="text-[var(--common-text-color)]">
                                {value.itemName}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
                <FooterButton
                  isDisabled={isDisabled}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  nextPagePath={"bedrooms"}
                  previousPagePath={path}
                  formPage={formPage}
                  step={step}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Page2 = reduxForm({
  form: "ListPlaceStep1", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmit: update,
})(Page2);

// Decorate with connect to read form values
const selector = formValueSelector("ListPlaceStep1"); // <-- same as form name

const mapState = (state) => ({
  existingList: state.location.isExistingList,
  listingFields: state.listingFields.data,
  roomTypeValue: selector(state, "roomType"),
});

const mapDispatch = {
  change,
};

export default injectIntl(
  withStyles(s, bt)(connect(mapState, mapDispatch)(Page2))
);
